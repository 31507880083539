import { Injectable } from '@angular/core';
import {
    BackendApiService,
    BaseServiceParameter,
} from '@datagalaxy/data-access';
import {
    GetClientQuickFiltersResult,
    UpdateClientQuickFiltersParameter,
} from './quick-filter';

@Injectable({ providedIn: 'root' })
export class QuickFiltersApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getClientQuickFilters(param: BaseServiceParameter) {
        return await this.backendApiService.postPromise(
            'ClientAdmin/GetClientQuickFilters',
            param,
            GetClientQuickFiltersResult,
        );
    }

    public async updateClientQuickFilters(
        param: UpdateClientQuickFiltersParameter,
    ) {
        return await this.backendApiService.postPromise(
            'ClientAdmin/UpdateQuickFilters',
            param,
            GetClientQuickFiltersResult,
        );
    }
}
