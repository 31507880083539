import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { ServerType } from '@datagalaxy/dg-object-model';
import { Filter } from '@datagalaxy/webclient/filter/domain';
import { ModuleEntitiesStore } from '../module-entities-store.service';
import {
    GetRowsParams,
    ServerFlatDataSourceConfig,
    ServerTreeDataSourceConfig,
} from '@datagalaxy/ui/grid';
import { EntityService } from '../../../shared/entity/services/entity.service';

export function getModuleEntitiesTreeDataSource(
    moduleEntitiesStore: ModuleEntitiesStore,
    entityService: EntityService,
    spaceIdr: WorkspaceIdentifier,
    serverTypes: ServerType[],
    filters?: Filter[],
    sizeLimit?: number,
    attributeKeys?: string[],
    sortKey?: string,
): ServerTreeDataSourceConfig<EntityItem> {
    return {
        type: 'serverTree',
        canExpand: (row: EntityItem) => row.ContextualAllLevelChildrenCount > 0,
        getChildren: (row: EntityItem) =>
            moduleEntitiesStore.getEntityChildren(row.ReferenceId),
        isExpanded: (row: EntityItem) =>
            moduleEntitiesStore.isExpanded(row.ReferenceId),
        setExpanded: (row: EntityItem, expanded: boolean) =>
            moduleEntitiesStore.setExpanded(row.ReferenceId, expanded),
        async loadChildren(row: EntityItem) {
            const res = await entityService.getEntitiesForHierarchical(
                spaceIdr,
                serverTypes,
                filters,
                attributeKeys,
                row.ReferenceId,
                sizeLimit,
                sortKey,
            );

            moduleEntitiesStore.setEntityChildren(
                row.ReferenceId,
                res.Entities,
            );

            return res.Entities;
        },
    };
}

export function getModuleEntitiesFlatDataSource(
    moduleEntitiesStore: ModuleEntitiesStore,
    entityService: EntityService,
    spaceIdr: WorkspaceIdentifier,
    serverTypes: ServerType[],
    filters?: Filter[],
    entityRootId?: string,
    sizeLimit?: number,
    pageSize?: number,
    attributeKeys?: string[],
    sortKey?: string,
): ServerFlatDataSourceConfig<EntityItem> {
    return {
        type: 'serverFlat',
        disableAutoLoad: true,
        getRows: async (params: GetRowsParams) => {
            const res = await entityService.getEntitiesForFlat(
                spaceIdr,
                serverTypes,
                params.startRow,
                pageSize,
                false,
                attributeKeys,
                filters,
                sortKey,
                entityRootId,
            );

            moduleEntitiesStore.addRootEntities(res.Entities, {
                totalCount: res.TotalCount,
                hasReachLimit: res.TotalCount >= sizeLimit,
            });

            return res.Entities;
        },
    };
}
