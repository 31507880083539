import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ITabItem } from '@datagalaxy/core-ui';
import { CollectionsHelper, StringUtil } from '@datagalaxy/core-util';
import { NotificationService } from '../notification.service';
import { ITranslatedNotification } from '../notification.types';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import {
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
} from '@angular/cdk/scrolling';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { NgIf } from '@angular/common';
import { DxyTabsHeaderComponent } from '@datagalaxy/core-ui';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { FormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { SearchInputComponent } from '@datagalaxy/ui/search';

/**
 * ## Role
 * Notifications panel container
 */
@Component({
    selector: 'app-notification-panel',
    templateUrl: 'notification-panel.component.html',
    styleUrls: ['notification-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SearchInputComponent,
        TranslateModule,
        MatSlideToggleModule,
        FormsModule,
        DxyLogFunctionalDirective,
        DxyTabsHeaderComponent,
        NgIf,
        SpinnerComponent,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        NotificationItemComponent,
    ],
})
export class NotificationPanelComponent
    extends DxyBaseComponent
    implements AfterViewInit, OnChanges
{
    @Input() public notifications: ITranslatedNotification[];
    @Input() public realtimeNotificationDisabled: boolean;

    @Output() public realtimeNotificationDisabledChange =
        new EventEmitter<boolean>();

    public showOnlyUnacknowledged = false;
    public searchString = '';
    public loading: boolean;
    public headerTabs: ITabItem<ITranslatedNotification[]>[] = [
        {
            tabId: HeaderTabs.AllNotifications,
            tabTranslateKey: 'UI.NotificationContainer.allTab',
            showEmptyDataCount: true,
        },
        {
            tabId: HeaderTabs.UnacknowledgedNotifications,
            tabTranslateKey: 'UI.NotificationContainer.unreadTab',
            showEmptyDataCount: true,
        },
    ];
    public currentTab: ITabItem<ITranslatedNotification[]> = this.headerTabs[0];

    public get showAcknowledgeAll() {
        return this.filteredNotifications?.some(
            (n) => !n.source.IsAcknowledged,
        );
    }
    public get showPlaceholder() {
        return !this.loading && !this.filteredNotifications?.length;
    }
    public get filteredNotifications() {
        return this.currentTab.data;
    }

    constructor(
        private functionalLogService: FunctionalLogService,
        private notificationService: NotificationService,
    ) {
        super();
    }

    ngAfterViewInit() {
        this.computeFilteredNotifications();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'notifications', () =>
            this.computeFilteredNotifications(),
        );
    }

    public getFeatureCodeNotifActive() {
        return this.realtimeNotificationDisabled
            ? 'NOTIFICATION_DO_NOT_DISTURB,C'
            : 'NOTIFICATION_DO_NOT_DISTURB,D';
    }

    public onRealtimeNotificationDisabledChange(disabled: boolean) {
        this.log('onRealtimeNotificationDisabledChange', disabled);
        this.realtimeNotificationDisabledChange.emit(disabled);
    }

    public onSearch(searchString: string) {
        this.searchString = searchString;
        this.computeFilteredNotifications();
    }

    public onChangeTab(tab: ITabItem<ITranslatedNotification[]>) {
        this.currentTab = tab;
        this.showOnlyUnacknowledged =
            tab.tabId === HeaderTabs.UnacknowledgedNotifications;
        this.computeFilteredNotifications();
        this.functionalLogService.logFunctionalAction(
            this.showOnlyUnacknowledged
                ? 'NOTIFICATION_TO_DO'
                : 'NOTIFICATION_ALL',
            CrudOperation.R,
        );
    }

    public async markAllAsAcknowledged(event: MouseEvent) {
        /**
         * Needed to not emit close event, since this will remove the markAllAsAcknowledged button
         * The click will be considered outside the container
         */
        event.stopPropagation();
        this.notificationService.acknowledgeNotificationList(
            this.filteredNotifications,
            true,
        );
    }

    private computeFilteredNotifications() {
        this.headerTabs[0].data = this.filterNotifications(this.notifications);
        this.headerTabs[1].data = this.filterNotifications(
            this.notifications,
            true,
        );
    }

    private filterNotifications(
        notifications: ITranslatedNotification[],
        onlyUnacknowledged?: boolean,
    ) {
        const filteredNotifications = notifications.filter(
            (notification) =>
                !onlyUnacknowledged || !notification.source.IsAcknowledged,
        );
        return CollectionsHelper.orderBy(
            StringUtil.filterSearched(
                this.searchString,
                filteredNotifications,
                (n) => n.rawText,
            ),
            (n) => n.source.CreationTime,
            'desc',
        );
    }
}

enum HeaderTabs {
    AllNotifications = 'AllNotifications',
    UnacknowledgedNotifications = 'Unacknowledged',
}
