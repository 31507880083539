<!-- Sidebar for app site / Client Workspace zone -->
<div
    class="home-button-container sidebar-item"
    [matTooltip]="'UI.NavSideBar.spaceHome' | translate"
    matTooltipPosition="right"
>
    <a
        (click)="goToHomePage()"
        dxyDataTestId="navbar-home-button"
        dxyLogFunctional="MAIN_CLIENTSPACE_HOME,R"
        class="home-logo-container open-spaces-list-btn"
    >
        <span
            class="title-logo-glyph glyph-logo-dg open-spaces-list-btn"
        ></span>
        <img
            class="caption"
            src="/images/logo/logo-white-txt.svg"
            alt="Datagalaxy logo"
        />
    </a>
</div>

<!-- Dashboard item -->
<dxy-nav-sidebar-item
    glyphClass="glyph-dashboard"
    [showTooltip]="showItemsTooltip"
    dtName="Dashboard (Global)"
    [itemIdentifier]="ViewIdentifier.ClientDashboard"
    [stateId]="StateName.ClientDashboard"
    (click)="navigateTo(ViewIdentifier.ClientDashboard)"
>
</dxy-nav-sidebar-item>
<!-- Tasks item -->
<dxy-nav-sidebar-item
    glyphClass="glyph-file-tasks-check"
    [showTooltip]="showItemsTooltip"
    dtName="Task Management"
    [itemIdentifier]="ViewIdentifier.ClientTasks"
    [stateId]="StateName.ClientTasks"
    (click)="navigateTo(ViewIdentifier.ClientTasks)"
>
</dxy-nav-sidebar-item>

@if (creationMenuOptions(); as menu) {
    <dxy-nav-sidebar-item
        [menuOptions]="menu"
        menuClass="sidebar-item--mat-menu"
        glyphClass="glyph-add"
        dtName="Create Entity Button (Global)"
        [showTooltip]="showItemsTooltip"
        [itemIdentifier]="ViewIdentifier.Create"
        dxyDataTestId="navbar-add-button"
    ></dxy-nav-sidebar-item>
}

<div
    class="dg_navSideBarSep workspace-list-section"
    [class.workspace-list-hidden]="shouldHideWorkspaceList"
    [class.workspace-list-populated]="!shouldHideWorkspaceList"
></div>

@if (currentSpace(); as workspace) {
    <dxy-nav-sidebar-item
        dtName="Create Entity Button (Global)"
        dxyDataTestId="workspace-selector-sidebar-button"
        [showTooltip]="showItemsTooltip"
        [tooltipText]="workspace.DisplayName"
        [hasCustomMenu]="true"
    >
        <dxy-workspace-icon
            sidebar-item-icon
            [workspace]="workspace | workspaceAdapter"
        ></dxy-workspace-icon>

        <dxy-workspace-menu-list
            sidebar-item-menu
            [selectedWorkspaceId]="workspace.ReferenceId"
            [workspaces]="spaces() | workspaceListAdapter"
            [showAllSpacesSection]="true"
            (workspaceSelected)="onNavSpaceSelected($event)"
            (allSpacesSelected)="goToClientSpaceList()"
            (toggleFavorite)="toggleSpaceFavorite($event)"
        ></dxy-workspace-menu-list>
    </dxy-nav-sidebar-item>
}

<div class="dg_navSideBarSep"></div>

<!-- Glossary item -->
<app-nav-sidebar-module
    [showItemsTooltip]="showItemsTooltip"
    [spaceIdr]="currentSpace()"
>
</app-nav-sidebar-module>

<div class="dg_navSideBarSep"></div>

<!-- Global diagrams list -->
<dxy-nav-sidebar-item
    glyphClass="glyph-diagram1"
    [showTooltip]="showItemsTooltip"
    dtName="Global Diagrams List"
    [itemIdentifier]="ViewIdentifier.Diagrams"
    [stateId]="StateName.Diagrams"
    (click)="navigateTo(ViewIdentifier.Diagrams)"
>
</dxy-nav-sidebar-item>

<!-- Campaign module -->
@if (showCampaignModule) {
    <dxy-nav-sidebar-item
        glyphClass="glyph-campaign dg5-beta-badge"
        [showTooltip]="showItemsTooltip"
        dtName="Campaign module"
        [itemIdentifier]="ViewIdentifier.Campaigns"
        [stateId]="StateName.Campaigns"
        (click)="navigateTo(ViewIdentifier.Campaigns)"
    >
    </dxy-nav-sidebar-item>
}

@if (hasManagementAccess()) {
    <dxy-nav-sidebar-item
        class="nav-item-offset"
        glyphClass="glyph-settings"
        [showTooltip]="showItemsTooltip"
        [itemIdentifier]="ViewIdentifier.SpaceSettings"
        dtName="Space Settings"
        [stateId]="StateName.SpaceSettings"
        (click)="navigateTo(ViewIdentifier.SpaceSettings)"
    >
    </dxy-nav-sidebar-item>

    <div class="dg_navSideBarSep"></div>
}

<!-- Expand/Collapse button -->
<dxy-nav-sidebar-item
    class="nav-item-offset"
    [glyphClass]="expandGlyphClass"
    [showTooltip]="showItemsTooltip"
    (click)="updateExpandState()"
    [itemIdentifier]="
        isCollapsed
            ? ViewIdentifier.GUIMenuExpand
            : ViewIdentifier.GUIMenuCollapse
    "
    dtName="{{ isCollapsed ? 'Expand Action' : 'Collapse Action' }}"
>
</dxy-nav-sidebar-item>
