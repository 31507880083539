import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import {
    NavigateTo,
    NavigationService,
} from '../../services/navigation.service';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { ISpaceBreadcrumbInfo } from '../../shared/util/BreadcrumbUtil';
import {
    HierarchyDataDescriptor,
    IHierarchicalData,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { HddUtil } from '../../shared/util/HddUtil';
import { DisplayedHData } from '../../shared/util/app-types/DisplayedHData';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { SecurityService } from '../../services/security.service';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService extends BaseService {
    constructor(
        private navigationService: NavigationService,
        private entityUiService: EntityUiService,
        private workspaceStore: WorkspaceStore,
        private securityService: SecurityService,
        private functionalLogService: FunctionalLogService,
    ) {
        super();
    }

    /** note: This assumes that first item's HddData is referencing a space */
    public getSpaceBreadcrumbInfo(
        breadcrumbData: BreadcrumbData,
    ): ISpaceBreadcrumbInfo {
        const firstItem = breadcrumbData.items[0];
        if (!firstItem?.HddData?.Data?.isSpace?.()) {
            return;
        }

        const spaceId = firstItem.HddData.DataReferenceId;
        const navSpace = this.workspaceStore.getNavSpace(spaceId);
        return {
            IconHash: navSpace?.IconHash,
            DisplayName: navSpace?.DisplayName,
            Trigram: navSpace?.Trigram,
        };
    }

    public getBreadcrumb(
        hdata: IHierarchicalData,
        options: IBreadcrumbOptions = {},
    ) {
        const {
            readOnly,
            includeSelf,
            forceIncludeSpace,
            forceExcludeSpace,
            getTabNameToNavTo,
        } = options;
        const spaceIdr = WorkspaceIdentifier.fromHdd(hdata.Data);
        const spaceIsSameAsContext =
            this.securityService.isSingleWorkspaceClient() ||
            (this.workspaceStore.hasCurrentSpace &&
                WorkspaceIdentifier.areSame(
                    spaceIdr,
                    this.workspaceStore.currentSpace,
                ));
        const excludeSpace =
            forceExcludeSpace || (!forceIncludeSpace && spaceIsSameAsContext);
        const items: DisplayedHData[] = [];

        if (hdata) {
            const currentHdd = hdata.Data;
            const hdds = this.getHierarchyDataDescriptorsForBreadcrumb(hdata);
            hdds.forEach((hdd) => {
                if (excludeSpace && HddUtil.isSpace(hdd)) {
                    return;
                }

                const isCurrent =
                    currentHdd && hdd.DataLocalId == currentHdd.DataLocalId;
                const hData = isCurrent
                    ? hdata
                    : HddUtil.extractParentHData(hdata, hdd.DataReferenceId);

                if (includeSelf || !isCurrent) {
                    const navToTabName = getTabNameToNavTo?.(hData);
                    items.push(this.buildItem(hData, readOnly, navToTabName));
                }
            });
        }
        return new BreadcrumbData(items);
    }
    private buildItem(
        hData: IHierarchicalData,
        isReadOnly: boolean,
        navToTab?: string,
    ) {
        return this.entityUiService.buildDisplayedHData(
            hData,
            isReadOnly,
            navToTab,
        );
    }

    public getNavTo(navToLineage = false, navToTabName?: string) {
        return this.navigationService.getNavTo(navToLineage, navToTabName);
    }

    public async navigateByHierarchicalData(
        hdata: IHierarchicalData,
        destination: NavigateTo,
        isFromBreadcrumb: boolean,
        isFromHierarchicalView = false,
    ) {
        if (isFromBreadcrumb) {
            this.functionalLogService.logFunctionalAction(
                'NAV_BREADCRUMB',
                CrudOperation.R,
            );
        }
        await this.navigationService.goToWithHierarchicalData(hdata, {
            destination,
            isFromHierarchicalView,
            broadcastStateChangeSuccess: true,
        });
    }

    private getHierarchyDataDescriptorsForBreadcrumb(hdata: IHierarchicalData) {
        const dataDescriptors = HddUtil.getHierarchyDataDescriptorList(hdata)
            .filter((hdd) => this.isAvailableDataDescriptorForBreadcrumb(hdd))
            .reverse();

        dataDescriptors.push(hdata.Data);
        return dataDescriptors;
    }

    private isAvailableDataDescriptorForBreadcrumb(
        parentDataDescriptor: HierarchyDataDescriptor,
    ) {
        if (parentDataDescriptor.IsTechnical) return false;

        switch (ServerType[parentDataDescriptor.DataTypeName]) {
            case ServerType.Project:
            case ServerType.Property:
            case ServerType.Model:
            case ServerType.Container:
            case ServerType.Table:
            case ServerType.Column:
            case ServerType.DataProcessing:
            case ServerType.SoftwareElement:
            case ServerType.Diagram:
                return true;
            default:
                return false;
        }
    }
}

/** holds an array of DisplayedHData for to display a breadcrumb */
export class BreadcrumbData {
    public items: DisplayedHData[];

    constructor(items: DisplayedHData | DisplayedHData[]) {
        this.items = Array.isArray(items) ? items : items ? [items] : [];
    }
}

export interface IBreadcrumbOptions {
    readOnly?: boolean;
    includeSelf?: boolean;
    /* if false, space is included only if no current space is selected */
    forceIncludeSpace?: boolean;
    /* if false, space is included only if no current space is selected */
    forceExcludeSpace?: boolean;
    getTabNameToNavTo?: (hd: IHierarchicalData) => string | undefined;
}
