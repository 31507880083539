import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
    selector: '[dxyMultilingualReadMoreButton]',
    standalone: true,
})
export class MultilingualReadMoreButtonDirective implements OnInit {
    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private translate: TranslateService,
    ) {}

    ngOnInit() {
        this.renderer.setAttribute(
            this.elementRef.nativeElement,
            'href',
            'https://datagalaxy.freshdesk.com/a/solutions/articles/35000247859',
        );
        this.renderer.setAttribute(
            this.elementRef.nativeElement,
            'target',
            '_blank',
        );
        this.elementRef.nativeElement.innerText = this.translate.instant(
            'Multilingual.Administration.readMore',
        );
    }
}
