<ng-container *ngIf="state$ | async as state">
    <div *ngIf="state.enabled" class="language-selector-container">
        <dxy-spinner
            *ngIf="loading$ | async; else content"
            class="xs no-margin"
        ></dxy-spinner>

        <ng-template #content>
            <button
                dxyIconButton
                type="button"
                variant="tertiary"
                size="medium"
                [matMenuTriggerFor]="menu"
                [matTooltip]="'Multilingual.changeMyLanguage' | translate"
            >
                <dxy-language-icon
                    [languageCode]="selectedLanguageCode"
                ></dxy-language-icon>
                <span
                    *ngIf="
                        state.hasPendingBatch || state.hasPendingRegeneration
                    "
                    @fadeInFadeOut
                    class="language-pending-batch"
                ></span>
            </button>
            <mat-menu #menu="matMenu" class="dg5-mat-menu">
                <button
                    *ngFor="let languageCode of state.languageCodes"
                    class="select-language-menu-button"
                    mat-menu-item
                    [class.active]="languageCode === selectedLanguageCode"
                    (click)="selectLanguage(languageCode)"
                >
                    <dxy-language-card
                        [languageCode]="languageCode"
                    ></dxy-language-card>
                    <dxy-translation-progress
                        [displayMode]="'icon'"
                        [languageCode]="languageCode"
                    ></dxy-translation-progress>
                </button>
                <a
                    *ngIf="administrationLinkVisible"
                    mat-menu-item
                    [uiSref]="adminRouteStateName"
                >
                    <span translate>Multilingual.manageLanguages</span>
                </a>
            </mat-menu>
        </ng-template>
    </div>
</ng-container>
