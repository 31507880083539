import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { IActionOption } from '@datagalaxy/core-ui';
import { IHasAttributeFilterModels } from '../../shared/attribute/attribute-filter/attribute-filter-form/IFilterFormModel';
import { AttributeFilterModel } from '../../shared/attribute/attribute-filter/attribute-filter/attributeFilterModel';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import {
    ISpotlightSection,
    TSpotlightSectionItem,
} from '../dxy-spotlight-result-section/spotlight-result-section.types';
import {
    HierarchicalData,
    HierarchyDataDescriptor,
    IHasHddData,
} from '@datagalaxy/dg-object-model';
import { NavigationService } from '../../services/navigation.service';
import { EntityPreviewPanelService } from '../../shared/entity/services/entity-preview-panel.service';
import { SearchService } from '../../search/search.service';
import { FilteredEntityItem } from '@datagalaxy/webclient/search/data-access';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityIdentifier } from '@datagalaxy/webclient/entity/utils';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaValue } from '@datagalaxy/webclient/attribute/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { AttributeFilterCarouselComponent } from '../../shared/attribute/attribute-filter/attribute-filter-carousel/attribute-filter-carousel.component';
import { DxyColorPointCellComponent } from '@datagalaxy/core-ui/cell-components';
import { TranslateModule } from '@ngx-translate/core';
import { DxyProfileAvatarComponent } from '../../shared/shared-ui/dxy-profile-avatar/dxy-profile-avatar.component';
import { EntityCardCellComponent } from '../../shared/entityCard/entity-card/entity-card-cell.component';
import { FilteredEntityCardCellComponent } from '../../shared/entityCard/filtered-entity-card-cell/filtered-entity-card-cell.component';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { NgClass, NgIf } from '@angular/common';
import { SecurityService } from '../../services/security.service';

@Component({
    selector: 'dxy-spotlight-result-item',
    templateUrl: './dxy-spotlight-result-item.component.html',
    styleUrls: ['./dxy-spotlight-result-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        CdkDrag,
        NgClass,
        FilteredEntityCardCellComponent,
        EntityCardCellComponent,
        DxyProfileAvatarComponent,
        TranslateModule,
        DxyColorPointCellComponent,
        AttributeFilterCarouselComponent,
        EllipsisTooltipDirective,
    ],
})
export class DxySpotlightResultItemComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() item: TSpotlightSectionItem;
    @Input() section: ISpotlightSection;
    @Input() spaceIdr: IWorkspaceIdentifier;

    @Output() lastSearchClick = new EventEmitter<IHasAttributeFilterModels>();
    @Output() onSelectFilterValue = new EventEmitter<AttributeMetaValue>();
    @Output() onClickResultItem = new EventEmitter();

    public isEntityItem: boolean;
    public isFilterItems: boolean;
    public isAttributeMetaValue: boolean;

    public entityItem: EntityItem;
    public entityActions: IActionOption<IHasHddData>[] = [];

    public lastSearchTerm: string;
    public filterItems: AttributeFilterModel[] = [];

    public amv: AttributeMetaValue;
    public isUserAMV: boolean;
    public isDomainAMV: boolean;
    public isTagAMV: boolean;

    public forceBreadcrumbSpaceIcon: boolean;

    public hoverLastFilterItems = false;

    public dragHdd: HierarchyDataDescriptor;
    public dragging = false;
    public get dragDrop() {
        return this.searchService.spotlightDragDrop;
    }
    public get dragItemClass() {
        return this.dragDisabled ? '' : this.dragDrop?.itemClass;
    }
    public get dragDisabled() {
        const dd = this.dragDrop;
        return (
            !dd || dd.disabled || !this.dragHdd || dd.isDisabled?.(this.dragHdd)
        );
    }

    public get hierarchicalData() {
        return (this.item as IHasHddData).HddData as HierarchicalData;
    }
    public get filteredEntityItem() {
        return this.item instanceof FilteredEntityItem ? this.item : undefined;
    }
    private get featureCode() {
        return this.navigationService.isInSpaceHomeView
            ? 'MAIN_SEARCH'
            : 'SPOTLIGHT';
    }
    /** true if filter carousel is scrolled, it prevents lastSearchClick event to be emitted */
    private filterScrolled: boolean;

    constructor(
        private entityPreviewPanelService: EntityPreviewPanelService,
        private searchService: SearchService,
        private navigationService: NavigationService,
        private functionalLogService: FunctionalLogService,
        private securityService: SecurityService,
        private entityUiService: EntityUiService,
    ) {
        super();
    }

    ngOnInit() {
        this.log('onInit', this.item);
        this.init();
    }

    private init() {
        this.forceBreadcrumbSpaceIcon =
            !this.securityService.isSingleWorkspaceClient() &&
            !this.spaceIdr?.spaceId;

        this.isFilterItems = 'filterItems' in this.item;
        this.isAttributeMetaValue = this.item instanceof AttributeMetaValue;
        this.isEntityItem =
            !this.isAttributeMetaValue && 'HddData' in this.item;

        if (this.isFilterItems) {
            this.setupFilterItems();
        } else if (this.isAttributeMetaValue) {
            this.setupAttributeMetaValue();
        } else if (this.isEntityItem) {
            this.setupEntityItem();
        }
    }

    //#region event handlers

    public onSelectAttributeMetaValue() {
        this.log('onSelectAttributeMetaValue', this.amv);
        this.onSelectFilterValue?.emit(this.amv);
    }

    public onClickPreviewItem(event: Event) {
        // prevent preview panel opening when dragging item to a diagram
        if (this.dragging) {
            event.stopPropagation();
            return;
        }
        // prevent preview pane opening when clicking a link (e.g. in entity-card/nav-breadcrumb/dxyNavDataLink)
        if ((event.target as Element)?.tagName == 'A') {
            return;
        }

        this.onClickResultItem.emit();
        const entityIdr = EntityIdentifier.fromIHierarchicalData(
            this.hierarchicalData,
        );
        if (EntityPreviewPanelService.isPanelAvailable(entityIdr.entityType)) {
            if (!this.entityPreviewPanelService.isPanelVisible) {
                this.functionalLogService.logFunctionalAction(
                    `OBJECT_PREVIEW_${this.featureCode}`,
                    CrudOperation.R,
                );
            }
            this.entityPreviewPanelService.setupPanel({ entityIdr });
        }
    }

    public onLastSearchClick() {
        if (this.filterScrolled) {
            this.filterScrolled = false;
            return;
        }
        this.lastSearchClick.emit(this.item as IHasAttributeFilterModels);
    }

    public onFilterScroll() {
        this.filterScrolled = true;
    }

    public onDragStart() {
        this.dragging = true;
    }

    public onDragEnded() {
        setTimeout(() => (this.dragging = false), 10);
    }

    //#endregion

    private setupFilterItems() {
        const filterForm = this.item as IHasAttributeFilterModels;
        this.filterItems = filterForm.filterItems.filter(
            (afm) =>
                afm.attributeKey !== ServerConstants.Search.SearchTermFilterKey,
        );
        this.lastSearchTerm = filterForm.filterItems
            .find(
                (afm) =>
                    afm.attributeKey ==
                    ServerConstants.Search.SearchTermFilterKey,
            )
            ?.getValuesAsArray()?.[0];
    }

    private setupEntityItem() {
        const entity = (this.entityItem = this.item as EntityItem);
        this.dragHdd = entity.HddData.Data;

        const entityBurgerMenuOption =
            this.entityUiService.getEntityCellNavigationMenu('SPOTLIGHT');
        this.entityActions = [entityBurgerMenuOption];
    }

    private setupAttributeMetaValue() {
        const amv = (this.amv = this.section.isFilter
            ? (this.item as AttributeMetaValue)
            : undefined);
        const attributeKey = amv.attributeInfo.AttributeKey;

        this.isUserAMV =
            SearchService.isSpaceGovUserFilterElement(attributeKey);
        this.isDomainAMV =
            SearchService.isBusinessDomainFilterElement(attributeKey);
        this.isTagAMV = SearchService.isTagFilterElement(attributeKey);
    }

    private onItemClick() {
        this.log('onItemClick');
        this.functionalLogService.logFunctionalAction(
            `OBJECT_DETAILS_${this.featureCode}`,
            CrudOperation.R,
        );
        this.searchService.notifySpotlightPanelOpen(false);
    }
}
