import { UIRouter, UIRouterModule, UIView } from '@uirouter/angular';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { appRoutes } from './app.routes';
import { appRouterConfig } from './app-router.config';
import { initializeAppForCurrentUser } from './current-user-app-initializer';
import { CurrentUserInitializer } from '@datagalaxy/webclient/user/feature/current-user';
import { AppSharedModule } from './app.shared.module';
import { AppDataService } from './services/app-data.service';
import { AuthV2TempAppDataService } from './services/auth-v2-temp-app-data-service';
import { TranslateService } from '@ngx-translate/core';
import { API_BASE_URL } from './api-config';
import { ClientModule } from './client/client.module';
import { StateName } from '@datagalaxy/webclient/routing';
import { ClientServiceManagerService } from './client/client-service-manager.service';
import { apiBaseUrlFactory } from './api-base-url-factory';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { InactivityLogoutService } from '@datagalaxy/webclient/auth/feature';
import { OpenFeatureInitializer } from '@datagalaxy/webclient/feature-flag';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';

@NgModule({
    imports: [
        AppSharedModule,
        ClientModule,
        UIRouterModule.forRoot({
            states: appRoutes,
            otherwise: { state: StateName.Client },
            useHash: false,
            deferIntercept: true,
            config: (uiRouter, injector) => appRouterConfig(uiRouter, injector),
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppForCurrentUser,
            deps: [
                CurrentUserInitializer,
                CurrentUserService,
                InactivityLogoutService,
                OpenFeatureInitializer,
                TranslateService,
                ClientServiceManagerService,
                UIRouter,
            ],
            multi: true,
        },
        {
            provide: API_BASE_URL,
            useFactory: apiBaseUrlFactory,
            deps: [AppConfigService],
        },
        {
            provide: AppDataService,
            useClass: AuthV2TempAppDataService,
        },
    ],
    bootstrap: [UIView],
})
export class AppModule {
    constructor() {}
}
