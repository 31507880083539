import { Ng2StateDeclaration } from '@uirouter/angular/interface';
import { StateName } from '@datagalaxy/webclient/routing';
import { AccountExpiredTrialPageComponent } from './account-expired-trial-page/account-expired-trial-page.component';
import { AccountModelService } from './services/account-model.service';
import { Constants } from '../shared/util/Constants';
import { AccountLoginComponent } from './account-login/account-login.component';
import { LegacyLoginService } from '../services/login.service';
import { LegacyAuthenticationService } from '../services/authentication.service';
import { NavigationService } from '../services/navigation.service';
import { StateParams } from '@uirouter/core';
import { AccountSetPasswordComponent } from './account-set-password/account-set-password.component';
import { AccountSetPasswordMode } from './models/account-set-password.model';
import { SeeYouSoonComponent } from '@datagalaxy/webclient/auth/ui';
import ParamKey = Constants.Nav.ParamKey;
import UrlWord = Constants.Nav.UrlWord;

export const loginRoute: Ng2StateDeclaration = {
    name: StateName.AccountLogin,
    url: `^/${UrlWord.login}?al&cc`,
    component: AccountLoginComponent,
    resolve: [
        {
            token: '_parameters',
            deps: [
                '_initLogin',
                '$stateParams',
                LegacyLoginService,
                LegacyAuthenticationService,
                NavigationService,
            ],
            resolveFn: (
                _initLogin: any,
                $stateParams: StateParams,
                loginService: LegacyLoginService,
                authenticationService: LegacyAuthenticationService,
                navigationService: NavigationService,
            ) => {
                loginService.handleSystemStartupStateParameters($stateParams);
                navigationService.clearCurrentContext();
                return authenticationService.showLoginScreen();
            },
        },
    ],
};

export const resetPasswordRoute: Ng2StateDeclaration = {
    name: StateName.AccountMainReset,
    url: `/${UrlWord.reset}?t`,
    component: AccountSetPasswordComponent,
    resolve: [
        {
            token: 'accountSetPasswordData',
            deps: ['$stateParams', AccountModelService],
            resolveFn: async (
                $stateParams: StateParams,
                accountModelService: AccountModelService,
            ) => {
                return await accountModelService.createAccountSetPasswordModel(
                    $stateParams['t'],
                    AccountSetPasswordMode.ResetPassword,
                );
            },
        },
    ],
};

export const externalLoginRoute: Ng2StateDeclaration = {
    name: StateName.AccountExternalLogin,
    url: `^/${UrlWord.extlogin}?${ParamKey.Status}&${ParamKey.Path}&${ParamKey.LoginToken}`,
    resolve: [
        {
            token: 'extLoginParameters',
            deps: ['_initLogin', '$stateParams', LegacyAuthenticationService],
            resolveFn: (
                _initLogin: any,
                $stateParams: StateParams,
                authenticationService: LegacyAuthenticationService,
            ) => {
                if ($stateParams[ParamKey.Status] == '1') {
                    // not awaited
                    authenticationService.doExternalLogin($stateParams);
                } else {
                    // not awaited
                    authenticationService.handleExternalLoginFailed(
                        $stateParams,
                    );
                }
            },
        },
    ],
};

export const externalLogoutRoute: Ng2StateDeclaration = {
    name: StateName.AccountExternalLogout,
    url: `/${UrlWord.seeYouSoon}`,
    component: SeeYouSoonComponent,
};

export const expiredLicenseRoute: Ng2StateDeclaration = {
    name: StateName.MainExpiredLicense,
    url: `/${UrlWord.expiredTrialLicense}`,
    component: AccountExpiredTrialPageComponent,
};

export const setPasswordRoute: Ng2StateDeclaration = {
    name: StateName.AccountMainInit,
    url: `^/${UrlWord.init}?t`,
    component: AccountSetPasswordComponent,
    resolve: [
        {
            token: 'accountSetPasswordData',
            deps: ['$stateParams', AccountModelService],
            resolveFn: async (
                $stateParams: StateParams,
                accountModelService: AccountModelService,
            ) => {
                return await accountModelService.createAccountSetPasswordModel(
                    $stateParams['t'],
                    AccountSetPasswordMode.InitialPassword,
                );
            },
        },
    ],
};

export const accountRoutes: Ng2StateDeclaration[] = [
    setPasswordRoute,
    resetPasswordRoute,
    loginRoute,
    externalLoginRoute,
    externalLogoutRoute,
    expiredLicenseRoute,
];
