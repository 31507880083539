import { Component } from '@angular/core';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-account-expired-trial-page',
    templateUrl: 'account-expired-trial-page.component.html',
    styleUrls: ['account-expired-trial-page.component.scss'],
    standalone: true,
    imports: [TranslateModule, DxyButtonDirective],
})
export class AccountExpiredTrialPageComponent extends DxyBaseComponent {
    public linkUrl =
        'https://solutions.datagalaxy.com/meetings/productengagement/lets-meet';

    constructor() {
        super();
    }
}
