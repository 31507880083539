import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {
    DxyBurgerMenuComponent,
    EllipsisTooltipDirective,
    IListOption,
} from '@datagalaxy/core-ui';
import { ViewIdentifier } from '../../shared/util/ViewIdentifier';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { UIRouterModule } from '@uirouter/angular';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { TooltipDirective } from '@datagalaxy/ui/tooltip';
import { fadeInFadeOutAnimation } from '@datagalaxy/ui/animation';

@Component({
    selector: 'dxy-nav-sidebar-item',
    templateUrl: './dxy-nav-sidebar-item.component.html',
    styleUrls: ['./dxy-nav-sidebar-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        DxyLogFunctionalDirective,
        DxyDataTestIdDirective,
        MatLegacyMenuModule,
        NgIf,
        NgClass,
        DxyBurgerMenuComponent,
        AsyncPipe,
        TranslateModule,
        UIRouterModule,
        EllipsisTooltipDirective,
        DxyIconButtonDirective,
        TooltipDirective,
    ],
    animations: [fadeInFadeOutAnimation('150ms')],
})
export class DxyNavSidebarItemComponent
    extends DxyBaseComponent
    implements AfterViewInit
{
    @Input() stateId: string;
    @Input() itemIdentifier: string;
    @Input() glyphClass: string;
    @Input() dtName: string;
    @Input() showTooltip = false;
    @Input() tooltipText = '';
    @Input() menuOptions: IListOption[] = null;
    @Input() hasCustomMenu = false;
    @Input() menuClass: string;

    @Output() onMatMenuTrigger = new EventEmitter();

    @ViewChild('templateRef') private templateRef: TemplateRef<any>;
    @ViewChild('menuTrigger') private menuTrigger: ElementRef;

    protected isOpen = false;

    private portal: TemplatePortal;
    private overlayRef: OverlayRef;

    public get caption() {
        return this.tooltipText
            ? this.tooltipText
            : this.translate.instant(this.translateKey);
    }

    public get dtNameFull() {
        return `Side Bar - ${this.dtName}`;
    }
    public get translateKey(): string {
        return `UI.NavSideBar.${this.itemIdentifier}`;
    }
    public get isGlyphUndefined() {
        return !this.glyphClass;
    }
    public get logModule() {
        const prjOrgPrefix = 'WORKSPACE_';
        switch (this.itemIdentifier) {
            case ViewIdentifier.ClientDashboard:
                return 'MAIN_DASHBOARD,R';
            case ViewIdentifier.Glossary:
                return `MAIN_${prjOrgPrefix}GLOSSARY,R`;
            case ViewIdentifier.Modeler:
                return `MAIN_${prjOrgPrefix}DICTIONARY,R`;
            case ViewIdentifier.DataProcessing:
                return `MAIN_${prjOrgPrefix}DATAPROCESSING,R`;
            case ViewIdentifier.ImpactAnalysis:
                return `MAIN_${prjOrgPrefix}IMPACT_ANALYSIS,R`;
            case ViewIdentifier.Diagrams:
                return `MAIN_${prjOrgPrefix}DIAGRAMS,R`;
            case ViewIdentifier.Software:
                return `MAIN_${prjOrgPrefix}USES,R`;
            case ViewIdentifier.ClientTasks:
                return 'MAIN_TASK_SCREEN,R';
            case ViewIdentifier.SpaceSettings:
                return `MAIN_${prjOrgPrefix}SETTINGS,R`;
            case ViewIdentifier.Campaigns:
                return `MAIN_${prjOrgPrefix}CAMPAIGNS,R`;
        }
    }

    public get tooltipContent() {
        return this.showTooltip ? this.tooltipText || this.translateKey : null;
    }

    constructor(
        private translate: TranslateService,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
    ) {
        super();
    }

    ngAfterViewInit() {
        this.portal = new TemplatePortal(
            this.templateRef,
            this.viewContainerRef,
        );
    }

    public async onClick() {
        if (!this.menuOptions && !this.hasCustomMenu) {
            return;
        }

        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.menuTrigger.nativeElement)
            .withPositions([
                {
                    originX: 'end',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'top',
                    offsetX: 20,
                },
            ])
            .withLockedPosition(true);

        const overlayRef = this.overlay.create({
            positionStrategy,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
        });

        this.overlayRef = overlayRef;

        overlayRef.attach(this.portal);

        overlayRef.backdropClick().subscribe(() => overlayRef.detach());

        return;
    }

    protected onMenuClick() {
        this.overlayRef.detach();
    }
}
