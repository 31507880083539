import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Inject,
} from '@angular/core';
import { executeOnce, withLoading } from '@datagalaxy/core-ui';
import {
    MultilingualApiService,
    MultilingualAttributeSetting,
} from '@datagalaxy/webclient/multilingual/data-access';
import { BaseComponent } from '@datagalaxy/utils';
import { MultilingualStateService } from '../../multilingual-state.service';
import { BehaviorSubject, combineLatest, map, mergeMap } from 'rxjs';
import { DIALOG_SERVICE_TOKEN, IDialogService } from '@datagalaxy/ui/dialog';
import {
    AttributeCellComponent,
    ATTRIBUTES_FINDER_TOKEN,
    AttributesFinder,
} from '@datagalaxy/webclient/attribute/feature';
import { AddAttributesModalComponent } from '../add-attributes-modal/add-attributes-modal.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultilingualAttribute } from './multilingual-attribute';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { SearchInputComponent } from '@datagalaxy/ui/search';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'dxy-multilingual-attributes',
    templateUrl: './multilingual-attributes.component.html',
    styleUrls: ['./multilingual-attributes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        SpinnerComponent,
        SearchInputComponent,
        DxyIconButtonDirective,
        MatLegacyTooltipModule,
        NgFor,
        AttributeCellComponent,
        AsyncPipe,
        TranslateModule,
    ],
})
export class MultilingualAttributesComponent
    extends BaseComponent
    implements AfterViewInit
{
    protected searchTerm$ = new BehaviorSubject<string>('');

    protected multilingualAttributes$ = combineLatest([
        this.multilingualStateService
            .selectAttributeSettings()
            .pipe(
                mergeMap((settings) =>
                    this.convertToMutlilingualAttributes(settings),
                ),
            ),
        this.searchTerm$,
    ]).pipe(
        map(([augmentedSettings, searchTerm]) => {
            return augmentedSettings.filter((s) => {
                const name =
                    s.attribute?.translatedDisplayName ??
                    s.attribute?.DisplayName ??
                    '';
                return name.toLowerCase().includes(searchTerm?.toLowerCase());
            });
        }),
    );

    constructor(
        private multilingualApiService: MultilingualApiService,
        private multilingualStateService: MultilingualStateService,
        @Inject(ATTRIBUTES_FINDER_TOKEN)
        private attributesFinder: AttributesFinder,
        @Inject(DIALOG_SERVICE_TOKEN) private dialogService: IDialogService,
        private translate: TranslateService,
    ) {
        super();
    }
    ngAfterViewInit() {
        this.loadAttributeSettings();
    }

    protected onSearchStringChange(searchString: string | undefined) {
        this.searchTerm$.next(searchString ?? '');
    }

    protected async openAddAttributesModal() {
        await this.dialogService.open<AddAttributesModalComponent, void, void>({
            componentType: AddAttributesModalComponent,
        });
    }

    @executeOnce()
    protected async removeAttribute(
        multilingualAttribute: MultilingualAttribute,
    ) {
        if (
            !multilingualAttribute.setting.id ||
            !multilingualAttribute.attribute
        ) {
            return;
        }
        if (!(await this.confirmRemoval(multilingualAttribute))) {
            return;
        }
        this.multilingualStateService.removeAttributeSetting(
            multilingualAttribute.setting,
        );
        try {
            await this.multilingualApiService.removeAttributeSetting(
                multilingualAttribute.setting.id,
            );
        } catch (e) {
            // Revert the change
            this.multilingualStateService.addAttributeSettings([
                multilingualAttribute.setting,
            ]);
            setTimeout(() => {
                throw e;
            }, 0);
        }
    }

    private async confirmRemoval(multilingualAttribute: MultilingualAttribute) {
        return await this.dialogService.confirm({
            message: this.translate.instant(
                'Multilingual.Administration.AttributesTab.removeAttributeConfirmation',
                {
                    attributeName:
                        multilingualAttribute.attribute
                            ?.translatedDisplayName ??
                        multilingualAttribute.attribute?.DisplayName ??
                        '',
                },
            ),
            titleKey:
                'Multilingual.Administration.AttributesTab.removeAttribute',
            confirmButtonKey:
                'Multilingual.Administration.AttributesTab.removeAttribute',
        });
    }

    @withLoading()
    private async loadAttributeSettings() {
        const attributeSettings =
            await this.multilingualApiService.getAttributeSettings();
        this.multilingualStateService.updateAttributeSettings(
            attributeSettings,
        );
    }

    private async convertToMutlilingualAttributes(
        settings: MultilingualAttributeSetting[],
    ): Promise<MultilingualAttribute[]> {
        const attributes = await this.attributesFinder.searchAttributes((a) =>
            settings.some((s) => s.attributePath === a.AttributePath),
        );
        return settings
            .map((setting) => ({
                setting,
                attribute: attributes.find(
                    (a) => a.AttributePath === setting.attributePath,
                ),
            }))
            .filter((s) => !!s.attribute);
    }
}
