import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
    DxyTabsHeaderComponent,
    ITabItem,
    ITabsHeaderData,
} from '@datagalaxy/core-ui';
import { SuggestionPanelUiService } from '../suggestion-panel-ui.service';
import { map } from 'rxjs';
import { AppDataService } from '../../services/app-data.service';
import { SuggestionType } from '@datagalaxy/webclient/suggestion/types';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { WorkspaceIdentifier } from '@datagalaxy/webclient/workspace/utils';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import { ModuleStore } from '../../module/module.store';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { SuggestionPanelLinksComponent } from '../suggestion-panel-links/suggestion-panel-links.component';
import { SuggestionPanelGlossaryImportComponent } from '../suggestion-panel-glossary-import/suggestion-panel-glossary-import.component';
import { SuggestionPanelGridComponent } from '../suggestion-panel-grid/suggestion-panel-grid.component';
import { SuggestionAttributeFilterManagerComponent } from '../suggestion-attribute-filter-manager/suggestion-attribute-filter-manager.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { DxySpaceVersionSelectorComponent } from '../../workspace/workspace-version-selector/dxy-space-version-selector/dxy-space-version-selector.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';

/***
 * ## Role
 * Suggestion panel containing filterable suggestions current user can accept or decline
 */
@Component({
    selector: 'app-suggestion-panel',
    templateUrl: './suggestion-panel.component.html',
    styleUrls: ['./suggestion-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        DxySpaceVersionSelectorComponent,
        TranslateModule,
        MatSlideToggleModule,
        DxyTabsHeaderComponent,
        SuggestionAttributeFilterManagerComponent,
        SuggestionPanelGridComponent,
        SuggestionPanelGlossaryImportComponent,
        SuggestionPanelLinksComponent,
        AsyncPipe,
    ],
})
export class SuggestionPanelComponent extends DxyBaseComponent {
    public static readonly gridRowHeight = 50;

    protected tabsData?: ITabsHeaderData<SuggestionType>;
    protected spaceIdr: WorkspaceIdentifier = new WorkspaceIdentifier(null);

    public override get loading$() {
        return this.suggestionPanelUiService.loading$;
    }

    protected get settings$() {
        return this.suggestionPanelUiService.settings$;
    }

    protected get spaceFiltersEnabled$() {
        return this.loading$.pipe(map((loading) => !loading));
    }

    protected get isSuggestionTab() {
        return [SuggestionType.Tag, SuggestionType.Dcp].includes(
            this.currentSuggestionType,
        );
    }

    protected get isLinkTab() {
        return this.currentTab.tabId === 'Link';
    }

    protected get isGlossaryTab() {
        return this.currentTab.tabId === 'Glossary';
    }

    protected get currentSuggestionType() {
        return this.currentTab?.data;
    }

    private currentTab: ITabItem<SuggestionType>;

    private get isReader() {
        return (
            this.appDataService.currentUserLicenseLevel == LicenseLevel.Reader
        );
    }

    constructor(
        private suggestionPanelUiService: SuggestionPanelUiService,
        private functionalLogService: FunctionalLogService,
        private appDataService: AppDataService,
        private moduleStore: ModuleStore,
    ) {
        super();
        this.initParameters();
    }

    protected onToggleChange() {
        this.suggestionPanelUiService.toggleSuggestions();
    }

    protected onTabChange(tabItem: ITabItem<SuggestionType>) {
        this.currentTab = tabItem;
        this.functionalLogService.logFunctionalAction(
            `SUGGESTIONS_CENTER_${tabItem.tabId.toUpperCase()}`,
            CrudOperation.R,
        );
        if (!this.currentSuggestionType) {
            return;
        }
        this.suggestionPanelUiService.filterBySuggestionType(
            this.currentSuggestionType,
        );
    }

    protected onSpaceSelected(spaceIdr: IWorkspaceIdentifier) {
        if (spaceIdr.spaceId != this.spaceIdr.spaceId) {
            this.functionalLogService.logFunctionalAction(
                'SUGGESTIONS_CENTER_SELECT_SPACE',
                CrudOperation.R,
            );

            this.spaceIdr = spaceIdr;
        }
        if (
            spaceIdr.versionId &&
            spaceIdr.versionId != this.spaceIdr.versionId
        ) {
            this.functionalLogService.logFunctionalAction(
                'SUGGESTIONS_CENTER_SELECT_VERSION',
                CrudOperation.R,
            );

            this.spaceIdr = spaceIdr;
        }
        this.suggestionPanelUiService.filterBySpaceIdr(spaceIdr);
    }

    private initParameters() {
        this.suggestionPanelUiService.initParameters(
            this.currentSuggestionType,
        );
        this.createTabsData();
    }

    private createTab(
        suggestionType: SuggestionType,
    ): ITabItem<SuggestionType> {
        const suggestionTypeKey = SuggestionType[suggestionType];
        return {
            tabId: suggestionTypeKey.toUpperCase(),
            tabTranslateKey: `UI.Suggestions.Panel.Tabs.${suggestionTypeKey}`,
            tabTooltipKey:
                suggestionType == SuggestionType.Dcp
                    ? 'UI.Suggestions.Panel.Tabs.DcpTooltip'
                    : undefined,
            showEmptyDataCount: true,
            data: suggestionType,
        };
    }

    private createTabsData() {
        this.tabsData = {
            tabItems: [
                {
                    tabId: 'Link',
                    tabTranslateKey: 'UI.Suggestions.Panel.Tabs.Link',
                    disabled: this.isReader,
                },
                this.createTab(SuggestionType.Tag),
                this.createTab(SuggestionType.Dcp),
            ],
        };

        if (this.hasUserAccessToGlossary()) {
            this.tabsData.tabItems.push({
                tabId: 'Glossary',
                tabTranslateKey: 'UI.Suggestions.Panel.Tabs.Glossary',
                disabled: this.isReader,
            });
        }
        this.currentTab = this.tabsData.tabItems[0];
    }

    private hasUserAccessToGlossary() {
        const accessibleModules = this.moduleStore.modules;
        return accessibleModules.some(
            (m) => DgModule[m.name] === DgModule.Glossary,
        );
    }
}
