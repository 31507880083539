<div *ngIf="!isLoading" class="dxy-actions">
    <button
        dxyButton
        variant="secondary"
        color="destructive"
        size="medium"
        (click)="onRejectAllClick()"
        [disabled]="areButtonsDisabled"
    >
        <i class="glyph-cancelsearch"></i>
        <span translate="UI.Suggestions.Panel.rejectAll"></span>
    </button>
    <button
        dxyButton
        variant="secondary"
        color="success"
        size="medium"
        (click)="onAcceptAllClick()"
        [disabled]="areButtonsDisabled"
    >
        <i class="glyph-check"></i>
        <span translate="UI.Suggestions.Panel.acceptAll"></span>
    </button>
</div>
<dxy-spinner *ngIf="isLoading"></dxy-spinner>
