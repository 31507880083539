import { Component, Input, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { mailValidator } from '../validators/mail.validator';
import { passwordValidator } from '../validators/password.validator';
import { passwordMatchValidator } from '../validators/password-match.validator';
import {
    AccountSetPasswordMode,
    AccountSetPasswordModel,
} from '../models/account-set-password.model';
import { NavigationService } from '../../services/navigation.service';
import { AccountApiService } from '../services/account-api.service';
import { SecurityService } from '../../services/security.service';
import { catchUnsuccessfulApiErrorOrThrow } from '@datagalaxy/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyFieldPasswordComponent } from '@datagalaxy/core-ui';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { DxyFieldEmailComponent } from '@datagalaxy/core-ui';
import { NgIf } from '@angular/common';
import { AccountLogoComponent } from '../account-logo/account-logo.component';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    selector: 'app-account-set-password',
    templateUrl: 'account-set-password.component.html',
    styleUrls: ['account-set-password.component.scss'],
    standalone: true,
    imports: [
        AccountLogoComponent,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        DxyFieldEmailComponent,
        DxyDataTestIdDirective,
        TranslateModule,
        DxyFieldPasswordComponent,
        DxyButtonDirective,
    ],
})
export class AccountSetPasswordComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() accountSetPasswordData: AccountSetPasswordModel;

    public isError = false;
    public isMailSent = false;

    public accountResetPasswordForm: UntypedFormGroup;
    public get hasError() {
        return this.isServerError || this.isError;
    }
    public get isInvalidToken() {
        return (
            !this.accountSetPasswordData.isValidToken &&
            this.accountSetPasswordData.hasToken()
        );
    }

    public get mailControl() {
        return this.accountResetPasswordForm?.controls.mail;
    }
    public get newPasswordControl() {
        return this.accountResetPasswordForm?.controls.newPassword;
    }
    public get newPasswordConfirmControl() {
        return this.accountResetPasswordForm?.controls.newPasswordConfirm;
    }

    public get isResetPasswordMode() {
        return this.accountSetPasswordData?.isResetPasswordMode();
    }

    public get hasToken() {
        return this.accountSetPasswordData?.hasToken();
    }

    public get mailErrorMessage(): string {
        if (this.mailControl?.valid || this.mailControl?.untouched) {
            return undefined;
        }
        if (this.mailControl.errors.required) {
            return this.translate.instant('UI.Reset.msgEmailRequired');
        }
        return this.mailControl.errors.mailFormat;
    }

    public get newPasswordErrorMessage(): string {
        if (
            this.newPasswordControl?.valid ||
            this.newPasswordControl?.untouched
        ) {
            return undefined;
        }
        if (this.newPasswordControl.errors.required) {
            return this.translate.instant('UI.Reset.msgPasswordRequired');
        }
        return this.newPasswordControl.errors.passwordWeak;
    }

    public get newPasswordConfirmErrorMessage(): string {
        if (this.newPasswordConfirmControl?.untouched) {
            return undefined;
        }
        if (this.newPasswordConfirmControl.errors?.required) {
            return this.translate.instant(
                'UI.Reset.msgPasswordConfirmRequired',
            );
        }
        if (this.accountResetPasswordForm.errors?.passwordMismatch) {
            return this.translate.instant(
                'UI.Reset.msgPasswordConfirmMismatch',
            );
        }
    }

    private isServerError = false;

    constructor(
        private translate: TranslateService,
        private navigationService: NavigationService,
        private accountApiService: AccountApiService,
        private securityService: SecurityService,
        private formBuilder: UntypedFormBuilder,
    ) {
        super();
        this.createForm();
    }

    ngOnInit() {
        this.initForm();
    }

    public onChangeEmail() {
        this.isServerError = false;
        this.isError = false;
        this.isMailSent = false;
    }

    public sendRequest() {
        if (this.accountResetPasswordForm.invalid) {
            return;
        }

        this.accountSetPasswordData.mail = this.mailControl.value;

        if (this.accountSetPasswordData.hasToken()) {
            this.accountSetPasswordData.newPassword =
                this.newPasswordControl.value;
            this.accountSetPasswordData.newPasswordConfirm =
                this.newPasswordConfirmControl.value;
            if (this.accountSetPasswordData.isResetPasswordMode()) {
                this.doResetPassword();
            } else {
                this.doInitialPassword();
            }
        } else {
            this.doRequestForResetPassword();
        }
    }

    public goToLoginPage() {
        this.navigationService.goLogout();
    }

    public getInvalidTokenMsg() {
        const invalidToken = !this.accountSetPasswordData.isValidToken;
        const expiredToken = this.accountSetPasswordData.isExpiredToken;
        if (invalidToken && expiredToken) {
            if (
                this.accountSetPasswordData.mode ==
                AccountSetPasswordMode.InitialPassword
            ) {
                return this.translate.instant(
                    `UI.Reset.InitialPasswordExpiredTokenMsg`,
                );
            }
            return this.translate.instant(
                `UI.Reset.ResetPasswordExpiredTokenMsg`,
            );
        } else {
            if (
                this.accountSetPasswordData.mode ==
                AccountSetPasswordMode.InitialPassword
            ) {
                return this.translate.instant(
                    `UI.Reset.InitialPasswordUsedTokenMsg`,
                );
            }
            return this.translate.instant(`UI.Reset.ResetPasswordUsedTokenMsg`);
        }
    }

    private createForm() {
        this.accountResetPasswordForm = this.formBuilder.group({
            mail: [
                '',
                {
                    validators: [
                        Validators.required,
                        mailValidator(this.securityService),
                    ],
                },
            ],
        });
    }
    private initForm() {
        if (this.hasToken) {
            this.accountResetPasswordForm.addControl(
                'newPassword',
                new UntypedFormControl('', {
                    validators: [
                        Validators.required,
                        passwordValidator(this.securityService),
                    ],
                }),
            );
            this.accountResetPasswordForm.addControl(
                'newPasswordConfirm',
                new UntypedFormControl('', {
                    validators: [Validators.required],
                }),
            );
            this.accountResetPasswordForm.addValidators(
                passwordMatchValidator('newPassword', 'newPasswordConfirm'),
            );
        }
        this.accountResetPasswordForm.patchValue(this.accountSetPasswordData, {
            emitEvent: false,
        });
    }

    private async doRequestForResetPassword() {
        try {
            await this.accountApiService.requestPasswordReset(
                this.accountSetPasswordData.mail,
            );
            this.isMailSent = true;
        } catch (e) {
            catchUnsuccessfulApiErrorOrThrow(e, () => {
                this.isError = this.isServerError = true;
            });
        }
    }

    private async doResetPassword() {
        try {
            await this.accountApiService.resetPassword(
                this.accountSetPasswordData.token,
                this.accountSetPasswordData.newPassword,
            );
            await this.navigationService.goLogout();
        } catch (e) {
            catchUnsuccessfulApiErrorOrThrow(e, () => {
                this.isError = this.isServerError = true;
            });
        }
    }

    private async doInitialPassword() {
        try {
            await this.accountApiService.initPassword(
                this.accountSetPasswordData.token,
                this.accountSetPasswordData.newPassword,
            );
            await this.navigationService.goLogout();
        } catch (e) {
            catchUnsuccessfulApiErrorOrThrow(e, () => {
                this.isError = this.isServerError = true;
            });
        }
    }
}
