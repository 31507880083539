import {
    IPreviewPanelParams,
    PreviewPanelService,
} from '../preview-panel.service';
import {
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { rightToLeftEnterAnimation } from '@datagalaxy/ui/animation';
import {
    ConnectedPosition,
    GlobalPositionStrategy,
    Overlay,
    OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyPreviewPanelContentComponent } from '../dxy-preview-panel-content/dxy-preview-panel-content.component';

/**
 * ## Role
 * Right sliding preview panel element
 *
 * ## Features
 * - Display a panel in an angular overlay
 * - Panel Dom insertion and removal are animated
 * - Manage panel reopening by instanciating a new overlay instance
 *   to perform insertion and removal animations at the same time
 *
 * ## Notes
 * This component should be inserted only once for an entire application
 */
@Component({
    selector: 'dxy-preview-panel-slider',
    templateUrl: 'dxy-preview-panel-slider.component.html',
    styleUrls: ['dxy-preview-panel-slider.component.scss'],
    animations: [
        rightToLeftEnterAnimation(
            '200px',
            `${DxyPreviewPanelSliderComponent.animationDuration}ms`,
            0,
        ),
    ],
    standalone: true,
    imports: [DxyPreviewPanelContentComponent],
})
export class DxyPreviewPanelSliderComponent
    extends DxyBaseComponent
    implements OnDestroy, OnInit
{
    static animationDuration = 300;

    public params?: IPreviewPanelParams;

    public visible$ = this.previewPanelService.visible$;
    public panelParams$ = this.previewPanelService.panelParams$;
    public reopenParams$ = this.previewPanelService.reopenParams$;

    public hasBackdrop = false;

    public animationParameters = {};

    public overlayPositions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'bottom',
        },
    ];

    @ViewChild('panelWrapperRef') panelWrapperRef: TemplateRef<HTMLDivElement>;
    private overlayRef: OverlayRef;

    constructor(
        private previewPanelService: PreviewPanelService,
        private overlay: Overlay,
        private vcRef: ViewContainerRef,
    ) {
        super();
        super.subscribe(this.visible$, (v) => this.onVisibleChanged(v));
        super.subscribe(this.reopenParams$, (p) => this.onReopeningChanged(p));
        super.subscribe(this.panelParams$, (p) => (this.params = p));
    }
    ngOnInit(): void {
        this.overlayRef = this.createOverlay();
    }

    ngOnDestroy() {
        this.overlayRef?.detach();
        this.overlayRef?.dispose();
        super.ngOnDestroy();
    }

    private createOverlay() {
        return this.overlay.create({
            height: '100vh',
            width: '700px',
            positionStrategy: new GlobalPositionStrategy()
                .top('0px')
                .right('0px')
                .bottom('0px'),
        });
    }

    private onVisibleChanged(visible: boolean) {
        if (!this.overlayRef) {
            return;
        }
        if (!visible) {
            this.overlayRef.detach();
            return;
        }
        const templatePortal = new TemplatePortal(
            this.panelWrapperRef,
            this.vcRef,
        );
        this.overlayRef.attach(templatePortal);
    }

    private onReopeningChanged(params: IPreviewPanelParams) {
        if (!this.overlayRef) {
            return;
        }
        this.overlayRef.detach();
        this.params = params;
        const overlayToDispose = this.overlayRef;
        const reopeningOverlayRef = this.createOverlay();
        const templatePortal = new TemplatePortal(
            this.panelWrapperRef,
            this.vcRef,
        );
        reopeningOverlayRef.attach(templatePortal);
        this.overlayRef = reopeningOverlayRef;
        setTimeout(() => {
            overlayToDispose.dispose();
        }, DxyPreviewPanelSliderComponent.animationDuration);
    }
}
