import {
    Directive,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    inject,
} from '@angular/core';
import {
    AttributeValueTranslationResult,
    AttributeValueTranslationService,
} from '../translations';
import { Observable, firstValueFrom, map } from 'rxjs';
import { BaseComponent } from '@datagalaxy/utils';
import { executeOnce, withLoading } from '@datagalaxy/core-ui';
import { StringUtil } from '@datagalaxy/core-util';
import { DxyFieldTextComponent } from '@datagalaxy/ui/fields';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { AttributeMetaInfo } from '@datagalaxy/webclient/attribute/domain';

@Directive()
export abstract class AttributeTranslationBaseInput
    extends BaseComponent
    implements OnChanges
{
    @Input() attributeMetaInfo!: AttributeMetaInfo;
    @Input() entity!: EntityItem;
    @Input() mini = false;
    @Input() forceReadonly = false;

    @ViewChild('field') field?: DxyFieldTextComponent;

    protected attributeValueTranslationService: AttributeValueTranslationService;

    protected translationResult$ =
        new Observable<AttributeValueTranslationResult>();
    protected value$ = new Observable<string>();
    protected aiOrigin$ = new Observable<boolean>();

    protected touched = false;
    protected sourceValue?: string;
    protected updatedValue?: string;

    protected errorMessage = '';

    protected get labelText() {
        return (
            this.attributeMetaInfo?.translatedDisplayName ??
            this.attributeMetaInfo?.DisplayName ??
            ''
        );
    }

    protected get description() {
        return this.attributeMetaInfo?.Description ?? '';
    }

    protected get mandatory() {
        return this.attributeMetaInfo?.IsMandatory ?? false;
    }

    protected get readonly(): boolean {
        return (
            this.forceReadonly || (this.attributeMetaInfo?.IsReadOnly ?? false)
        );
    }

    protected get edited(): boolean {
        return !this.readonly && this.updatedValue !== undefined;
    }

    constructor() {
        super();
        this.attributeValueTranslationService = inject(
            AttributeValueTranslationService,
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!changes['entity']) {
            return;
        }
        this.initObservables();
    }

    public focusField() {
        this.field?.doFocus();
    }

    public onValueChange(value: string) {
        this.touched = true;
        this.updatedValue = value;
    }
    public async onFieldBlur(_$event: FocusEvent) {
        this.touched = true;
        const value = await firstValueFrom(this.value$);
        if (this.updatedValue === value || this.updatedValue === undefined) {
            this.updatedValue = undefined;
            return;
        }
        await this.updateTranslation();
    }
    public onFieldFocus(_$event: FocusEvent) {
        this.touched = true;
    }

    @executeOnce()
    @withLoading()
    protected async updateTranslation() {
        await this.attributeValueTranslationService.updateAttributeTranslation(
            this.entity,
            this.attributeMetaInfo.AttributePath ?? '',
            this.updatedValue ?? '',
            this.sourceValue,
        );
        this.updatedValue = undefined;
    }

    private initObservables() {
        this.translationResult$ =
            this.attributeValueTranslationService.getAttributeValueTranslationResultFromEntityItem$(
                this.entity,
                this.attributeMetaInfo?.AttributePath ?? '',
            );
        const attributePath = this.attributeMetaInfo?.AttributePath ?? '';
        const attributeKey = attributePath.split('.').pop() ?? '';
        this.sourceValue = this.entity.getAttributeValue<string>(attributeKey);
        this.value$ = this.translationResult$.pipe(
            map((t) =>
                StringUtil.isNullOrEmpty(t?.value as string)
                    ? (this.sourceValue ?? '')
                    : (t?.value ?? ''),
            ),
        );
        this.aiOrigin$ = this.translationResult$.pipe(
            map((r) => r.aiOrigin ?? false),
        );
    }
}
