import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    HostBinding,
    OnInit,
} from '@angular/core';
import { CoreEventsService, IListOption } from '@datagalaxy/core-ui';
import { StateName } from '@datagalaxy/webclient/routing';
import { ViewIdentifier } from '../../shared/util/ViewIdentifier';
import { NavigationService } from '../../services/navigation.service';
import { EntitySecurityService } from '../../shared/entity/services/entity-security.service';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { ImportWizardService } from '../../import/services/import-wizard.service';
import { SecurityService } from '../../services/security.service';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';
import { UserService } from '../../services/user.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityCreationOrigin } from '@datagalaxy/webclient/entity/feature';
import {
    IWorkspace,
    WorkspaceIconComponent,
    WorkspaceMenuListComponent,
} from '@datagalaxy/webclient/workspace/ui';
import {
    WorkspaceAdapterPipe,
    WorkspaceListAdapterPipe,
    WorkspaceRouterService,
} from '@datagalaxy/webclient/workspace/feature';
import { TranslateModule } from '@ngx-translate/core';
import { NavSidebarModuleComponent } from '../nav-sidebar-module/nav-sidebar-module.component';
import { DxyNavSidebarItemComponent } from '../dxy-nav-sidebar-item/dxy-nav-sidebar-item.component';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { DxyDataTestIdDirective } from '@datagalaxy/ui/testing';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { WorkspaceDetails } from '@datagalaxy/webclient/workspace/domain';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
    //eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-nav-sidebar',
    templateUrl: './dxy-nav-sidebar.component.html',
    styleUrls: ['./dxy-nav-sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatLegacyTooltipModule,
        DxyDataTestIdDirective,
        DxyLogFunctionalDirective,
        DxyNavSidebarItemComponent,
        NavSidebarModuleComponent,
        TranslateModule,
        WorkspaceIconComponent,
        WorkspaceAdapterPipe,
        WorkspaceMenuListComponent,
        WorkspaceListAdapterPipe,
    ],
})
export class DxyNavSidebarComponent extends DxyBaseComponent implements OnInit {
    private static EXPAND_STATE_KEY = 'menu-expand-state';

    @HostBinding('class.expanded') public isExpanded = false;

    @HostBinding('class.collapsed')
    public get isCollapsed() {
        return !this.isExpanded;
    }

    protected readonly StateName = StateName;
    protected readonly ViewIdentifier = ViewIdentifier;

    protected currentSpace = toSignal(this.workspaceStore.selectCurrentSpace());
    protected spaces = toSignal(this.workspaceStore.selectSpaces());
    protected creationMenuOptions = computed(() =>
        this.buildActionMenu(this.currentSpace()),
    );
    protected hasManagementAccess = computed(() =>
        this.entitySecurityService.hasManagementAccessSecurity(
            this.currentSpace().SecurityData,
        ),
    );

    protected get showCampaignModule() {
        return this.securityService.areCampaignsEnabled();
    }

    public get expandGlyphClass() {
        return 'glyph-arrow-' + (this.isExpanded ? 'left' : 'right');
    }

    public get showItemsTooltip() {
        return !this.isExpanded;
    }

    protected shouldHideWorkspaceList = false;

    constructor(
        private navigationService: NavigationService,
        private workspaceRouter: WorkspaceRouterService,
        private entitySecurityService: EntitySecurityService,
        private entityUiService: EntityUiService,
        private coreEventsService: CoreEventsService,
        private importWizardService: ImportWizardService,
        private securityService: SecurityService,
        private userService: UserService,
        private cd: ChangeDetectorRef,
        private workspaceStore: WorkspaceStore,
    ) {
        super();
    }

    ngOnInit() {
        void this.updateExpandState(true);
    }

    protected goToHomePage() {
        const spaceIdr = this.workspaceStore.currentSpace;
        return this.workspaceRouter.goToSpaceHome(spaceIdr);
    }

    protected async openImportModal() {
        await this.importWizardService.openImportModal();
    }

    protected async navigateTo(itemIdentifier: string) {
        await this.navigationService.goTo(
            itemIdentifier,
            this.workspaceStore.currentSpace,
        );
    }

    protected onNavSpaceSelected(workspace: IWorkspace) {
        this.workspaceStore.setCurrentSpaceIdentifier(workspace);

        return this.workspaceRouter.goToSpaceHome(workspace);
    }

    protected goToClientSpaceList() {
        return this.workspaceRouter.goToWorkspaceList();
    }

    protected toggleSpaceFavorite(workspace: IWorkspace) {
        this.workspaceStore.updateDefaultSpace(workspace, !workspace.favorite);
    }

    protected async updateExpandState(init = false) {
        if (init) {
            const res = await this.userService.getUserSettingValue(
                DxyNavSidebarComponent.EXPAND_STATE_KEY,
                '',
            );
            const isNewComer = res.Value === null;
            // Newcomers should have their sidebar expanded
            this.isExpanded = isNewComer
                ? true
                : coerceBooleanProperty(res.Value);
            this.updateSidebarWidth();
            this.cd.detectChanges();
        } else {
            this.isExpanded = !this.isExpanded;
            this.userService.setUserSettingValue(
                DxyNavSidebarComponent.EXPAND_STATE_KEY,
                '',
                `${this.isExpanded}`,
            );
            this.updateSidebarWidth();
        }
        this.coreEventsService.emitMainViewResize();
    }

    private updateSidebarWidth() {
        const root = (window.document as Document).documentElement;
        root.style.setProperty(
            '--nav-sidebar-width',
            `var(--nav-sidebar-width-${
                this.isExpanded ? 'expanded' : 'collapsed'
            })`,
        );
    }

    private buildActionMenu(
        workspace: WorkspaceDetails,
    ): IListOption[] | undefined {
        const options: IListOption[] = [];
        const hasImportAccess =
            this.entitySecurityService.hasImportAccessToAnyModule(workspace);
        const hasEntityCreationAccess =
            this.entitySecurityService.hasWriteAccessToAnyModule(workspace);

        if (!hasImportAccess && !hasEntityCreationAccess) {
            return;
        }

        if (hasEntityCreationAccess) {
            options.push({
                glyphClass: 'glyph-add',
                labelKey:
                    'UI.DashboardGrid.WidgetType.shortcutButtons.buttons.addEntity',
                callback: () =>
                    this.entityUiService.openCreationModal(
                        EntityCreationOrigin.globalAddButton,
                    ),
                dataTestId: 'shortcut-button--add-object',
            });
        }

        if (hasImportAccess) {
            options.push({
                glyphClass: 'glyph-upload',
                labelKey:
                    'UI.DashboardGrid.WidgetType.shortcutButtons.buttons.importEntity',
                callback: () => this.openImportModal(),
                dataTestId: 'shortcut-button--import-objects',
            });
        }

        return options;
    }
}
