<dxy-dropdown-section
    [sections]="attributeSections"
    [buttonTitle]="addBtnText | translate"
    [buttonGlyphClass]="buttonIconClass"
    [buttonVariant]="buttonVariant"
    [buttonColor]="buttonColor"
    [buttonSize]="buttonSize"
    [enableSearch]="true"
    [flattenSections]="noSections"
></dxy-dropdown-section>
