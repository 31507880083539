import { ModuleWithProviders, NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { multilingualFeatureRoutes } from './multilingual.routes';
import { MultilingualFunnelService } from './administration/funnel/multilingual-funnel.service';
import { MultilingualInitializer } from './multilingual-initializer';
import {
    EntityCreationTranslationService,
    EntityTranslationLoader,
    EntityTranslationStore,
} from './translations';

@NgModule({
    imports: [UIRouterModule.forChild({ states: multilingualFeatureRoutes })],
})
export class MultilingualFeatureModule {
    static forRoot(): ModuleWithProviders<MultilingualFeatureModule> {
        return {
            ngModule: MultilingualFeatureModule,
            providers: [
                MultilingualInitializer,
                MultilingualFunnelService,
                EntityTranslationStore,
                EntityTranslationLoader,
            ],
        };
    }
    constructor(
        _etLoader: EntityTranslationLoader,
        _ect: EntityCreationTranslationService,
        _initializer: MultilingualInitializer,
    ) {}
}
