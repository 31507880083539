import { CollectionsHelper, CoreUtil } from '@datagalaxy/core-util';
import {
    IActionOption,
    SpinnerComponent,
    withLoading,
} from '@datagalaxy/core-ui';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EntityUiService } from '../services/entity-ui.service';
import {
    BaseLinkDataInfo,
    EntityLinkTypeKind,
    EntityTypeUtil,
    HierarchicalData,
    IHasHddData,
    ObjectLinkType,
    ServerType,
} from '@datagalaxy/dg-object-model';
import { ViewTypeService } from '../../../services/viewType.service';
import { EntityService } from '../services/entity.service';
import { ToasterService } from '../../../services/toaster.service';
import { DataUtil } from '../../util/DataUtil';
import { EntityLinkService } from '../../../entity-links/entity-link.service';
import { EntityEventService } from '../services/entity-event.service';
import {
    DeleteLinkedEntitiesResult,
    InaccessibleLinkGroups,
} from '@datagalaxy/webclient/entity/data-access';
import { EntityDockingPaneService } from '../services/entity-docking-pane.service';
import { SuggestionService } from '../../../suggestions/suggestion.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    EntityItem,
    LinkedDataGroup,
} from '@datagalaxy/webclient/entity/domain';
import {
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';
import { CommonModule } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { EntityCardCellComponent } from '../../entityCard/entity-card/entity-card-cell.component';
import { DgStatusCellComponent } from '../../shared-ui/cells/dg-status-cell/dg-status-cell.component';
import { EntityLifecycleStatus } from '@datagalaxy/webclient/attribute/domain';
import { UserCollectionCellComponent } from '../../shared-ui/cells/user-collection-cell/user-collection-cell.component';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'dxy-entity-links',
    templateUrl: 'dxy-entity-links.component.html',
    styleUrls: ['dxy-entity-links.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        GridComponent,
        TranslateModule,
        SpinnerComponent,
        MatLegacyTooltipModule,
        DxyIconButtonDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxyEntityLinksComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() entityData: EntityItem;
    @Input() serverType: ServerType;

    protected linkSuggestionCount: number;
    protected resultData: IEntityLinkRow[];
    protected htmlLinksSuggestionsLabel: string;
    protected gridConfig: GridConfig<IEntityLinkRow> = {
        getItemId: (row) => row.linkedDataItemReferenceId,
    };

    protected get showPlaceholder() {
        return !this.loadingValue && this.isEmptyGrid;
    }

    protected get showGrid() {
        return !this.loadingValue && !this.isEmptyGrid;
    }

    protected get hasWriteAccess() {
        return !!this.entityData.SecurityData.HasWriteAccess;
    }

    protected get areLinksSuggestionsAvailable() {
        return (
            !this.loadingValue &&
            this.linkSuggestionCount > 0 &&
            this.hasWriteAccess
        );
    }

    protected get isEmptyGrid() {
        return !this.resultData?.length;
    }

    protected get linksCount() {
        return this.resultData?.length;
    }

    protected columns: TColDef<IEntityLinkRow>[] = [
        {
            id: 'linkType',
            hidden: true,
            type: GridCellType.text,
            rowGroup: true,
            getGroupingText: (linkType: ObjectLinkType) =>
                this.translate.instant(
                    `DgServerTypes.ObjectLinkType.${ObjectLinkType[linkType]}`,
                ),
        },
        {
            headerLabel: this.translate.instant('UI.EntityLinks.NameColumn'),
            id: 'entity',
            width: 300,
            minWidth: 200,
            sortable: true,
            resizable: true,
            customCellComponent: EntityCardCellComponent,
            getInputs: (row: IEntityLinkRow) => ({
                hideBreadcrumb: row.isRestrictedItem,
                isRestrictedItem: row.isRestrictedItem,
                breadcrumbOpenPreview: true,
                hierarchicalData: row.HddData,
                actions: this.getAvailableActions(row),
            }),
            getSortableValue: (row) => row.entity?.DisplayName,
        },
        {
            id: 'description',
            headerLabel: this.translate.instant(
                'UI.EntityLinks.DescriptionColumn',
            ),
            getValue: (row) => {
                return row.entity?.getAttributeValue<string>('Description');
            },
            type: GridCellType.text,
            width: 200,
            sortable: true,
            resizable: true,
        },
        {
            id: 'status',
            headerLabel: this.translate.instant('UI.EntityLinks.StatusColumn'),
            width: 100,
            sortable: true,
            resizable: true,
            customCellComponent: DgStatusCellComponent,
            getInputs: (row: IEntityLinkRow) => ({
                status: row.entity?.Status,
            }),
            getSortableValue: (row) => {
                const status = row.entity?.Status;
                if (!status) return '';
                return this.translate.instant(
                    `DgServerTypes.EntityLifecycleStatus.${EntityLifecycleStatus[status]}`,
                );
            },
        },
        {
            id: 'owners',
            headerLabel: this.translate.instant('UI.EntityLinks.OwnerColumn'),
            width: 50,
            sortable: false,
            resizable: true,
            customCellComponent: UserCollectionCellComponent,
            getInputs: (row: IEntityLinkRow) => ({
                userIds: row.entity?.getDataOwners(),
            }),
        },
        {
            id: 'stewards',
            headerLabel: this.translate.instant('UI.EntityLinks.StewardColumn'),
            width: 50,
            sortable: false,
            resizable: true,
            customCellComponent: UserCollectionCellComponent,
            getInputs: (row: IEntityLinkRow) => ({
                userIds: row.entity?.getDataStewards(),
            }),
        },
    ];

    private resultEntities: EntityItem[] = [];
    private entityLinksDataGroups: LinkedDataGroup[] = [];
    private inaccessibleLinkGroups: InaccessibleLinkGroups;

    constructor(
        private translate: TranslateService,
        private toasterService: ToasterService,
        private entityService: EntityService,
        private entityEventService: EntityEventService,
        private linkedObjectService: EntityLinkService,
        private viewTypeService: ViewTypeService,
        private entityUiService: EntityUiService,
        private entityDockingPaneService: EntityDockingPaneService,
        private suggestionService: SuggestionService,
        private detector: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        this.initAsync();
        this.subscribeEvents();
    }

    protected async onRowClick(row: IEntityLinkRow) {
        if (row.isRestrictedItem) {
            return;
        }
        await this.entityUiService.openPreviewOrDetailsOrDefault(row.entity);
    }

    protected async createLinkedObject() {
        await this.linkedObjectService.openLinkCreationModal(
            this.entityData,
            this.entityLinksDataGroups,
            {
                includeEntityLinks: true,
            },
        );
    }

    protected onLinksSuggestionsClick() {
        this.entityDockingPaneService.gotoMetabotPane();
    }

    private subscribeEvents() {
        super.registerSubscriptions(
            this.entityEventService.subscribeEntityLinkAdd(null, (entity) => {
                if (entity.ReferenceId === this.entityData.ReferenceId) {
                    this.initAsync();
                }
            }),
            this.entityEventService.subscribeEntityLinkDelete(
                null,
                (entity) => {
                    if (entity.ReferenceId === this.entityData.ReferenceId) {
                        this.initAsync();
                    }
                },
            ),
            this.entityEventService.subscribeEntityUpdate(null, (entity) => {
                const foundEntity = this.resultEntities.some(
                    (e) => e.DataReferenceId == entity.DataReferenceId,
                );
                if (foundEntity) {
                    this.initAsync();
                }
            }),
        );
    }

    @withLoading()
    private async initAsync() {
        const linkTypes = this.linkedObjectService.getAvailableLinkTypes(
            this.entityData,
        );
        const objectLinkTypes = linkTypes
            .filter((l) => l.isEntityLink)
            .map((l) => l.UniversalObjectLinkType);
        this.debug &&
            this.log(
                'loadLinksData-start',
                objectLinkTypes.map((olt) => ObjectLinkType[olt]),
            );
        try {
            const result = await this.entityService.getEntityLinks(
                this.entityData,
                objectLinkTypes,
                true,
            );

            this.resultEntities = result.Entities;
            this.entityLinksDataGroups = result.Groups;
            this.inaccessibleLinkGroups = result.InaccessibleLinkGroups;

            await this.loadLinksSuggestionsCount();
            this.debug &&
                this.log('loadLinksData-getEntityLinks-result', {
                    entities: this.resultEntities?.length,
                    groups: this.entityLinksDataGroups?.length,
                    links: this.linksCount,
                });
        } finally {
            this.resultData = this.buildRowGroups();
            this.addRestrictedItems(this.resultData);

            this.detector.detectChanges();
            this.log('loadLinksData-end');
        }
    }

    private async loadLinksSuggestionsCount() {
        this.linkSuggestionCount =
            await this.suggestionService.getEntityLinkSuggestionsCount(
                this.entityData.VersionId,
                this.entityData.ReferenceId,
            );
        this.htmlLinksSuggestionsLabel = this.translate.instant(
            'UI.EntityLinks.linksSuggestionsCount',
            { count: this.linkSuggestionCount },
        );
    }

    /**
     * Build IEntityLinkRow[]
     * IEntityLinkRow are sorted by serverType, then by displayName
     */
    private buildRowGroups(): IEntityLinkRow[] {
        const entityLinkRows: IEntityLinkRow[] = [];
        this.entityLinksDataGroups.forEach((dataGroup) => {
            const rows: IEntityLinkRow[] = CollectionsHelper.orderBy(
                dataGroup.Items.map((item) => {
                    const entity = this.resultEntities.find(
                        (entity) =>
                            entity.DataReferenceId == item.DataReferenceId,
                    );
                    return {
                        entity: CoreUtil.clone(entity),
                        HddData: entity.HddData,
                        linkedDataItemReferenceId: item.ReferenceId,
                        linkedDataGroup: dataGroup,
                        isRestrictedItem: false,
                        linkType: dataGroup.UniversalObjectLinkType,
                    } as IEntityLinkRow;
                }),
                [
                    (row) =>
                        DataUtil.getModuleFromServerType(
                            row.entity?.ServerType,
                        ),
                    (row) =>
                        row.entity
                            ? this.viewTypeService
                                  .getTechnicalOrDisplayName(row.entity)
                                  .toLowerCase()
                            : 'Empty',
                ],
            );
            entityLinkRows.push(...rows);
        });
        return entityLinkRows;
    }

    private addRestrictedItems(rows: IEntityLinkRow[]) {
        Object.keys(this.inaccessibleLinkGroups).forEach((groupKey) => {
            const entityLinkRows = this.inaccessibleLinkGroups[groupKey].map(
                (item) => {
                    const hdd = new HierarchicalData();
                    const mapping = EntityTypeUtil.getMapping(item.EntityType);
                    hdd.Data.DataTypeName = mapping?.DataTypeName;
                    hdd.Data.SubTypeName = mapping?.SubTypeName;
                    hdd.TechnologyCode = item.TechnologyCode;
                    hdd.Data.DataReferenceId = item.ReferenceId;
                    return {
                        entity: null,
                        HddData: hdd,
                        linkedDataItemReferenceId: item.ReferenceId,
                        linkedDataGroup: null,
                        isRestrictedItem: true,
                        linkType: ObjectLinkType[groupKey],
                    } as IEntityLinkRow;
                },
            );
            rows.push(...entityLinkRows);
        });
    }

    private getAvailableActions(row: IEntityLinkRow): any[] {
        const options: IActionOption<IEntityLinkRow>[] = [];

        if (this.hasWriteAccess) {
            options.push({
                glyphClass: 'glyph-link-broken',
                labelKey: 'UI.EntityLinks.btnDeleteTooltip',
                logFunctional: (data) =>
                    this.getDeleteFeatureCode(data?.linkedDataGroup),
                callback: () => this.deleteEntityLink(row),
            });
        }

        return [
            this.entityUiService.getEntityCellNavigationMenu(
                'ENTITY_LINKS',
                options,
            ),
        ];
    }

    private async deleteEntityLink(row: IEntityLinkRow) {
        console.log('deleteEntityLink', row);
        try {
            await this.entityService.deleteEntityLink(
                this.entityData.ReferenceId,
                row.entity.ReferenceId,
                row.entity.VersionId,
                row.linkedDataGroup.UniversalObjectLinkType,
            );
        } catch (error) {
            this.toasterService.warningToast({
                messageKey: (error as DeleteLinkedEntitiesResult)
                    .errorDetailsKey,
            });
        }
    }

    private getDeleteFeatureCode(entityLink: BaseLinkDataInfo) {
        if (!entityLink) {
            return;
        }
        const type = entityLink.EntityLinkTypeKind
            ? EntityLinkTypeKind[entityLink.EntityLinkTypeKind]
            : entityLink.EntityLinkType;
        return `ENTITY_LINK_${type.toUpperCase()},D`;
    }
}

interface IEntityLinkRow extends IHasHddData {
    linkedDataGroup: LinkedDataGroup;
    linkedDataItemReferenceId: string;
    entity: EntityItem;
    isRestrictedItem: boolean;
    linkType: ObjectLinkType;
}
