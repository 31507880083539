<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" [translate]="modalTitleKey"></h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<form (ngSubmit)="onCloseSubmit()">
    <div mat-dialog-content>
        <div *ngIf="!isUpdateUser">
            <dxy-field-email
                name="email"
                labelKey="AdminUI.User.userInfoModal.lblEmail"
                [(ngModel)]="email"
                [debounceTimeMs]="500"
                (debouncedModelChange)="onChangeEmail()"
                (blur)="onChangeEmail()"
                [errorMessageKey]="emailErrorKey"
                required
            ></dxy-field-email>
            <div *ngIf="!isAddExistingUser">
                <dxy-field-text
                    name="firstName"
                    labelKey="AdminUI.User.userInfoModal.lblFirstName"
                    [(ngModel)]="firstName"
                    (ngModelChange)="onChangeFirstName()"
                    (blur)="onChangeFirstName()"
                    [errorMessageKey]="firstNameErrorKey"
                    required
                ></dxy-field-text>
                <dxy-field-text
                    name="lastName"
                    labelKey="AdminUI.User.userInfoModal.lblLastName"
                    [(ngModel)]="lastName"
                    (ngModelChange)="onChangeLastName()"
                    (blur)="onChangeLastName()"
                    [errorMessageKey]="lastNameErrorKey"
                    required
                ></dxy-field-text>
                <dxy-field-text
                    *ngIf="!isTrialInvite"
                    name="title"
                    labelKey="AdminUI.User.userInfoModal.lblTitle"
                    [(ngModel)]="title"
                ></dxy-field-text>
                <dxy-field-text
                    *ngIf="!isTrialInvite"
                    name="service"
                    labelKey="AdminUI.User.userInfoModal.lblService"
                    [(ngModel)]="service"
                ></dxy-field-text>
                <dxy-field-text
                    *ngIf="!isTrialInvite"
                    name="role"
                    labelKey="AdminUI.User.userInfoModal.lblRole"
                    [(ngModel)]="role"
                ></dxy-field-text>
            </div>
        </div>
        <div>
            <label translate
                >AdminUI.User.userInfoModal.lblAvailableLicenses</label
            >
            <div>
                <div>
                    <span translate>UI.LicenseLevel.Steward</span> :
                    {{ availableStewardsCount }}
                </div>
                <div *ngIf="hasExplorerLicensesAvailable">
                    <span translate>UI.LicenseLevel.Explorer</span> :
                    {{ availableExplorerCount }}
                </div>
                <div>
                    <span translate>UI.LicenseLevel.Reader</span> :
                    {{ availableDataReaderCount }}
                </div>
            </div>
        </div>
        <dxy-field-select
            name="license"
            labelKey="AdminUI.User.userInfoModal.lblAvailableLicenses"
            [adapter]="licenseTypes"
            [required]="isUpdateUser"
            (openClose)="onPanelOpenClose($event)"
        ></dxy-field-select>
    </div>
    <div class="modal-footer dxy-actions">
        <button
            type="button"
            name="cancel"
            dxyButton
            variant="secondary"
            (click)="onCloseCancel()"
        >
            <span translate>UI.Global.btnCancel</span>
        </button>
        <button
            type="submit"
            name="submit"
            dxyButton
            [dxyLogFunctional]="featureCode"
            [disabled]="!canSubmitCreation"
        >
            <span [translate]="actionBtnLblKey"></span>
        </button>
    </div>
</form>
