import { EntityLinkService } from '../../entity-links/entity-link.service';
import {
    IEntityPanelData,
    IEntityToolState,
} from '../../shared/entity/interfaces/entity-panel.interface';
import { CoreUtil, DomUtil } from '@datagalaxy/core-util';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { EntityPanelTool } from '../entity-panels.types';
import { EntityType, EntityTypeUtil } from '@datagalaxy/dg-object-model';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { CommentaryService } from '../../commentary/commentary.service';
import { TaskService } from '../../tasks/task.service';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { NavigationService } from '../../services/navigation.service';
import { DataQualityService } from '../../data-quality/data-quality.service';
import { getSpaceIdFromEntity } from '@datagalaxy/webclient/utils';
import { EntitySuggestionStoreService } from '../../suggestions/entity-suggestion-store.service';
import { EntityService } from '../../shared/entity/services/entity.service';
import { SecurityService } from '../../services/security.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { IDragDropConfig } from '@datagalaxy/core-ui';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { EntityFunctionalLogService } from '@datagalaxy/webclient/entity/data-access';
import { DxyActivityLogContainerComponent } from '../../activityLog/dxy-activity-log-container/dxy-activity-log-container.component';
import { CampaignContainerComponent } from '../entity-panel-containers/campaign-container/campaign-container.component';
import { DxyVersioningComparatorSingleObjectComponent } from '../../versioning/dxy-versioning-comparator-single-object/dxy-versioning-comparator-single-object.component';
import { SuggestionContainerComponent } from '../../suggestions/suggestion-container/suggestion-container.component';
import { DxyCommentaryContainerComponent } from '../../commentary/dxy-commentary-container/dxy-commentary-container.component';
import { DxyTaskContainerComponent } from '../../tasks/dxy-task-container/dxy-task-container.component';
import { EntityAssetsComponent } from '../../shared/entity/entity-assets/entity-assets.component';
import { DiagramsListComponent } from '../../diagrams/diagrams-list/diagrams-list.component';
import { EntityLinkedObjectsComponent } from '../../shared/entity/entity-linked-objects/entity-linked-objects.component';
import { PrimaryKeyPreviewPaneComponent } from '../../modeler/pkfk/primary-key-preview-pane/primary-key-preview-pane.component';
import { EntityTableColumnsComponent } from '../../shared/entity/entity-table-columns/entity-table-columns.component';
import { DxyEntityTreeComponent } from '../../shared/entity/dxy-entity-tree/dxy-entity-tree.component';
import { DataQualityContainerComponent } from '../../data-quality/data-quality-container/data-quality-container.component';
import { DxyInsightDataComponent } from '../../insights/dxy-insight-data/dxy-insight-data.component';
import { DxyEntityDetailsComponent } from '../../shared/entity/dxy-entity-details/dxy-entity-details.component';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import PropertyName = ServerConstants.PropertyName;
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * Body of the entity docking panel and the entity preview panel
 */
@Component({
    selector: 'dxy-entity-panel-body',
    templateUrl: 'dxy-entity-panel-body.component.html',
    styleUrls: ['dxy-entity-panel-body.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        DxyLogFunctionalDirective,
        DxyIconButtonDirective,
        MatLegacyTooltipModule,
        NgClass,
        TranslateModule,
        SpinnerComponent,
        DxyEntityDetailsComponent,
        DxyInsightDataComponent,
        DataQualityContainerComponent,
        DxyEntityTreeComponent,
        EntityTableColumnsComponent,
        PrimaryKeyPreviewPaneComponent,
        EntityLinkedObjectsComponent,
        DiagramsListComponent,
        EntityAssetsComponent,
        DxyTaskContainerComponent,
        DxyCommentaryContainerComponent,
        SuggestionContainerComponent,
        DxyVersioningComparatorSingleObjectComponent,
        CampaignContainerComponent,
        DxyActivityLogContainerComponent,
        AsyncPipe,
    ],
})
export class DxyEntityPanelBodyComponent
    extends DxyBaseComponent
    implements OnChanges, OnInit, OnDestroy
{
    @Input() data: IEntityPanelData;
    @Input() activeState: IEntityToolState;
    /** Prevent the use of entity-table-columns instead of entity-tree when entity is a Table */
    @Input() noTableColumns: boolean;
    /** Prevent display of the PrimaryKey tool when entity is a Table */
    @Input() noPrimaryKey: boolean;
    @Input() collapsed: boolean;
    @Input() hidden: boolean;
    @Input() dragDropConfig: IDragDropConfig;

    @Output() toolClick = new EventEmitter<EntityPanelTool>();
    @Output() toggleClick = new EventEmitter<void>();

    public readonly Tool = EntityPanelTool;
    public panes: IPane[];
    public loading = new BehaviorSubject(false);
    public diagramsExcludedDefaultColumns = [PropertyName.Description];
    public get readOnly() {
        return !!this.data?.readOnly;
    }
    public get noNavLink() {
        return this.data?.noNavLink;
    }
    public get entityData() {
        return this.data?.entityData as EntityItem;
    }
    public get hasEntityData() {
        return !!this.entityData;
    }
    public get hasWriteAccess() {
        return this.entityData?.SecurityData?.HasWriteAccess;
    }
    public get spaceId() {
        return getSpaceIdFromEntity(this.entityData);
    }
    public get versionId() {
        return this.entityData?.VersionId;
    }

    public get showToggleButton() {
        return this.data?.showToggleButton;
    }
    public get attributesMetaData() {
        return this.entityMoreData?.attributesMetaData;
    }
    public get activityLogDataOptions() {
        return this.entityMoreData?.activityLogDataOptions;
    }
    public get versioningComparatorToolParam() {
        return this.entityMoreData?.versioningComparatorToolParam;
    }
    public get showEmptyData() {
        return !this.hasEntityData && !this.loading.value;
    }
    public get serverType() {
        return this.entityData.ServerType;
    }
    public get headerName() {
        return `UI.EntityDockingPane.Tool.${EntityPanelTool[this.activeTool]}`;
    }
    public get useTableColumns() {
        return (
            !this.noTableColumns &&
            this.entityData?.entityType == EntityType.Table &&
            !this.isGlobalSearchOrTasks
        );
    }
    public get usePrimaryKey() {
        return (
            !this.noPrimaryKey &&
            this.entityData?.entityType == EntityType.Table &&
            !this.isGlobalSearchOrTasks
        );
    }
    public get useDataQuality() {
        return this.dataQualityService.isDataQualityEnabled(
            this.entityData?.entityType,
        );
    }
    public get areCampaignsEnabled() {
        return this.securityService.areCampaignsEnabled();
    }

    private activeTool: EntityPanelTool;
    private isKeyShortcutActionActive: boolean;
    private subcriptionEntityUpdate: Subscription;
    private get entityMoreData() {
        return this.data?.entityMoreData;
    }

    private get isGlobalSearchOrTasks() {
        // for DG-5490
        return (
            this.navigationService.isInSearchResultsView ||
            this.navigationService.isInClientTaskView
        );
    }

    constructor(
        private ngZone: NgZone,
        private dxyModalService: DxyModalService,
        private commentaryService: CommentaryService,
        private taskService: TaskService,
        private linkedObjectService: EntityLinkService,
        private entityService: EntityService,
        private entityEventService: EntityEventService,
        private navigationService: NavigationService,
        private dataQualityService: DataQualityService,
        private entitySuggestionStore: EntitySuggestionStoreService,
        private securityService: SecurityService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'data', () => this.init());
        super.onChange(changes, 'activeState', () => this.applyActiveState());
    }
    ngOnInit() {
        this.init();
        super.registerForDestroy(
            DomUtil.addListener(
                document,
                'keypress',
                (e: KeyboardEvent) => this.keyDownEntityPanelEvent(e),
                this.ngZone,
                true,
            ),
        );
        super.subscribe(
            this.entitySuggestionStore.selectSuggestionGroups(),
            () => this.buildPanes(),
        );
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.data) {
            this.data.entityData = this.data.entityMoreData = null;
        }
    }

    public isActiveTool(tool: EntityPanelTool) {
        return (
            tool != undefined && tool === this.activeTool && !this.loading.value
        );
    }
    public isReadOnlyTool(tool: EntityPanelTool) {
        return this.readOnly || this.data.readOnlyTools?.includes(tool);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public isNoNavLinkTool(tool: EntityPanelTool) {
        return this.noNavLink;
    }

    //#region events
    public onTogglePaneClick() {
        this.toggleClick.emit();
    }
    public onPaneIconClick(pane: IPane) {
        this.log('onPaneIconClick', pane);
        if (!pane) {
            return;
        }
        this.toolClick.emit(pane.tool);
    }
    //#endregion

    private init() {
        const data = this.data;
        this.log('init', data);
        if (!data) {
            return;
        }

        this.collapsed ??= this.data.isCollapsedOnInit;

        super.unsubscribe(this.subcriptionEntityUpdate);
        if (this.entityData?.ServerType != undefined) {
            this.subcriptionEntityUpdate = super.registerSubscription(
                this.entityEventService.subscribeEntityUpdate(
                    this.entityData.ServerType,
                    (entity) => {
                        if (
                            this.entityData &&
                            entity.ReferenceId === this.entityData.ReferenceId
                        ) {
                            this.buildPanes();
                        }
                    },
                ),
            );
        }

        this.buildPanes();
        this.applyActiveState();
    }

    private buildPanes() {
        this.panes =
            (!this.hidden &&
                this.data?.orderedTools
                    .filter(
                        (tool) =>
                            (this.hasWriteAccess ||
                                tool != EntityPanelTool.Suggestion) &&
                            (this.usePrimaryKey ||
                                tool != EntityPanelTool.PrimaryKey) &&
                            (this.useDataQuality ||
                                tool != EntityPanelTool.DataQuality) &&
                            (this.areCampaignsEnabled ||
                                tool != EntityPanelTool.Campaign),
                    )
                    .map(
                        (tool) =>
                            ({
                                tool,
                                dtName: this.getDtName(tool),
                                funcLogCode: this.getFuncLogCode(tool),
                                isActive:
                                    !this.collapsed && tool === this.activeTool,
                                tooltipTranslateKey: this.getToolTooltip(tool),
                                glyphClass: this.getGlyphClass(tool),
                                hasPill: this.hasPill(tool),
                                pillText: this.getPillText(tool),
                                isBeta: false,
                            }) as IPane,
                    )) ||
            [];
    }

    private applyActiveState() {
        if (this.activeState?.activeTool == undefined) {
            return;
        }
        this.activeTool = this.activeState.activeTool;
        this.panes?.forEach((p) => (p.isActive = p.tool === this.activeTool));
        if (!this.collapsed) {
            this.loadEntityAttributes();
        }
    }

    private getToolTooltip(tool: EntityPanelTool) {
        switch (tool) {
            case EntityPanelTool.Suggestion:
                return 'UI.EntityDockingPane.Tool.Tooltip.Suggestion';

            default:
                return 'UI.EntityDockingPane.Tool.' + EntityPanelTool[tool];
        }
    }

    private hasPill(tool: EntityPanelTool) {
        return (
            tool == EntityPanelTool.Suggestion &&
            !!this.entitySuggestionStore.suggestionsCount
        );
    }

    private getPillText(tool: EntityPanelTool) {
        switch (tool) {
            case EntityPanelTool.Suggestion:
                return this.entitySuggestionStore.suggestionsCount?.toString();
            default:
                return;
        }
    }
    private getDtName(tool: EntityPanelTool) {
        const entityToolName = (() => {
            switch (tool) {
                case EntityPanelTool.Details:
                    return 'Details';
                case EntityPanelTool.EntityTree:
                    return 'Hierarchy';
                case EntityPanelTool.Insights:
                    return 'Insights';
                case EntityPanelTool.LinkedObject:
                    return 'Linked Objects';
                case EntityPanelTool.Diagrams:
                    return 'Diagrams';
                case EntityPanelTool.Assets:
                    return 'Assets';
                case EntityPanelTool.Tasks:
                    return 'Tasks';
                case EntityPanelTool.Commentaries:
                    return 'Comments';
                case EntityPanelTool.Suggestion:
                    return 'Suggestions';
                case EntityPanelTool.VersionComparison:
                    return 'Version Compare';
                case EntityPanelTool.ActivityLog:
                    return 'Activity Log';
                case EntityPanelTool.PrimaryKey:
                    return 'Primary Key';
            }
        })();
        const entityFuncLogCode =
            this.entityData &&
            EntityFunctionalLogService.getEntityItemActionFeatureCode(
                this.entityData,
            );
        return `Entity Docking Pane - ${entityToolName} - ${entityFuncLogCode}`;
    }
    private getFuncLogCode(tool: EntityPanelTool) {
        switch (tool) {
            case EntityPanelTool.Tasks:
                return 'SOCIAL_TASK,R';
            case EntityPanelTool.Commentaries:
                return 'SOCIAL_COMMENT,R';
            case EntityPanelTool.EntityTree:
                return 'SOCIAL_TREE,R';
            case EntityPanelTool.LinkedObject:
                return 'SOCIAL_LINKED_OBJECT,R';
            case EntityPanelTool.Diagrams:
                return 'SOCIAL_DIAGRAMS,R';
            case EntityPanelTool.Assets:
                return 'ASSETS,R';
            case EntityPanelTool.Insights:
                return 'SOCIAL_INSIGHTS,R';
            case EntityPanelTool.DataQuality:
                return 'DATA_QUALITY,R';
            case EntityPanelTool.Suggestion:
                return 'SOCIAL_SUGGESTION,R';
            case EntityPanelTool.VersionComparison:
                return 'SOCIAL_COMPARE_VERSION,R';
            case EntityPanelTool.ActivityLog:
                return 'SOCIAL_LOG_ACTIVITY,R';
            case EntityPanelTool.PrimaryKey:
                return 'SOCIAL_PRIMARY_KEY,R';
            case EntityPanelTool.Campaign:
                return 'CAMPAIGN,R';
        }
    }
    private getGlyphClass(tool: EntityPanelTool) {
        switch (tool) {
            case EntityPanelTool.Details:
                return 'glyph-info';
            case EntityPanelTool.EntityTree:
                return 'glyph-hierarchy';
            case EntityPanelTool.Insights:
                return 'glyph-insight';
            case EntityPanelTool.DataQuality:
                return 'glyph-quality';
            case EntityPanelTool.LinkedObject:
                return 'glyph-diagram';
            case EntityPanelTool.Diagrams:
                return 'glyph-diagram1';
            case EntityPanelTool.Assets:
                return 'glyph-view-list1';
            case EntityPanelTool.Tasks:
                return 'glyph-file-tasks-check';
            case EntityPanelTool.Commentaries:
                return 'glyph-chat-comment';
            case EntityPanelTool.Suggestion:
                return 'glyph-robot2';
            case EntityPanelTool.VersionComparison:
                return 'glyph-compare-item';
            case EntityPanelTool.ActivityLog:
                return 'glyph-activity-log';
            case EntityPanelTool.PrimaryKey:
                return 'glyph-pk';
            case EntityPanelTool.Campaign:
                return 'glyph-campaign';
        }
    }

    private keyDownEntityPanelEvent(event: KeyboardEvent) {
        if (
            this.isKeyShortcutActionActive ||
            !this.entityData ||
            !this.isShortcutEventValid(event) ||
            this.dxyModalService.isActiveModal ||
            !this.data?.isKeyboardEventEnabled
        ) {
            return;
        }

        switch (event.key.toLowerCase()) {
            case 't':
                this.log(
                    'keyDownEntityPanelEvent',
                    't',
                    this.entityData?.DisplayName,
                );
                this.isKeyShortcutActionActive = true;
                this.taskService
                    .openTaskFormModal(this.entityData as EntityItem)
                    .finally(() => {
                        this.isKeyShortcutActionActive = false;
                    });
                return;

            case 'c':
                this.log(
                    'keyDownEntityPanelEvent',
                    'c',
                    this.entityData?.DisplayName,
                );
                this.isKeyShortcutActionActive = true;
                this.commentaryService
                    .openCommentaryNewModal({
                        entityData: this.entityData,
                    })
                    .finally(() => {
                        this.isKeyShortcutActionActive = false;
                    });
                return;

            case 'l': {
                this.log(
                    'keyDownEntityPanelEvent',
                    'l',
                    this.entityData?.DisplayName,
                );
                this.isKeyShortcutActionActive = true;
                const entityItem = this.entityData as EntityItem;
                if (EntityTypeUtil.canHaveLinks(entityItem?.EntityType)) {
                    this.linkedObjectService
                        .openLinkCreationModal(entityItem, undefined, {
                            includeEntityLinks: true,
                        })
                        .finally(() => {
                            this.isKeyShortcutActionActive = false;
                        });
                }
                return;
            }
        }
    }

    private isShortcutEventValid(event: KeyboardEvent) {
        const eventTarget = event.target as HTMLElement;
        return (
            !eventTarget.isContentEditable &&
            ['input', 'textarea'].indexOf(eventTarget.localName) == -1
        );
    }

    private async loadEntityAttributes(): Promise<void> {
        const currentEntity = this.entityData as EntityItem;
        const attributesFilter = this.getToolAttributesFilters(this.activeTool);

        if (attributesFilter.length) {
            this.loading.next(true);
            const entity = await this.entityService.getEntityForDetails(
                currentEntity,
                attributesFilter,
                false,
            );
            CoreUtil.mergeWith(
                currentEntity.Attributes,
                entity.Attributes,
                (_objValue, srcValue) => srcValue,
            );
            this.loading.next(false);
        }
    }

    private getToolAttributesFilters(tool: EntityPanelTool) {
        switch (tool) {
            case EntityPanelTool.Insights:
                return [
                    PropertyName.LogicalAllLevelChildrenCount,
                    PropertyName.EntityLinkCount,
                    PropertyName.DataFillPercentage,
                    PropertyName.RecommendedDataFillPercentage,
                    PropertyName.CreationTime,
                    PropertyName.CreationUserId,
                    PropertyName.LastModificationTime,
                    PropertyName.LastModificationUserId,
                ];
            case EntityPanelTool.EntityTree:
                return [
                    PropertyName.LogicalParentId,
                    PropertyName.LogicalChildrenCount,
                ];
            default:
                return [];
        }
    }
}

interface IPane {
    tool: EntityPanelTool;
    isActive: boolean;
    tooltipTranslateKey: string;
    glyphClass: string;
    hasPill: boolean;
    pillText: string;
    isBeta: boolean;
    dtName: string;
    funcLogCode: string;
}
