import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { fadeInFadeOutAnimation } from '@datagalaxy/ui/animation';
import { SuggestionPanelUiService } from '../suggestion-panel-ui.service';
import { SecurityService } from '../../services/security.service';
import { SecurityRoleConstant } from '@datagalaxy/webclient/security/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { SuggestionPanelComponent } from '../suggestion-panel/suggestion-panel.component';
import { DxyPanelHostComponent } from '@datagalaxy/core-ui';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { AsyncPipe, NgIf } from '@angular/common';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * Button to toggle meta bot or toggle suggestion panel
 *
 * ## Features
 * - For steward and admins:
 *   - pulse animation if suggestions are available
 *   - Toggle suggestion panel
 * - For other users:
 *   - Toggle metabot
 */
@Component({
    selector: 'app-suggestion-trigger',
    templateUrl: './suggestion-trigger.component.html',
    styleUrls: ['./suggestion-trigger.component.scss'],
    animations: [fadeInFadeOutAnimation()],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatLegacyTooltipModule,
        DxyPanelHostComponent,
        SuggestionPanelComponent,
        AsyncPipe,
        TranslateModule,
        DxyIconButtonDirective,
    ],
})
export class SuggestionTriggerComponent
    extends DxyBaseComponent
    implements OnInit
{
    public opened$ = this.suggestionPanelUiService.panelOpened$;
    public notify$ = this.suggestionPanelUiService.notifyNewSuggestions$;

    public get settings$() {
        return this.suggestionPanelUiService.settings$;
    }
    public get hasWriteAccess() {
        return (
            this.securityService.isSteward() ||
            this.securityService.hasLevelAccess(
                SecurityRoleConstant.AdministrativeAdministrator,
            ) ||
            this.securityService.hasLevelAccess(
                SecurityRoleConstant.TechnicalAdministrator,
            )
        );
    }

    constructor(
        private suggestionPanelUiService: SuggestionPanelUiService,
        private securityService: SecurityService,
    ) {
        super();
    }
    ngOnInit() {
        this.init();
    }

    public async toggleSuggestions() {
        await this.suggestionPanelUiService.toggleSuggestions();
    }

    public toggleSuggestionPanel() {
        this.suggestionPanelUiService.toggleSuggestionPanel();
    }

    private init() {
        if (!this.hasWriteAccess) {
            return;
        }
        this.suggestionPanelUiService.initNotifyNewSuggestions();
    }
}
