import { Injectable } from '@angular/core';
import { BackendApiService, BaseServiceResult } from '@datagalaxy/data-access';
import {
    CreateDashboardParameter,
    DashboardListResult,
    DashboardResult,
    DeleteDashboardParameter,
    GetDashboardParameter,
    GetDashboardsParameter,
    UpdateDashboardParameter,
} from './dashboard';
import {
    GetStatisticsDataParameter,
    GetWidgetDatasetParameter,
    GetWidgetDatasetResult,
} from './widget';
import { ObservableInput } from 'rxjs';
import { INewable } from 'cerialize';

@Injectable({ providedIn: 'root' })
export class DashboardApiService {
    constructor(private backendApiService: BackendApiService) {}

    public async getUserDashboards(param: GetDashboardsParameter) {
        return await this.backendApiService.postPromise(
            'Dashboard/GetUserDashboards',
            param,
            DashboardListResult,
        );
    }

    public async getDashboard(param: GetDashboardParameter) {
        return await this.backendApiService.postPromise(
            'Dashboard/GetDashboard',
            param,
            DashboardResult,
        );
    }

    public async createDashboard(param: CreateDashboardParameter) {
        return await this.backendApiService.postPromise(
            'Dashboard/CreateDashboard',
            param,
            DashboardResult,
        );
    }

    public async updateDashboard(param: UpdateDashboardParameter) {
        return await this.backendApiService.postPromise(
            'Dashboard/UpdateDashboard',
            param,
            DashboardResult,
        );
    }

    public async deleteDashboard(param: DeleteDashboardParameter) {
        return await this.backendApiService.postPromise(
            'Dashboard/DeleteDashboard',
            param,
            DashboardListResult,
        );
    }

    public async getWidgetDataset(param: GetWidgetDatasetParameter) {
        return await this.backendApiService.postPromise(
            'Insight/GetWidgetDataset',
            param,
            GetWidgetDatasetResult,
        );
    }

    public async getStatisticsData<T extends BaseServiceResult>(
        param: GetStatisticsDataParameter,
        type: INewable<T>,
        cancelNotifier: ObservableInput<any>,
    ): Promise<T> {
        return await this.backendApiService.postPromise(
            'Analytic/GetStatisticsData',
            param,
            type,
            { cancelNotifier },
        );
    }
}
