import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseService } from '@datagalaxy/core-ui';
import { NavigationService } from './navigation.service';
import { ViewType } from '../shared/util/app-types/ViewType';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';

@Injectable({ providedIn: 'root' })
export class AppEventsService extends BaseService {
    //#region ViewType

    /** fired on technical/functional view change */
    public get viewTypeChange$() {
        return this.viewTypeChange.asObservable();
    }
    private viewTypeChange = new Subject<ViewType>();

    /** to be called on technical/functional view change */
    public emitViewTypeChange(viewType: ViewType) {
        this.log('emitViewTypeChange', viewType);
        this.viewTypeChange.next(viewType);
    }

    //#endregion

    //#region route state
    public get stateChanged$() {
        return this.stateChanged.asObservable();
    }
    private stateChanged = new Subject<string>();
    private currentModule: DgModule;
    public notifyStateChanged(stateName: string) {
        this.log('notifyStateChanged', stateName);
        this.stateChanged.next(stateName);

        const module = NavigationService.getModuleFromStateName(stateName);
        if (module != this.currentModule) {
            this.currentModule = module;
            this.notifyModuleChanged(module);
        }
    }
    //#endregion

    //#region current DgModule
    public get moduleChanged$() {
        return this.moduleChanged.asObservable();
    }
    private moduleChanged = new Subject<DgModule>();
    public notifyModuleChanged(newModule: DgModule) {
        this.log('notifyModuleChanged', DgModule[newModule]);
        this.moduleChanged.next(newModule);
    }
    //#endregion

    public notifyImpactAnalysisClearCacheNeeded() {
        this.impactAnalysisClearCacheNeeded.next();
    }
    public get impactAnalysisClearCacheNeeded$() {
        return this.impactAnalysisClearCacheNeeded.asObservable();
    }
    private impactAnalysisClearCacheNeeded = new Subject<void>();

    //#region Import

    /** fired on import successfully completed from home shortcuts */
    public get importDone$() {
        return this.importDone.asObservable();
    }
    private importDone = new Subject<void>();

    /** to be called on import from home shortcuts */
    public notifyImportDone() {
        this.importDone.next();
    }

    //#endregion

    //#region top spotlight input visibility
    public get topSpotlightShouldBeHidden$() {
        return this.topSpotlightShouldBeHidden.asObservable();
    }
    private topSpotlightShouldBeHidden = new Subject<boolean>();
    public emitTopSpotlightShouldBeHidden(hidden: boolean) {
        this.topSpotlightShouldBeHidden.next(hidden);
    }
    //#endregion
}
