import { Injectable, NgZone } from '@angular/core';
import { CookieNavNewTab, IDgNavNewTabParams } from './cookie-nav-new-tab';
import { CookieService } from 'ngx-cookie-service';
import { IHierarchicalData } from '@datagalaxy/dg-object-model';
import {
    NavigateTo,
    NavigationService,
} from '../../services/navigation.service';
import { DomUtil, THTMLElement } from '@datagalaxy/core-util';
import { environment } from '../../../environments/environment';

/***
 * @deprecated no cookie is needed with authV2
 */
@Injectable({ providedIn: 'root' })
export class NavNewTabService {
    constructor(
        private cookieService: CookieService,
        private navigationService: NavigationService,
    ) {}

    /***
     * @deprecated no cookie is needed with authV2
     */
    public setCookieNavNewTab(url: string, keepHash = false) {
        const dc = CookieNavNewTab;
        if (url && !keepHash && url.startsWith('#')) {
            url = url.slice(1);
        }
        this.cookieService.set(dc.CookieKey, dc.makeCookieValue(url), {
            expires: dc.makeCookieExpires(),
            secure: true,
        });
    }

    /***
     * @deprecated no cookie is needed with authV2
     */
    public clearCookieNavNewTab() {
        this.cookieService.delete(CookieNavNewTab.CookieKey);
    }

    /***
     * @deprecated no cookie is needed with authV2
     */
    public getUrlInCookieNavNewTab(noDecode = false) {
        const dc = CookieNavNewTab;
        const cookieString = this.cookieService.get(dc.CookieKey);
        let url = dc.extractUrlFromCookie(cookieString);
        if (!noDecode) {
            url = decodeURIComponent(url);
        }
        return url;
    }

    /**
     * @deprecated replace with a link, no need to generate a temp cookie
     * Adds the right-click/open in new tab option to the specified element
     * */
    public setupRightClickOpenInNewTab(
        element: THTMLElement,
        info: IDgNavNewTabParams,
        ngZone?: NgZone,
    ) {
        const el = DomUtil.getElement(element);

        if (!el || !info || !info.hierarchicalData) {
            return;
        }

        const navTo =
            info.navTo ||
            this.navigationService.getNavTo(info.navToLineage, info.navToTab);

        //wraps the element or its content into a A tag
        const aElement = this.wrapElement(el, info.wrapContent);

        //on the right-click event, sets the href link and the cookie allowing to pass through the login
        const rightClickListenerRemover = DomUtil.addListener(
            aElement,
            'contextmenu',
            () =>
                this.setAHref(
                    aElement,
                    info.hierarchicalData,
                    navTo,
                    true,
                ).then(),
            ngZone,
            true,
        );

        //prevents the left-click from using the href link, which is there only for right-click/open in new tab
        const clickListenerRemover = DomUtil.addListener(
            aElement,
            'click',
            (e) => e.preventDefault(),
            ngZone,
            true,
        );

        return {
            element: aElement,
            rightClickListenerRemover,
            clickListenerRemover,
        };
    }

    private async setAHref(
        aElement: HTMLElement,
        hData: IHierarchicalData,
        navTo?: NavigateTo,
        setCookie = false,
    ) {
        const url =
            await this.navigationService.getUrlToGoToWithHierarchicalData(
                hData,
                navTo,
            );
        if (!url) return false;

        aElement.setAttribute('href', url);

        if (setCookie) {
            this.setCookieNavNewTab(url);
        }

        return true;
    }

    private wrapElement(el: HTMLElement, wrapContent = false) {
        if (!el) return;

        const className = 'navto-href';

        if (el.tagName == 'A') {
            //no need to wrap
            el.classList.add(className);
            return el;
        }

        let a = wrapContent ? (el.firstChild as HTMLElement) : el.parentElement;
        if (a && a.tagName == 'A' && a.classList.contains(className)) {
            //already wrapped
            return a;
        }

        a = document.createElement('a');
        //so we'll know it has been wrapped
        a.classList.add(className);
        //since that was not a link, we dont want it to look as such
        a.style.textDecoration = 'none';
        a.style.cursor = 'default';

        if (wrapContent) {
            //wrap element's content into a A tag
            while (el.firstChild) a.appendChild(el.firstChild);
            el.appendChild(a);
        } else {
            //wrap element into a A tag
            if (!el.parentElement) {
                if (!environment.production) {
                    console.warn(
                        'element has no parent. ensure it has one (or has been added to the dom)',
                    );
                }
                return;
            }
            el.parentElement.insertBefore(a, el);
            a.appendChild(el);
        }

        return a;
    }
}
