import { Injectable } from '@angular/core';
import {
    OidcSecurityService,
    OpenIdConfiguration,
} from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';
import { clientId } from './default-open-id-config';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    public authenticated$ = this.oidcSecurityService.isAuthenticated$;

    private _authenticated = false;

    public get authenticated() {
        return this._authenticated;
    }

    constructor(private oidcSecurityService: OidcSecurityService) {
        this.authenticated$.subscribe((event) => {
            this._authenticated = event.isAuthenticated;
        });
    }

    public async authorize() {
        const url = window.location;
        const relativeUrl = url.pathname + url.search + url.hash;
        await firstValueFrom(this.oidcSecurityService.setState(relativeUrl));
        this.oidcSecurityService.authorize();
    }

    /**
     * Logout and redirect to the identity provider login page
     */
    public logout() {
        this.oidcSecurityService.revokeAccessToken().subscribe(() => {
            this.oidcSecurityService.logoff().subscribe();
        });
    }

    /**
     * Logout without redirecting to the identity provider login page
     */
    public async logoutServer() {
        await firstValueFrom(this.oidcSecurityService.revokeAccessToken());
        await firstValueFrom(this.oidcSecurityService.revokeRefreshToken());
    }

    /**
     * Logout without revoking any token and without redirecting to the identity provider
     */
    public logoutLocal() {
        this.oidcSecurityService.logoffLocal();
    }

    /**
     * Try to get an access token
     * @returns the final redirect url
     */
    public async initToken(): Promise<string> {
        let relativeCallbackUrl = '';
        try {
            relativeCallbackUrl = await firstValueFrom(
                this.oidcSecurityService.getState(),
            );
            // ensure we ignore "code" or "state" query params from non oidc callback urls
            const checkAuthUrl = location.search.includes('callbackIdentifier')
                ? location.origin
                : location.href;
            const response = await firstValueFrom(
                this.oidcSecurityService.checkAuth(checkAuthUrl),
            );
            if (!response.isAuthenticated) {
                this.authorize();
                return '';
            }
        } catch (error) {
            this.authorize();
            throw {
                tokenError: true,
                error,
            };
        }
        const accessToken = await this.getAccessTokenFromStorage();
        if (!accessToken) {
            this.authorize();
        }
        return relativeCallbackUrl;
    }

    public async resetPassword() {
        const config = await firstValueFrom(
            this.oidcSecurityService.getConfiguration(),
        );
        this.oidcSecurityService.setState('/');
        if (!config) {
            return;
        }
        location.href = this.buildUpdatePasswordUrl(config);
    }

    /**
     * Get the access token from the storage if the user is authenticated
     */
    public getAccessTokenFromStorage() {
        /**
         * 'angular-auth-oidc-client' doesn't allow us to get the JWT token in a synchronous way
         * So we have to use the async way to get the token, which will run a request to the server
         * to get the oidc configuration first if the user is not already authenticated
         *
         * To avoid this oidc config call we ensure that the user is already authenticated before
         * getting the JWT token
         */
        if (!this.authenticated) {
            return;
        }
        return firstValueFrom(this.oidcSecurityService.getAccessToken());
    }

    private buildUpdatePasswordUrl(config: OpenIdConfiguration) {
        const url = new URL(`${config.authority}/protocol/openid-connect/auth`);
        url.searchParams.append('client_id', clientId);
        url.searchParams.append('redirect_uri', window.location.origin);
        url.searchParams.append('response_type', 'code');
        url.searchParams.append('scope', 'openid');
        url.searchParams.append('kc_action', 'UPDATE_PASSWORD');
        return url.toString();
    }
}
