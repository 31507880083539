import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { ClientErrorService } from '../../services/clientError.service';
import { CoreUtil } from '@datagalaxy/core-util';
import { LoggingHttpService } from '../backend-http-interceptors/logging-http.service';
import {
    ApiServiceError,
    ApiServiceErrorType,
    ProblemDetails,
} from '@datagalaxy/data-access';
import { AuthenticationService } from '@datagalaxy/webclient/auth/feature';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(
        private clientErrorService: ClientErrorService,
        private loggingHttpService: LoggingHttpService,
        @Inject(Injector) private readonly injector: Injector,
    ) {}

    handleError(error: any): void {
        /**
         * When an error is thrown inside a promise it will be caught by
         * ZoneAwarePromise and wrapped into an uncaught error
         */
        if (error.zoneAwareStack && error.rejection) {
            error = error.rejection;
        }

        if (!CoreUtil.isProduction) {
            console.warn('handleError', !!this.clientErrorService, error);
        }

        if (error instanceof ApiServiceError) {
            this.autoLoginOnUnauthorizedError(error);
            this.loggingHttpService.notifyGenericError(error);
        }

        this.handleProblemDetails(error);

        this.clientErrorService?.notify(error, null, 'generic');
    }

    private autoLoginOnUnauthorizedError(error: ApiServiceError<any, any>) {
        // Can't inject directly because of circular dependencies for ApplicationRef
        const featureFlagService = this.injector.get(FeatureFlagService);
        const authenticationService = this.injector.get(AuthenticationService);
        if (
            featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2') &&
            error.type == ApiServiceErrorType.Unauthorized
        ) {
            authenticationService.authorize();
        }
    }

    private handleProblemDetails(error: any) {
        const rejection = error?.rejection ?? error;
        if (ProblemDetails.isProblemDetails(rejection)) {
            const problemDetails = ProblemDetails.fromJson(rejection);
            this.loggingHttpService.notifyProblemDetails(problemDetails);
        }
    }
}
