import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Inject,
} from '@angular/core';
import { withLoading } from '@datagalaxy/core-ui';
import { MultilingualApiService } from '@datagalaxy/webclient/multilingual/data-access';
import { BaseComponent } from '@datagalaxy/utils';
import { MultilingualStateService } from '../../multilingual-state.service';
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    map,
    Observable,
} from 'rxjs';
import { getLanguageName } from '@datagalaxy/webclient/multilingual/ui';
import { DIALOG_SERVICE_TOKEN, IDialogService } from '@datagalaxy/ui/dialog';
import { AddLanguageModalComponent } from '../add-language-modal/add-language-modal.component';
import { TranslationProgressComponent } from '../../translations/translation-progress/translation-progress.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageCardComponent } from '../../languages';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { SearchInputComponent } from '@datagalaxy/ui/search';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'dxy-multilingual-languages',
    templateUrl: './multilingual-languages.component.html',
    styleUrls: ['./multilingual-languages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        SpinnerComponent,
        SearchInputComponent,
        DxyIconButtonDirective,
        MatLegacyTooltipModule,
        NgFor,
        LanguageCardComponent,
        TranslateModule,
        TranslationProgressComponent,
        AsyncPipe,
    ],
})
export class MultilingualLanguagesComponent
    extends BaseComponent
    implements AfterViewInit
{
    protected searchTerm$ = new BehaviorSubject<string>('');

    protected sourceLanguageCode$ =
        this.multilingualStateService.selectSourceLanguageCode();

    protected languageCodes$?: Observable<string[]>;

    constructor(
        private multilingualApiService: MultilingualApiService,
        private multilingualStateService: MultilingualStateService,
        @Inject(DIALOG_SERVICE_TOKEN) private dialogService: IDialogService,
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.loadLanguages();
        this.initLanguageCodes();
    }

    protected onSearchStringChange(searchString: string | undefined) {
        this.searchTerm$.next(searchString ?? '');
    }

    protected async openAddLanguageModal() {
        await this.dialogService.open<AddLanguageModalComponent, void, void>({
            componentType: AddLanguageModalComponent,
        });
    }

    protected trackByLanguageCode(_index: number, languageCode: string) {
        return languageCode;
    }

    @withLoading()
    private async loadLanguages() {
        const languageSettings =
            await this.multilingualApiService.getTargetLanguages();
        this.multilingualStateService.updateLanguageSettings(languageSettings);
    }

    private initLanguageCodes() {
        this.languageCodes$ = combineLatest([
            this.multilingualStateService.selectAllLanguageCodes(),
            this.searchTerm$,
        ]).pipe(
            map(([languageCodes, searchTerm]) => {
                return languageCodes.filter((code) => {
                    const name = getLanguageName(code);
                    return name
                        ?.toLowerCase()
                        .includes(searchTerm?.toLowerCase());
                });
            }),
            distinctUntilChanged(),
        );
    }
}
