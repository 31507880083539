import { AbstractControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export function passwordMatchValidator(
    passwordControlName: string,
    passwordConfirmControlName: string,
): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
        const passwordControl: AbstractControl =
            formGroup?.controls[passwordControlName];
        const passwordConfirmControl: AbstractControl =
            formGroup?.controls[passwordConfirmControlName];
        return passwordControl?.value == passwordConfirmControl?.value
            ? null
            : { passwordMismatch: true };
    };
}
