import { EntityPanelTool } from '../../entity-panels/entity-panels.types';
import { NavigateTo } from '../../services/navigation.service';
import { IHierarchicalData } from '@datagalaxy/dg-object-model';
import * as moment from 'moment';

export interface IDgNavNewTabParams {
    hierarchicalData?: IHierarchicalData;
    navToLineage?: boolean;
    navToTab?: string;
    dockingPanelTool?: EntityPanelTool;
    navTo?: NavigateTo;
    wrapContent?: boolean;
    isFromBreadcrumb?: boolean;
}

// TODO (fbo) manage multiple urls, and extend expires on each url added
/**
 * @deprecated cookies will be removed with AuthV2
 */
export class CookieNavNewTab {
    public static readonly CookieKey = 'DG_COOKIE_NAVNEWTAB';
    /** number of seconds during which the user is automatically authorized,
     *  thus allowing to skip the login page on the new opened tab */
    public static readonly authorizedDelaySeconds = 10;

    public static makeCookieValue(url: string) {
        return JSON.stringify(new CookieNavNewTab(url));
    }

    public static makeCookieExpires() {
        return moment()
            .add(CookieNavNewTab.authorizedDelaySeconds, 'second')
            .toDate();
    }

    public static extractUrlFromCookie(cookieString: string) {
        try {
            const cookie: CookieNavNewTab =
                cookieString && JSON.parse(cookieString);
            return cookie?.url || '';
        } catch (e) {
            return '';
        }
    }

    constructor(public url: string) {}
}
