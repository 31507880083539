import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {
    NavigateTo,
    NavigationService,
} from '../../../../services/navigation.service';
import { IHierarchicalData } from '@datagalaxy/dg-object-model';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { EntityTypeUtils } from '@datagalaxy/webclient/entity/utils';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { NgClass, NgIf } from '@angular/common';
import { EllipsisTooltipDirective } from '@datagalaxy/ui/tooltip';
import { NavNewTabService } from '../../../../navigation/nav-new-tab/nav-new-tab.service';

/**
 * ## Role
 * Display entity in a small card with a link to navigate to entity
 */
@Component({
    standalone: true,
    selector: 'app-dg-entity-link-shortcut-cell',
    templateUrl: 'dg-entity-link-shortcut-cell.component.html',
    styleUrls: ['dg-entity-link-shortcut-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, EllipsisTooltipDirective, NgIf],
})
export class DgEntityLinkShortcutCellComponent
    extends DxyBaseComponent
    implements OnChanges
{
    @Input() hdd: IHierarchicalData;

    public glyphClass?: string;
    public href?: string;

    constructor(
        private featureFlagService: FeatureFlagService,
        private navigationService: NavigationService,
        private navNewTabService: NavNewTabService,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.onChange(changes, 'hdd', () => this.initLink(), true);
    }

    public onClick(e: MouseEvent) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            e.preventDefault();
            e.stopPropagation();
            this.navigationService.goToWithHierarchicalData(this.hdd, {
                destination: NavigateTo.Object,
            });
            return;
        }
        this.navNewTabService.setCookieNavNewTab(this.href);
    }

    private initLink() {
        if (!this.hdd) {
            return;
        }
        this.glyphClass = EntityTypeUtils.getColoredGlyphClass(
            this.hdd.EntityType,
        );
        this.setHref();
    }

    private async setHref() {
        this.href =
            await this.navigationService.getUrlToGoToWithHierarchicalData(
                this.hdd,
                NavigateTo.Object,
            );
    }
}
