<div class="spotlight-container-header">
    <div *ngIf="isFilterDropdownVisible" class="filter-dropdown">
        <ng-container *ngFor="let filterCategory of filterCategories">
            <dxy-spotlight-result-section
                *ngIf="filterCategory.items.length"
                [section]="filterCategory"
                [spaceIdr]="spaceIdr"
                (onSelectFilterItem)="onSelectFilterItem.emit($event)"
            ></dxy-spotlight-result-section>
        </ng-container>
    </div>
    <div class="space-version-selector-wrapper">
        <dxy-space-version-selector
            *ngIf="showSpaceVersionSelector"
            (onSpaceVersionSelected)="onSpaceVersionSelected($event)"
            [spaceIdr]="spaceIdr"
            [allowAll]="true"
            logId="spotlight-result-container"
        ></dxy-space-version-selector>
        <button
            dxyIconButton
            variant="tertiary"
            class="search-settings-link"
            [matTooltip]="'UI.NavBar.redirectOnSearchPreferences' | translate"
            (click)="goToSearchSettings()"
        >
            <i class="glyph-settings"></i>
        </button>
    </div>
    <div *ngIf="hasQuickFilters" class="quick-filters-wrapper">
        <app-attribute-quick-filters
            [currentSearch]="currentSearch"
            [quickFilters]="quickFilters"
            [isLoading]="loading"
            (onSelectFilter)="selectQuickFilterEvent($event)"
            dataTrackerId="Searchbar-facettes-lookingfor"
        ></app-attribute-quick-filters>
    </div>
</div>

<div class="spotlight-container-body">
    <dxy-spinner *ngIf="loading" class="xl search-loading"></dxy-spinner>

    <div
        *ngIf="showSearchResult"
        class="search-results"
        cdkDropList
        [cdkDropListConnectedTo]="spotlightDragDrop?.dropTo"
        [cdkDropListDisabled]="!spotlightDragDrop || spotlightDragDrop.disabled"
        cdkDropListSortingDisabled
        [cdkDropListData]="spotlightDragDrop"
    >
        <dxy-spotlight-result-section
            *ngFor="let section of resultCategories"
            [section]="section"
            [spaceIdr]="spaceIdr"
            [initialRowsCount]="section.initialRowsCount"
            (onSelectFilterItem)="onSelectFilterItem.emit($event)"
            (onClickFilteredSearch)="onClickFilteredSearch.emit($event)"
            (onClickResultItem)="onClickResultItem.emit()"
            (onClearLastSearches)="onClearLastSearches.emit()"
            (onShowAll)="onSectionShowMore(section)"
        ></dxy-spotlight-result-section>
    </div>

    <div *ngIf="showEmptySearchResult" class="empty-search-result">
        <img src="/images/placeholders/search/empty-search.svg" />
        <div class="empty-search-result-text" translate>
            UI.Search.noResults
        </div>
    </div>

    <div *ngIf="showEmptyLastViewedEntities" class="empty-last-viewed-entities">
        <span class="section-title" translate
            >UI.Spotlight.section.lastViewed</span
        >
        <img
            src="/images/placeholders/search/last-objects.svg"
            class="empty-section-img"
            alt="no-result-img"
        />
        <span class="empty-section-lbl" translate
            >UI.Spotlight.section.lastViewedEmpty</span
        >
    </div>
</div>
