import { BaseService } from '@datagalaxy/core-ui';
import { Injectable } from '@angular/core';
import { BaseServiceParameter } from '@datagalaxy/data-access';
import {
    UserAdminData,
    BaseUserListParameter,
    UserApiService,
} from '@datagalaxy/webclient/user/data-access';
import { QuickFiltersApiService } from '@datagalaxy/webclient/filter/data-access';
import { ChangePasswordParameter } from '@datagalaxy/webclient/auth/data-access';
import { UpdateClientQuickFiltersParameter } from '@datagalaxy/webclient/filter/data-access';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';
import { Filter } from '@datagalaxy/webclient/filter/domain';

@Injectable({ providedIn: 'root' })
export class ClientAdminService extends BaseService {
    constructor(
        private userApiService: UserApiService,
        private filterApiService: QuickFiltersApiService,
    ) {
        super();
    }

    public changeUserPassword(cupp: ChangePasswordParameter) {
        return this.userApiService.changeUserPassword(cupp);
    }

    public async loadSystemUsers(): Promise<UserAdminData[]> {
        const res = await this.loadUserData();

        return res?.filter((user) => user.IsToken);
    }

    public async loadUserData(
        includeDeletedUsers?: boolean,
    ): Promise<UserAdminData[]> {
        const param = new BaseUserListParameter();
        const res = await this.userApiService.loadUserList(param);

        return res?.Users.filter(
            (u) => !u.IsUserDeleted || includeDeletedUsers,
        );
    }

    public async loadUser(userId: string): Promise<UserAdminData> {
        const param = new BaseUserListParameter();
        param.UserIds = [userId];
        const res = await this.userApiService.loadUserList(param);

        return res?.Users?.[0];
    }

    public async getQuickFiltersSettings() {
        const params = new BaseServiceParameter();
        const res = await this.filterApiService.getClientQuickFilters(params);
        return res?.QuickFilters;
    }

    public async updateQuickFiltersSettings(
        filters: Filter[],
        license: LicenseLevel,
    ) {
        const params = new UpdateClientQuickFiltersParameter();
        params.QuickFilters = filters;
        params.ProfileName = LicenseLevel[license];
        const res =
            await this.filterApiService.updateClientQuickFilters(params);
        return res?.QuickFilters;
    }

    public async resetQuickFiltersSettings(license: LicenseLevel) {
        const params = new UpdateClientQuickFiltersParameter();
        params.SetDefaultValue = true;
        params.ProfileName = LicenseLevel[license];
        const res =
            await this.filterApiService.updateClientQuickFilters(params);
        return res?.QuickFilters;
    }
}
