import { Inject, Injectable, NgZone } from '@angular/core';
import * as moment from 'moment/moment';
import { DomUtil } from '@datagalaxy/core-util';
import { DOCUMENT } from '@angular/common';
import { Subject } from 'rxjs';
import { AuthenticationService } from '@datagalaxy/webclient/auth/feature';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';

@Injectable({ providedIn: 'root' })
export class CurrentUserTimeoutService {
    private readonly userSessionTimeout = new Subject<boolean>();
    private lastUserActionTime = Date.now();

    public get userSessionTimeout$() {
        return this.userSessionTimeout.asObservable();
    }

    private get userSessionTimeoutMinutes() {
        return this.currentUser.clientInfo?.UserSessionTimeoutMinutes;
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private ngZone: NgZone,
        private currentUser: CurrentUserService,
        private authService: AuthenticationService,
    ) {}

    public startSessionTimeoutDetection() {
        const repeat = () => setTimeout(check, 1000 * 60, this.ngZone, true);
        const check = () => {
            if (this.authService.authenticated) {
                return;
            }

            const timeoutMinutes = this.userSessionTimeoutMinutes;
            const idleMinutes = moment().diff(
                this.lastUserActionTime,
                'minute',
            );
            const isTimeout = !!timeoutMinutes && idleMinutes >= timeoutMinutes;
            this.userSessionTimeout.next(isTimeout);

            repeat();
        };

        repeat();

        const action = () => (this.lastUserActionTime = Date.now());

        ['mousemove', 'mousedown', 'keyup'].forEach((name: string) => {
            DomUtil.addListener(
                this.document,
                name as keyof HTMLElementEventMap,
                action,
                this.ngZone,
                true,
            );
        });
    }
}
