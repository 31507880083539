<ng-container *ngFor="let module of accessibleModules$ | async">
    <dxy-nav-sidebar-item
        [glyphClass]="module.glyphClass"
        [showTooltip]="showItemsTooltip"
        [dtName]="module.name"
        [itemIdentifier]="getViewIdentifier(module)"
        [stateId]="getStateName(module.name)"
        (click)="navigateTo(module)"
    >
    </dxy-nav-sidebar-item>
</ng-container>
