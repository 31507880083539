import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { SuggestionPanelUiService } from '../suggestion-panel-ui.service';
import { AttributeFilterService } from '../../shared/attribute/attribute-filter/attribute-filter.service';
import { AttributeFilterAction } from '../../shared/attribute/attribute-filter/attribute-filter.types';
import { IFilterFormModel } from '../../shared/attribute/attribute-filter/attribute-filter-form/IFilterFormModel';
import { AttributeFilterModel } from '../../shared/attribute/attribute-filter/attribute-filter/attributeFilterModel';
import { AttributeDataService } from '../../shared/attribute/attribute-data.service';
import { CoreUtil } from '@datagalaxy/core-util';
import { SuggestionType } from '@datagalaxy/webclient/suggestion/types';
import {
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import {
    AttributeMetaInfo,
    AttributeMetaType,
} from '@datagalaxy/webclient/attribute/domain';
import { DgZone } from '@datagalaxy/webclient/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { AttributeFilterComponent } from '../../shared/attribute/attribute-filter/attribute-filter/attribute-filter.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { AttributeFilterAddDropdownComponent } from '../../shared/attribute/attribute-filter/attribute-filter-add-dropdown/attribute-filter-add-dropdown.component';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';

/**
 * ## Role
 * Attribute filter selected by a dropdown
 */
@Component({
    selector: 'app-suggestion-attribute-filter-manager',
    templateUrl: './suggestion-attribute-filter-manager.component.html',
    styleUrls: ['./suggestion-attribute-filter-manager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AttributeFilterAddDropdownComponent,
        NgIf,
        forwardRef(() => AttributeFilterComponent),
        AsyncPipe,
    ],
})
export class SuggestionAttributeFilterManagerComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() suggestionType: SuggestionType;

    public attributes: AttributeMetaInfo[];
    public filter: AttributeFilterModel;
    public filterForm: SuggestionsAttributeFilterFormModel;

    public get loading$() {
        return this.suggestionPanelUiService.loading$;
    }

    constructor(
        private attributeDataService: AttributeDataService,
        private workspaceStore: WorkspaceStore,
        private suggestionPanelUiService: SuggestionPanelUiService,
        private functionalLogService: FunctionalLogService,
    ) {
        super();
    }

    ngOnInit() {
        this.init();
    }
    ngOnChanges(changes: SimpleChanges) {
        super.onChange(changes, 'suggestionType', () => this.init());
    }

    public initFilter(ami: AttributeMetaInfo) {
        const cleanAmi = this.removeUndefinedDcpValueFromAmi(ami);
        this.filter = AttributeFilterService.newFilterItemFromAttribute(
            this.filterForm,
            cleanAmi,
            false,
            [],
        );
    }
    /**
     * Remove "Undefined" value from Dcp filter: DG-5177
     */
    private removeUndefinedDcpValueFromAmi(ami: AttributeMetaInfo) {
        if (
            ami.AttributePath != ServerConstants.PropertyName.PersonalDataClass
        ) {
            return ami;
        }
        const cleanAmi = new AttributeMetaInfo();
        CoreUtil.assign(cleanAmi, ami);
        cleanAmi.ListValues = cleanAmi.ListValues.filter(
            (v) => v.Value != 'Undefined',
        );
        return cleanAmi;
    }

    public onFilterAction(action: AttributeFilterAction) {
        if (action == AttributeFilterAction.itemRemoved) {
            this.filter = undefined;
            this.suggestionPanelUiService.resetAttributeFilter();
            return;
        }

        this.functionalLogService.logFunctionalAction(
            `SUGGESTIONS_CENTER_SELECT_SUGGESTED_${SuggestionType[
                this.suggestionType
            ].toUpperCase()}`,
            CrudOperation.R,
        );

        this.suggestionPanelUiService.filterByAttributeType(
            this.filter.attributePath,
            this.filter.getValuesAsArray(),
        );
    }

    private async init() {
        this.filter = null;
        const filterPredicate = this.getFilterPredicate();
        if (!filterPredicate) {
            return;
        }
        this.attributes = (
            await this.attributeDataService.getAttributesForFiltering()
        ).filter(filterPredicate);
        this.filterForm = new SuggestionsAttributeFilterFormModel(
            this.workspaceStore.currentSpace,
            this.attributes,
        );

        if (this.attributes.length == 1) {
            this.initFilter(this.attributes[0]);
            this.filter.isFormOpen = false;
            this.filter.isDeletableItem = false;
            return;
        }
    }

    private getFilterPredicate(): (a: AttributeMetaInfo) => boolean {
        switch (this.suggestionType) {
            case SuggestionType.Tag:
                return (a) =>
                    [
                        AttributeMetaType.ManagedTag,
                        AttributeMetaType.ClientTag,
                    ].includes(a.AttributeType);
            case SuggestionType.Dcp:
                return (a) =>
                    a.AttributeKey ==
                    ServerConstants.PropertyName.PersonalDataClass;
        }
    }
}

class SuggestionsAttributeFilterFormModel implements IFilterFormModel {
    public dgZone: DgZone = DgZone.none;
    public dgModule?: DgModule;
    public filterItems: AttributeFilterModel[] = [];
    public displayedFilters$: Observable<AttributeFilterModel[]>;
    public filterItemsChanged$: Observable<void>;

    constructor(
        public spaceIdr: IWorkspaceIdentifier,
        public sourceAttributes: AttributeMetaInfo[],
    ) {}
}
