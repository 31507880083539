<header *ngIf="settings$ | async as settings">
    <div class="header-top">
        <div class="space-selector">
            <dxy-space-version-selector
                *ngIf="!isGlossaryTab"
                class="no-shadow no-padding"
                [hideOfficialVersion]="true"
                [allowAll]="true"
                [spaceIdr]="spaceIdr"
                logId="search-filters-list"
                (onSpaceVersionSelected)="onSpaceSelected($event)"
            ></dxy-space-version-selector>
        </div>
        <div *ngIf="settings$ | async as settings" class="metabot-toggle">
            <label translate> UI.Global.silent </label>
            <mat-slide-toggle
                color="primary"
                [checked]="settings.disabled"
                (toggleChange)="onToggleChange()"
            >
            </mat-slide-toggle>
        </div>
    </div>
    <dxy-tabs-header
        [tabs]="tabsData"
        [disabled]="loading$ | async"
        (tabChange)="onTabChange($event)"
    ></dxy-tabs-header>
</header>
<ng-container *ngIf="isSuggestionTab">
    <h2 translate>UI.Suggestions.Panel.title</h2>
    <div class="filters">
        <app-suggestion-attribute-filter-manager
            [suggestionType]="currentSuggestionType"
        ></app-suggestion-attribute-filter-manager>
    </div>
    <app-suggestion-panel-grid></app-suggestion-panel-grid>
</ng-container>

<ng-container *ngIf="isGlossaryTab">
    <app-suggestion-panel-glossary-import></app-suggestion-panel-glossary-import>
</ng-container>
<ng-container *ngIf="isLinkTab">
    <app-suggestion-panel-links
        [spaceIdr]="spaceIdr"
    ></app-suggestion-panel-links>
</ng-container>
