import {
    ParamTypeDefinition,
    RejectType,
    Transition,
    UIRouter,
} from '@uirouter/core';
import { ErrorHandler, Injector, Type } from '@angular/core';
import { Constants } from './shared/util/Constants';
import { UiSpinnerService } from '@datagalaxy/core-ui';
import { ServerType } from '@datagalaxy/dg-object-model';
import { CoreUtil, StringUtil } from '@datagalaxy/core-util';
import { NavigationService } from './services/navigation.service';
import { isStateByName, StateName } from '@datagalaxy/webclient/routing';
import { MonitoringService } from './services/monitoring/monitoring.service';
import { AppEventsService } from './services/AppEvents.service';
import { AnalyticService } from './services/analytic.service';
import {
    ErrorLogin,
    ErrorLoginType,
} from './shared/util/app-types/errors.types';
import { LegacyAuthenticationService } from './services/authentication.service';
import { getBootLogger } from '../boot';
import { environment } from '../environments/environment';
import { Visualizer } from '@uirouter/visualizer';
import { ApiServiceError, ApiServiceErrorType } from '@datagalaxy/data-access';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import {
    clientLazyLoadedLegacy,
    mainPrivateIndexRouteLegacy,
} from './app.routes';
import { ClientService } from './client/client.service';

const logBoot = getBootLogger('app-router.config.ts');
logBoot('IN');

const urlConfigTypes: { type: string; typeDefinition: ParamTypeDefinition }[] =
    [
        {
            type: 'serverType',
            typeDefinition: {
                encode: (serverType: ServerType) =>
                    StringUtil.toKebabCase(ServerType[serverType]).slice(1),
                decode: (slug: string) =>
                    ServerType[
                        StringUtil.capitalize(StringUtil.toCamelCase(slug))
                    ],
                is: (serverType: ServerType) =>
                    Object.values(ServerType).includes(serverType),
                equals: (serverType1: ServerType, serverType2: ServerType) =>
                    serverType1 === serverType2,
            },
        },
    ];

export function appRouterConfig(
    uiRouter: UIRouter,
    injector: Injector,
    visualizer?: boolean,
) {
    if (!environment.production && visualizer) {
        uiRouter.plugin(Visualizer);
    }

    logBoot('indexRouterConfig-in');
    const getLocationPath = () => uiRouter.locationService.path();

    if (getLocationPath() == `/${Constants.Nav.UrlWord.isUp}`) {
        return;
    }

    const log = (...args: any[]) => logBoot('indexRouterConfig', ...args);
    const { urlService, transitionService, stateService } = uiRouter;
    const services = new Map<any, any>();
    const getService = <T>(serviceType: Type<T>) => {
        if (services.has(serviceType)) {
            return services.get(serviceType) as T;
        }
        const svc = injector.get(serviceType, null);
        if (svc) {
            services.set(serviceType, svc);
        } else {
            log('getService-not-found', serviceType.name);
        }
        return svc;
    };

    const featureFlagService = getService(FeatureFlagService);

    if (!featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
        uiRouter.stateRegistry.register(mainPrivateIndexRouteLegacy);
        uiRouter.stateRegistry.register(clientLazyLoadedLegacy);
    }

    urlConfigTypes.forEach((configType) =>
        urlService.config.type(configType.type, configType.typeDefinition),
    );

    //#region Events of some transitions

    const setMemoState = (transition: Transition) => {
        getService(NavigationService).setMemoState(transition, false);
    };

    // Handle client admin routing events
    transitionService.onStart(
        {
            exiting: (state) => isStateByName(state, StateName.Space),
            entering: (state) =>
                isStateByName(
                    state,
                    StateName.ClientAdmin,
                    StateName.UserProfile,
                    StateName.UserSettings,
                ),
        },
        (transition) => {
            setMemoState(transition);
        },
    );

    // when exiting from search to enter in profile/administration
    transitionService.onStart(
        {
            exiting: (state) =>
                isStateByName(state, StateName.ClientSearchResults),
            entering: (state) =>
                isStateByName(
                    state,
                    StateName.ClientAdminDetails,
                    StateName.User,
                ),
        },
        (transition) => {
            setMemoState(transition);
        },
    );

    // Handle transition to main private index state
    transitionService.onSuccess(
        { to: StateName.MainPrivateIndex },
        async () => {
            await getService(NavigationService).gotoMemoStateOrClientMain();
        },
    );

    // Handle transition to Client (when no memo state)
    transitionService.onSuccess({ to: StateName.Client }, async () => {
        await getService(ClientService).goToLandingPage();
    });

    //#endregion specific transitions

    //#region Events of all transitions

    transitionService.onBefore({}, (transition) =>
        traceTransition('onBefore', transition),
    );

    transitionService.onStart({}, (transition) => {
        traceTransition('onStart', transition);
        getService(UiSpinnerService).setActiveSpinner();
        getService(MonitoringService).onStateTransitionStart(
            transition.targetState().name(),
            transition.targetState().params(),
        );
    });

    transitionService.onSuccess({}, (transition) => {
        traceTransition('onSuccess', transition);
        const targetStateName = transition.targetState()?.name();
        getService(AppEventsService).notifyStateChanged(targetStateName);
        getService(UiSpinnerService).stopSpinner();
        getService(MonitoringService).onStateTransitionSuccess(
            targetStateName,
            getLocationPath(),
        );
        getService(AnalyticService).page().then();
    });

    transitionService.onError({}, async (transition) => {
        const transitionError = transition.error();
        traceTransition('onError', transition, transitionError);

        if (
            transitionError.type === RejectType.SUPERSEDED &&
            transitionError.redirected
        ) {
            return;
        }

        getService(UiSpinnerService).stopSpinner();

        const targetStateName = transition.targetState()?.name();

        getService(MonitoringService).onStateTransitionError(
            transitionError as any as Error,
            targetStateName,
            transitionError.message,
        );

        const error = transitionError.detail;

        const navigationService = getService(NavigationService);

        // Handle Login error
        if (error instanceof ErrorLogin) {
            if (featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
                return;
            }
            if (error.loginErrorMessage) {
                const authenticationService = getService(
                    LegacyAuthenticationService,
                );
                if (authenticationService.IsUsingExternaLogin) {
                    await navigationService.goToMainError(
                        error.loginErrorMessage,
                    );
                }
            }

            if (error.type === ErrorLoginType.notAuthenticated) {
                if (targetStateName !== StateName.MainPrivateIndex) {
                    navigationService.setMemoState(transition, true);
                }
                await navigationService.goToMainLogin();
            } else if (error.type === ErrorLoginType.loginFailed) {
                await navigationService.goToMainLogin();
            }
        }

        if (error instanceof ApiServiceError) {
            switch (error.type) {
                case ApiServiceErrorType.ObjectNotFound:
                    await navigationService.goToObjectNotFound();
                    break;
            }
        }
    });

    //#endregion all transitions

    const globalDefaultHandler = stateService.defaultErrorHandler();
    stateService.defaultErrorHandler((error) => {
        // Ignore Login Error: not an error
        if (
            error.detail instanceof ErrorLogin ||
            error.type === RejectType.SUPERSEDED
        ) {
            return;
        }

        if (error.detail instanceof ApiServiceError) {
            injector.get(ErrorHandler).handleError(error.detail);
        }

        globalDefaultHandler(error);
    });

    logBoot('indexRouterConfig-out');
}

function traceTransition(
    logId: string,
    transition: Transition,
    ...args: any[]
) {
    if (!CoreUtil.isDebuggingBoot()) {
        return;
    }
    logBoot(
        'traceTransition',
        logId,
        `${transition.$from()?.name || '(n/a)'} -> ${
            transition.$to()?.name || '(n/a)'
        }`,
        ...args,
    );
}
