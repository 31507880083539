import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { BaseCellComponent } from '@datagalaxy/core-ui/cell-components';
import { IActionOption } from '@datagalaxy/core-ui';
import { CoreUtil } from '@datagalaxy/core-util';
import { IFilteredEntityCardCellParams } from './filtered-entity-card-cell.types';
import { IEntityCardCellInputs } from '../entity-card/entity-card-cell.types';
import { HierarchicalData } from '@datagalaxy/dg-object-model';
import { EntityCardCellComponent } from '../entity-card/entity-card-cell.component';
import { IHasHddData, ServerType } from '@datagalaxy/dg-object-model';
import {
    AttributeDataService,
    IAttributeSearchMatch,
} from '../../attribute/attribute-data.service';
import { FilteredEntityItem } from '@datagalaxy/webclient/search/data-access';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

/**
 * ## Role
 * Entity card with an exact match filtered attribute if exists
 */
@Component({
    selector: 'app-filtered-entity-card-cell',
    templateUrl: 'filtered-entity-card-cell.component.html',
    styleUrls: ['filtered-entity-card-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, EntityCardCellComponent, TranslateModule],
})
export class FilteredEntityCardCellComponent
    extends BaseCellComponent<
        IHasHddData,
        HierarchicalData,
        IFilteredEntityCardCellParams
    >
    implements IEntityCardCellInputs, OnChanges
{
    @Input() filteredEntityItem?: FilteredEntityItem;

    @Input() noLabelNavLink = false;
    @Input() noBreadcrumbNavLink = false;
    @Input() hideBreadcrumb = false;
    @Input() isFromHierarchical = false;
    @Input() forceIncludeSpace?: boolean;
    @Input() forceExcludeSpace?: boolean;
    @Input() breadcrumbOpenPreview?: boolean;
    @Input() dtContext?: string;
    @Input() actions?: IActionOption<IHasHddData>[];

    @ViewChild(EntityCardCellComponent) entityCard: EntityCardCellComponent;

    protected exactMatch: IAttributeSearchMatch;
    protected get hierarchicalData() {
        return this.filteredEntityItem?.HddData;
    }
    protected get attributes() {
        return this.filteredEntityItem?.Attributes;
    }

    constructor(private attributeDataService: AttributeDataService) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        super.onChange(
            changes,
            'filteredEntityItem',
            () => this.initAsync(),
            true,
        );
    }

    public override updateLayout() {
        this.entityCard?.updateLayout();
    }

    public refreshLayout() {
        this.entityCard?.refreshLayout();
    }

    protected override updateBindings() {
        this.filteredEntityItem =
            this.value instanceof FilteredEntityItem
                ? this.value
                : this.data instanceof FilteredEntityItem
                  ? this.data
                  : undefined;
        this.actions = this.params.actions;
        CoreUtil.assign(this, this.params.inputs);
        this.initAsync();
    }

    private async initAsync() {
        if (!this.filteredEntityItem) {
            return;
        }
        await this.setExactMatches();
    }

    private async setExactMatches() {
        const entity = this.filteredEntityItem;
        // this is to avoid the "attributeDataService.loadAttributes must be called before use" error,
        // at least when entity is a model Diagram
        await this.attributeDataService.loadAttributes([
            ServerType[entity.ServerType],
        ]);

        const exactMatchesAttributes =
            this.attributeDataService.extractExactMatchesAttributes(entity);
        this.exactMatch = this.attributeDataService.getFirstOrNullExactMatch(
            exactMatchesAttributes,
            entity,
        );
        this.cd.detectChanges();
    }
}
