import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ServerType } from '@datagalaxy/dg-object-model';
import { NavigationService } from '../../services/navigation.service';
import { DataUtil } from '../../shared/util/DataUtil';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { TranslateModule } from '@ngx-translate/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { NgIf } from '@angular/common';
import { WatchEntityButtonComponent } from '../../shared/entity/watch-entity-button/watch-entity-button.component';
import { DxyEntityHeaderComponent } from '../../shared/entity/dxy-entity-header/dxy-entity-header.component';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

@Component({
    selector: 'dxy-entity-panel-header',
    templateUrl: 'dxy-entity-panel-header.component.html',
    styleUrls: ['dxy-entity-panel-header.component.scss'],
    standalone: true,
    imports: [
        DxyEntityHeaderComponent,
        WatchEntityButtonComponent,
        NgIf,
        MatLegacyTooltipModule,
        TranslateModule,
        DxyIconButtonDirective,
    ],
})
export class DxyEntityPanelHeaderComponent extends DxyBaseComponent {
    @Input() entityData: EntityItem;
    @Input() readOnly?: boolean;
    @Input() noNavLink?: boolean;
    @Input() closeDtName: string;
    @Output() readonly onCloseClick = new EventEmitter<void>();

    public get entityItem() {
        return this.entityData as EntityItem;
    }

    public get isDiagram() {
        return this.entityItem.ServerType == ServerType.Diagram;
    }

    constructor(private navigationService: NavigationService) {
        super();
    }

    public goToEntity() {
        const dgModule = DataUtil.getModuleFromServerType(
            this.entityData.ServerType,
        );
        this.navigationService.goToEntityDetails(dgModule, this.entityData);
    }

    public goToDiagram() {
        this.navigationService.goToWithHierarchicalData(
            this.entityItem.HddData,
        );
    }
}
