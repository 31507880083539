import { Injectable } from '@angular/core';
import { AnalyticService } from '../../services/analytic.service';
import { ViewTypeService } from '../../services/viewType.service';
import { ViewType } from '../../shared/util/app-types/ViewType';
import {
    IUserFirstAccessData,
    IUserSegmentationData,
} from './first-access.types';
import { UserService } from '../../services/user.service';
import { AppConfigService } from '@datagalaxy/webclient/config';
import {
    UserPublicData,
    userSettingsValues,
} from '@datagalaxy/webclient/user/domain';

const userFirstAccessCompletionTag = 'USD_isCompleted';

@Injectable({ providedIn: 'root' })
export class FirstAccessService {
    // Cached response
    private _isCompleted: boolean = null;

    constructor(
        private userService: UserService,
        private viewTypeService: ViewTypeService,
        private analyticService: AnalyticService,
        private appConfigService: AppConfigService,
    ) {}

    public async isCompleted() {
        if (this.appConfigService.DISABLE_FIRST_ACCESS) {
            return true;
        }

        if (this._isCompleted !== null) {
            return this._isCompleted;
        }

        const completions = await Promise.allSettled([
            this.isLegacySegmentationCompleted(),
            this.isFirstAccessCompleted(),
        ]);

        // Completion is met if at least one of both tests return true
        this._isCompleted =
            completions.filter(
                (r) => r.status === 'fulfilled' && r.value === true,
            ).length >= 1;

        return this._isCompleted;
    }

    public async publishCompletion(
        user: UserPublicData,
        firstAccessData: IUserFirstAccessData,
    ) {
        // Persist accepted legal terms and privacy policy conditions
        const { acceptedPrivacyPolicy, acceptedTermsOfUse } = firstAccessData;
        await this.persistFirstAccessValues({
            acceptedPrivacyPolicy,
            acceptedTermsOfUse,
        });
        // Language preference
        await this.userService.setUserLanguage(user, firstAccessData.language);
        // Objects ViewType display
        await this.persistViewType(firstAccessData.profile as ViewType);
        this._isCompleted = true;

        await this.notifyFirstAccessDone();
    }

    public async notifyFirstAccessDone() {
        await this.analyticService.addUserMetaData({
            [userFirstAccessCompletionTag]: true,
        });
    }

    private async isFirstAccessCompleted() {
        const res = await this.userService.getUserSettingValue(
            userSettingsValues.firstAccess.category,
            userSettingsValues.firstAccess.routes.data,
        );
        const data = (
            res.Value ? JSON.parse(res.Value) : {}
        ) as IUserFirstAccessData;
        return data.acceptedPrivacyPolicy && data.acceptedTermsOfUse;
    }

    /** @comment See readme */
    private async isLegacySegmentationCompleted() {
        const userData = await this.getSegmentationData();
        return userData.isCompleted;
    }

    private async getSegmentationData(): Promise<IUserSegmentationData> {
        const res = await this.userService.getUserSettingValue(
            userSettingsValues.userSegmentation.category,
            userSettingsValues.userSegmentation.routes.data,
        );
        return (
            res.Value ? JSON.parse(res.Value) : {}
        ) as IUserSegmentationData;
    }

    private async persistFirstAccessValues(
        firstAccessAcceptance: Pick<
            IUserFirstAccessData,
            'acceptedPrivacyPolicy' | 'acceptedTermsOfUse'
        >,
    ) {
        return await this.userService.setUserSettingValue(
            userSettingsValues.firstAccess.category,
            userSettingsValues.firstAccess.routes.data,
            JSON.stringify(firstAccessAcceptance),
        );
    }

    private async persistViewType(viewType: ViewType) {
        this.viewTypeService.setViewType(viewType);
    }
}
