import { Injectable } from '@angular/core';
import { BaseService, CoreEventsService } from '@datagalaxy/core-ui';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { ICookieLoginData } from './clientStorage.service';
import { ViewType } from '../shared/util/app-types/ViewType';
import { IWidgetConfigDb } from '../widgets-dashboard/WidgetUtil';
import { ILoginData } from '../shared/util/app-types/login-data.types';
import {
    CommunicationSetting,
    CommunicationType,
} from '@datagalaxy/webclient/user/data-access';
import {
    ClientLoginResult,
    ClientPublicData,
} from '@datagalaxy/webclient/client/data-access';
import { getContextId, getReferenceId } from '@datagalaxy/webclient/utils';
import {
    SystemLoginParameter,
    SystemLoginResult,
} from '@datagalaxy/webclient/auth/data-access';
import { AppConfigService } from '@datagalaxy/webclient/config';
import { LicenseLevel } from '@datagalaxy/webclient/license/domain';

/**
 * ## Role
 * Holds global data for the app
 *
 * ## Info
 * #Archi-service-app-low-level: Can only reference leaf services
 * @deprecated Replace with auth library
 */
@Injectable({ providedIn: 'root' })
export class AppDataService extends BaseService {
    //#region loginData

    public get isLoginDataLoaded() {
        return this.loginData.isLoaded;
    }
    public get currentLanguageCode() {
        return this.loginData.userLanguageCode;
    }
    public get isAuthenticated() {
        return (
            this.loginData.isSystemAuthenticated &&
            this.loginData.isClientAuthenticated
        );
    }
    public get isSystemAuthenticated() {
        return !!this.loginData.isSystemAuthenticated;
    }
    public get isLoginFailed() {
        return this.loginData.isLoginFailed;
    }

    public get clientId() {
        return (
            this.loginData.currentClientId || this.getFirstAuthorizedClientId()
        );
    }
    public get clientName() {
        return this.loginData.currentClientName;
    }
    public get clientDescription() {
        return this.loginData.clientDescription;
    }

    public get clientDefaultViewType() {
        return ViewType[this.clientUISettings?.defaultViewType];
    }
    public get clientGoldenSettingsUserLocalId() {
        return this.clientUISettings?.goldenSettingsUserLocalId;
    }
    public get clientWidgetConfigs() {
        return this.clientUISettings.widgets;
    }

    public get currentUserId() {
        return this.loginData.userId;
    }
    public get currentUserReferenceId() {
        return getReferenceId(
            this.loginData.currentClientId,
            this.loginData.userId,
        );
    }
    public get currentUserInitials() {
        return this.loginData.firstName[0] + this.loginData.lastName[0];
    }
    public get currentUserFirstName() {
        return this.loginData.firstName;
    }
    public get currentUserLastName() {
        return this.loginData.lastName;
    }
    public get currentUserFullName() {
        return `${this.loginData.firstName} ${this.loginData.lastName}`;
    }
    public get isSupportUser() {
        return this.loginData.isSupportUser;
    }
    public get currentUserEmail() {
        return this.loginData.loggedUserEmail;
    }

    /**
     * @deprecated use {@link CurrentUserService#userInfo.LanguageCode}
     */
    public get currentUserLanguageCode() {
        return this.loginData.userLanguageCode;
    }
    public get userSessionTimeoutMinutes() {
        return this.loginData.userSessionTimeoutMinutes;
    }
    public get currentUserLogin() {
        return this.loginData.login;
    }
    public get currentUserService() {
        return this.loginData.Service;
    }
    public get currentUserTitle() {
        return this.loginData.Title;
    }
    public get currentUserRole() {
        return this.loginData.Role;
    }
    public get currentUserLicenseLevelString() {
        return this.loginData.licenseLevelString;
    }
    public get currentUserLicenseLevel() {
        return LicenseLevel[this.currentUserLicenseLevelString];
    }
    public get currentUserLicenseStartsOn() {
        return this.loginData.LicenseStartsOn;
    }
    public get currentUserLicenseExpiresOn() {
        return this.loginData.LicenseExpiresOn;
    }
    public get currentUserIsSupport() {
        return this.loginData.IsSupportUser;
    }
    public get currentUserFirstConnectionOn() {
        return this.loginData.FirstConnectionTime;
    }
    public get currentUserLastConnectionOn() {
        return this.loginData.LastConnectionTime;
    }

    public get defaultSpaceUid() {
        return this.loginData.DefaultSpaceUid;
    }
    public get userSessionId() {
        return this.loginData.userSessionId;
    }
    public get accessToken() {
        return this.loginData.accessToken;
    }
    public get hasUserSessionId() {
        return !!this.loginData.userSessionId;
    }

    public get useAutoLogin() {
        return this.loginData.useAutoLogin;
    }

    //#region #Archi-access-rights Should be moved to security service
    public get canCreateEntities() {
        return this.loginData.CanCreateEntities;
    }
    public get canImportEntities() {
        return this.loginData.CanImportEntities;
    }
    public get isTrialClient() {
        return this.loginData.clientOfferPlan == 'TRIAL';
    }
    //#endregion

    private readonly loginData: Partial<ILoginData> = {};
    //#endregion

    public get isLoginInProgress() {
        return this._isLoginInProgress;
    }
    private _isLoginInProgress: boolean;

    private get clientUISettings() {
        return this.loginData.clientUISettings;
    }

    constructor(
        private coreEventsService: CoreEventsService,
        private appConfigService: AppConfigService,
    ) {
        super();
        this.init(true);
    }

    public init(withLoginData: boolean) {
        if (withLoginData) {
            this.initLoginData(false);
        }
    }
    private initLoginData(isLoaded: boolean) {
        const ld = this.loginData;
        ld.login = '';
        ld.loginToken = '';
        ld.forceNewSession = false;
        ld.userSessionId = '';
        ld.accessToken = '';
        ld.firstName = '';
        ld.lastName = '';
        ld.loggedUserEmail = '';
        ld.isLoginFailed = false;
        ld.isSystemAuthenticated = false;
        ld.isClientAuthenticated = false;
        ld.isPasswordChangeNeeded = false;
        ld.isAccountValidationNeeded = false;
        ld.accountValidationExpirationTime = null;
        ld.authorizedClients = [];
        ld.ownerClientName = '';
        ld.ownerClientId = '';
        ld.currentClientId = '';
        ld.useAutoLogin = false;
        ld.currentClientName = '';

        ld.isLoaded = isLoaded;
        ld.isSupportUser = false;

        ld.isSingleWorkspaceClient = false;
        ld.clientUISettings = {};
        ld.isClientAdmin = false;
        ld.isOnlineConnectorEnabled = false;

        //#Archi-LoginData: todo? some properties are not initialized
    }

    public setCurrentUserInfo(firstName: string, lastName: string) {
        this.loginData.firstName = firstName;
        this.loginData.lastName = lastName;
    }

    /** returns undefined if DefaultSpaceUid is not defined */
    public getLoginDataDefaultSpaceReferenceId() {
        const defaultSpaceUid = this.loginData.DefaultSpaceUid;
        return defaultSpaceUid
            ? getReferenceId(defaultSpaceUid, defaultSpaceUid)
            : undefined;
    }

    public getDefaultSpaceReferenceId() {
        const defaultSpaceUid = this.loginData.DefaultSpaceUid;
        return (
            defaultSpaceUid && getReferenceId(defaultSpaceUid, defaultSpaceUid)
        );
    }

    public setDefaultSpaceByReferenceId(refId: string) {
        this.loginData.DefaultSpaceUid = getContextId(refId);
    }

    public getAllClients(predicate?: (c: ClientPublicData) => boolean) {
        return CollectionsHelper.filter(
            this.loginData.authorizedClients,
            predicate,
            true,
            true,
        );
    }

    //#region login.service
    public setFromCookieLogin(cookieLoginData: ICookieLoginData) {
        if (!cookieLoginData) {
            return;
        }
        const loginData = this.loginData;
        if (cookieLoginData.useAutoLogin) {
            loginData.useAutoLogin = true;
        }
        if (cookieLoginData.login) {
            loginData.login = cookieLoginData.login;
        }
        if (cookieLoginData.loginToken) {
            loginData.loginToken = cookieLoginData.loginToken;
        }
        this.setCurrentLanguageCodeOrDefault(cookieLoginData.userLanguageCode);
    }
    public setLoggedout() {
        // Set data to disabled auto login
        this.loginData.isSystemAuthenticated = false;
        this.loginData.isClientAuthenticated = false;
    }
    public setLoginInProgress(value: boolean) {
        this._isLoginInProgress = value;
    }
    public clearLogginFailed() {
        this.loginData.isLoginFailed = false;
    }
    public clearUserSessionId() {
        this.loginData.userSessionId = '';
    }
    public updateClientData(
        friendlyName: string,
        description: string,
        clientUISettingsJSON: string,
    ) {
        this.loginData.currentClientName = friendlyName;
        this.loginData.clientDescription = description;
        this.loginData.clientUISettings =
            JSON.parse(clientUISettingsJSON || null) ?? {};
    }
    public setUserSessionTimeoutMinutes(value: number) {
        this.loginData.userSessionTimeoutMinutes = value;
    }
    public getRealTimeLoginData(origin: string) {
        return {
            userFullName: this.currentUserFullName,
            userId: this.currentUserId,
            currentClientName: this.clientName,
            currentClientId: this.clientId,
            userSessionId: this.userSessionId,
            origin,
        };
    }

    public getLoginToken() {
        return this.loginData.loginToken;
    }

    public setSystemAuthenticated() {
        this.loginData.isSystemAuthenticated = true;
    }
    public getLoginAndToken() {
        const loginData = this.loginData;
        return {
            login: loginData.login,
            loginToken: loginData.loginToken,
        };
    }
    public initIfNotLoaded() {
        if (!this.loginData.isLoaded) {
            this.initLoginData(true);
        }
    }
    public setUseAutoLogin(value: boolean) {
        this.loginData.useAutoLogin = value;
    }
    public setCurrentUserLogin(value: string) {
        this.loginData.login = value;
    }
    public setLoginFailed(value: boolean) {
        this.loginData.isLoginFailed = value;
    }
    public hasAuthorizedClientId(clientId: string) {
        return this.loginData.authorizedClients?.some(
            (c) => c.ClientId == clientId,
        );
    }
    public getFirstAuthorizedClientId() {
        return this.loginData.authorizedClients?.[0]?.ClientId;
    }
    public setIsClientAuthenticated(value: boolean) {
        this.loginData.isClientAuthenticated = value;
    }
    public setConnectionInfos(currentClientId: string, clr: ClientLoginResult) {
        const ld = this.loginData;
        ld.currentClientId = currentClientId;
        ld.userSessionId = clr.UserSessionId;
        ld.accessToken = clr.AccessToken;
        ld.FirstConnectionTime = clr.FirstConnectionTime;
        ld.LastConnectionTime = clr.LastConnectionTime;
        ld.IsExternal = clr.IsExternal;
        ld.IsExternalClient = clr.IsExternalLogin;
        ld.Category = clr.Category;
        ld.Status = clr.Status;
        ld.ClientEngagementLifeCycle = clr.ClientEngagementLifeCycle;
        ld.POVStartTime = clr.POVStartTime;
        ld.POVEndTime = clr.POVEndTime;
        ld.TacitAgreementDate = clr.TacitAgreementDate;
        ld.EndOfContractNotificationDate = clr.EndOfContractNotificationDate;
        ld.DefaultSpaceUid = clr.DefaultSpaceUid;
        ld.clientOfferName = clr.ClientOfferName;
        ld.clientOfferPlan = clr.ClientOfferPlan;
        ld.currentClientName = clr.ClientName;

        const lsd = clr.SecurityData;
        ld.licenseLevelString = lsd.LicenseLevel;
        ld.CanCreateEntities = lsd.CanCreateEntities;
        ld.CanImportEntities = lsd.CanImportEntities;

        ld.clientDescription = clr.ClientDescription;
        ld.clientUISettings =
            JSON.parse(clr.ClientUISettingsJSON || null) ?? {};
    }
    public getCookieLoginData(): ICookieLoginData {
        const { useAutoLogin, login, loginToken, userLanguageCode } =
            this.loginData;
        return { useAutoLogin, login, loginToken, userLanguageCode };
    }
    public setIsSingleWorkspaceClient(value: boolean) {
        this.loginData.isSingleWorkspaceClient = value;
    }
    /***
     * @deprecated
     */
    public setForMonitoring(data: {
        isClientAdmin: boolean;
        isOnlineConnectorEnabled: boolean;
        communicationSettings: CommunicationSetting[];
    }) {
        this.loginData.isClientAdmin = data.isClientAdmin;
        this.loginData.isOnlineConnectorEnabled = data.isOnlineConnectorEnabled;
        this.loginData.communicationSettings = data.communicationSettings;
    }
    public setForSystemLoginSuccess(slr: SystemLoginResult) {
        this.setCurrentLanguageCode(slr.UserLanguageCode);

        const ld = this.loginData;

        ld.isSystemAuthenticated = true;
        ld.userLanguageCode = slr.UserLanguageCode;

        // Set security data
        ld.loginToken = slr.LoginToken;

        // Set current owner client informations
        ld.ownerClientName = slr.OwnerClientName;
        ld.ownerClientId = slr.OwnerClientId;

        // Set list of authorized clients
        ld.authorizedClients = slr.AuthorizedClients;

        // Set Support User
        ld.isSupportUser = slr.IsSupportUser;

        // Set current client informations
        ld.currentClientId = slr.CurrentClientId;
        CollectionsHelper.withFirstFound(
            ld.authorizedClients,
            (authCli) => authCli.ClientId === ld.currentClientId,
            (authCli) => (ld.currentClientName = authCli.DisplayName),
        );

        // Set user informations
        ld.userId = slr.UserId;
        ld.firstName = slr.FirstName;
        ld.lastName = slr.LastName;
        ld.loggedUserEmail = ld.login || slr.Email;
        ld.LicenseExpiresOn = slr.LicenseExpiresOn;
        ld.LicenseStartsOn = slr.LicenseStartsOn;
        ld.IsSupportUser = slr.IsSupportUser;

        ld.IsInternalDataGalaxy = slr.IsInternalDataGalaxy;
        ld.Title = slr.Title;
        ld.Service = slr.Service;
        ld.Role = slr.Role;
        ld.UserEngagementLifeCycle = slr.UserEngagementLifeCycle;

        // Set account validation settings
        ld.isPasswordChangeNeeded = slr.IsPasswordChangeNeeded;
        ld.isAccountValidationNeeded = slr.IsAccountValidationNeeded;
        ld.accountValidationExpirationTime = new Date(
            slr.AccountValidationExpirationTime,
        );
    }

    //#endregion

    //#region api.service
    public getSystemLoginParameter() {
        return new SystemLoginParameter(
            this.loginData.login,
            this.loginData.loginToken,
        );
    }
    public isAuthenticatedAndHasToken() {
        return this.isAuthenticated && !!this.loginData.loginToken;
    }
    //#endregion

    //#region monitoring.service
    public getLoginDataForAnalyticsAccount() {
        const ld = this.loginData;
        return {
            clientOfferName: ld.clientOfferName,
            clientOfferPlan: ld.clientOfferPlan,
            isSingleWsClient: ld.isSingleWorkspaceClient,
            category: ld.Category,
            ClientEngagementLifeCycle: ld.ClientEngagementLifeCycle,
        };
    }
    public getLoginDataForAnalytics() {
        const ld = this.loginData;
        return {
            privateData: {
                isAuthenticated: this.isAuthenticated,
                userId: ld.userId,
                currentClientName: ld.currentClientName,
                currentClientId: ld.currentClientId,
            },
            publicData: {
                firstname: ld.firstName,
                name: ld.lastName,
                fullname: this.currentUserFullName,
                email: ld.loggedUserEmail,
                language: ld.userLanguageCode,
                title: ld.Title,
                service: ld.Service,
                role: ld.Role,
                clientid: ld.currentClientId,
                firstconnection: ld.FirstConnectionTime,
                lastconnection: ld.LastConnectionTime,
                isinternal: ld.IsInternalDataGalaxy,
                isexternal: ld.IsExternal,
                numberofclient: ld.authorizedClients.length,
                category: ld.Category,
                Status: ld.Status,
                UserEngagementLifeCycle: ld.UserEngagementLifeCycle,
                ClientEngagementLifeCycle: ld.ClientEngagementLifeCycle,
                POVStartTime: ld.POVStartTime,
                POVEndTime: ld.POVEndTime,
                TacitAgreementDate: ld.TacitAgreementDate,
                EndOfContractNotificationDate: ld.EndOfContractNotificationDate,
                licenselevel: ld.licenseLevelString,
                isSingleWsClient: ld.isSingleWorkspaceClient,
                groupId: ld.currentClientId,
                isUserClientAdmin: ld.isClientAdmin,
                isOnlineConnectorEnabled: ld.isOnlineConnectorEnabled,
                clientOfferName: ld.clientOfferName,
                clientOfferPlan: ld.clientOfferPlan,
                accessUrl: window.location.origin,
                ...this.getCommunicationSettingsObject(
                    ...ld.communicationSettings,
                ),
            },
        };
    }
    public getCommunicationSettingsObject(
        ...communicationSettings: CommunicationSetting[]
    ) {
        const data: { [key: string]: boolean } = {};
        communicationSettings.forEach(
            (cs) =>
                (data[`Com_${CommunicationType[cs.CommunicationType]}`] =
                    !!cs.EnableInApp),
        );
        return data;
    }
    //#endregion

    //#region client-api.service
    public setClientWidgets(widgets: IWidgetConfigDb[]) {
        this.loginData.clientUISettings.widgets = widgets;
    }
    public getClientUISettingsJSON() {
        return JSON.stringify(this.loginData.clientUISettings);
    }
    //#endregion

    //#region languageCode
    public setCurrentLanguageCodeFromBrowser() {
        const browserLang = navigator?.language;
        this.log('setCurrentLanguageCodeFromBrowser', browserLang);
        this.setCurrentLanguageCodeOrDefault(browserLang);
    }
    private setCurrentLanguageCodeOrDefault(userLanguageCode: string) {
        const languageCode =
            userLanguageCode || this.appConfigService.startupLanguage;
        this.log('setCurrentLanguageCode', userLanguageCode, languageCode);
        if (languageCode) {
            this.setCurrentLanguageCode(languageCode);
        }
    }
    public setCurrentLanguageCode(userLanguageCode: string) {
        this.loginData.userLanguageCode = userLanguageCode;
        this.coreEventsService.uiLanguage = userLanguageCode;
    }
    //#endregion
}
