import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';
import { DxyShowMoreButtonComponent, IActionOption } from '@datagalaxy/core-ui';
import { SuggestionCellComponent } from '../suggestion-cell/suggestion-cell.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
    ISuggestionParameters,
    ISuggestionRow,
    ISuggestionUserChoice,
} from '../suggestion.types';
import { SuggestionPanelUiService } from '../suggestion-panel-ui.service';
import { map } from 'rxjs';
import { EntityUiService } from '../../shared/entity/services/entity-ui.service';
import { EntityCardCellComponent } from '../../shared/entityCard/entity-card/entity-card-cell.component';
import { SuggestionType } from '@datagalaxy/webclient/suggestion/types';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { GridComponent, GridConfig, TColDef } from '@datagalaxy/ui/grid';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { SuggestionApplyAllButtonComponent } from '../suggestion-apply-all-button/suggestion-apply-all-button.component';
import { AsyncPipe, NgIf } from '@angular/common';

/***
 * ## Role
 * Omnigrid of suggestions for DCP and Tags
 */
@Component({
    selector: 'app-suggestion-panel-grid',
    templateUrl: './suggestion-panel-grid.component.html',
    styleUrls: ['./suggestion-panel-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        GridComponent,
        TranslateModule,
        DxyShowMoreButtonComponent,
        SuggestionApplyAllButtonComponent,
        SpinnerComponent,
        AsyncPipe,
    ],
})
export class SuggestionPanelGridComponent
    extends DxyBaseComponent
    implements OnInit
{
    @ViewChild(GridComponent) gridComponent: GridComponent;

    protected rows?: ISuggestionRow[] = [];
    protected isLoadingMore = false;
    protected gridConfig: GridConfig<ISuggestionRow> = {
        getItemId: (row) => row.suggestion?.id,
    };
    protected columns: TColDef<ISuggestionRow>[] = [];

    protected get allSuggestionsLoaded$() {
        return this.suggestionPanelUiService.allSuggestionsLoaded$;
    }

    protected get showMoreButtonVisible$() {
        return this.allSuggestionsLoaded$.pipe(
            map((loaded) => !loaded && this.hasSuggestions),
        );
    }

    public override get loading$() {
        return this.suggestionPanelUiService.loading$;
    }

    protected get isMainSpinnerVisible$() {
        return this.loading$.pipe(
            map((loading) => {
                return !this.hasSuggestions && loading;
            }),
        );
    }

    protected get gridHiddenAndPlaceholderVisible$() {
        return this.loading$.pipe(
            map((loading) => {
                return !this.hasSuggestions && !loading;
            }),
        );
    }

    protected get hasSuggestions() {
        return this.rows?.length > 0;
    }

    protected get allSuggestionsBeingApplied$() {
        return this.suggestionPanelUiService.applyAllLoading$;
    }

    protected suggestionType?: SuggestionType;
    private forceIncludeSpace?: boolean;

    private get suggestionTypeKey() {
        const sType = this.suggestionType;
        if (sType == undefined) {
            return;
        }
        return SuggestionType[sType];
    }

    constructor(
        private suggestionPanelUiService: SuggestionPanelUiService,
        private translate: TranslateService,
        private entityUiService: EntityUiService,
        private cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        this.subscribeToEvents();
    }

    protected onUserChoice(choice: ISuggestionUserChoice) {
        this.rows = [
            ...this.rows.map((row) => {
                const updatedRow = choice.ids.find(
                    (id) => id === row.suggestion.id,
                );

                if (!updatedRow) {
                    return row;
                }

                return {
                    ...row,
                    accepted: choice.accepted,
                };
            }),
        ];
    }

    protected onParametersChange(parameters: ISuggestionParameters) {
        const forceEntitySpace = !parameters.spaceId;
        if (
            this.suggestionType != parameters.suggestionType ||
            this.forceIncludeSpace != forceEntitySpace
        ) {
            this.suggestionType = parameters.suggestionType;
            this.forceIncludeSpace = forceEntitySpace;
        }
        this.createColumns();
        this.load();
    }

    protected async load() {
        this.rows =
            await this.suggestionPanelUiService.getFilteredSuggestions();
        this.refreshGrid();
    }

    protected async loadMore() {
        this.isLoadingMore = true;
        const rows = this.rows;
        const moreSuggestions =
            await this.suggestionPanelUiService.getMoreFilteredSuggestions(
                rows.map((o) => o.suggestion.id),
            );
        rows.push(...moreSuggestions);
        this.refreshGrid();
        this.scrollToBottom();
        this.isLoadingMore = false;
    }

    protected subscribeToEvents() {
        // We have to wait until gridApi is ready before subscribe to events
        this.subscribe(this.suggestionPanelUiService.userChoice$, (choice) => {
            this.onUserChoice(choice);
        });
        this.subscribe(
            this.suggestionPanelUiService.parameters$,
            (parameters) => {
                this.onParametersChange(parameters);
            },
        );
    }

    protected async onRowClick(data: ISuggestionRow) {
        await this.entityUiService.openPreviewOrDetailsOrDefault({
            HddData: data.HddData,
        });
    }

    private refreshGrid() {
        this.rows = [...this.rows];
        this.cd.detectChanges();
    }

    private getSuggestionColumnTitle() {
        if (!this.suggestionTypeKey) {
            return;
        }
        return this.translate.instant(
            `UI.Suggestions.Panel.ColumnTitle.${this.suggestionTypeKey}`,
        );
    }

    private createColumns() {
        this.columns = [
            {
                id: 'hierarchicalData',
                headerLabel: this.translate.instant(
                    'UI.Suggestions.Panel.ColumnTitle.Objects',
                ),
                sortable: false,
                customCellComponent: EntityCardCellComponent,
                getInputs: (row: ISuggestionRow) => {
                    return {
                        breadcrumbOpenPreview: true,
                        forceIncludeSpace: this.forceIncludeSpace,
                        hierarchicalData: row.suggestion.entity,
                        actions: this.getEntityActions(),
                    };
                },
            },
            {
                id: 'suggestion',
                headerLabel: this.getSuggestionColumnTitle(),
                sortable: false,
                customCellComponent: SuggestionCellComponent,
                getInputs: (row: ISuggestionRow) => ({
                    showGroupDisplayName:
                        this.suggestionType != SuggestionType.Dcp,
                    featureCodePrefix: 'SUGGESTIONS_CENTER_',
                    suggestion: row.suggestion,
                    accepted: row.accepted,
                }),
            },
        ];
    }

    private scrollToBottom() {
        this.cd.detectChanges();
        const lastRow = this.rows?.slice(-1)[0];
        if (!lastRow) {
            return;
        }
        this.gridComponent?.scrollToIndex(lastRow.suggestion.id);
        this.cd.detectChanges();
    }

    private getEntityActions() {
        const option: IActionOption<ISuggestionRow> =
            this.entityUiService.getEntityCellNavigationMenu(
                'SUGGESTIONS_CENTER',
            );

        return [option];
    }
}
