import { HttpClient } from '@angular/common/http';
import {
    OpenIdConfiguration,
    StsConfigHttpLoader,
} from 'angular-auth-oidc-client';
import { Observable, map } from 'rxjs';
import { defaultOpenIdConfig } from './default-open-id-config';

export const stsConfigHttpLoaderFactory = (httpClient: HttpClient) => {
    const baseUrl = window.location.origin;
    const configUrl = `${baseUrl}/oidc/configuration`;
    const obs: Observable<any> = httpClient
        .get<OpenIdConfiguration>(configUrl)
        .pipe(
            map((config: OpenIdConfiguration): OpenIdConfiguration => {
                return {
                    ...defaultOpenIdConfig,
                    authority: config.authority,
                    redirectUrl: window.location.origin,
                    postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}/see-you-soon`,
                };
            }),
        );
    return new StsConfigHttpLoader(obs);
};
