import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    DxyDgDateTimeCellComponent,
    DxyIconCellComponent,
} from '@datagalaxy/core-ui/cell-components';
import {
    DataQualityCheck,
    DataQualityRule,
} from '@datagalaxy/webclient/data-quality/data-access';
import { DataQualityService } from '../data-quality.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import { TextAndToolsCellComponent } from '../../shared/shared-ui/cells/text-and-tools-cell/text-and-tools-cell.component';
import { UserCollectionCellComponent } from '../../shared/shared-ui/cells/user-collection-cell/user-collection-cell.component';
import { LastChecksCellComponent } from '../last-checks-cell-component/last-checks-cell.component';
import { RuleCheckListModalComponent } from '../rule-check-modal/rule-check-list-modal.component';
import { IRuleCheckListModalOptions } from '../rule-check-modal/rule-check-list-modal.types';
import { ModalSize } from '@datagalaxy/ui/dialog';
import { CollectionsHelper, DomUtil } from '@datagalaxy/core-util';
import { GlyphUtil } from '../../shared/util/GlyphUtil';
import { withLoading } from '@datagalaxy/core-ui';
import { RuleBurgerMenuActionProvider } from './RuleBurgerMenuActionProvider';
import { RuleEditable } from '../data-quality.types';
import { IMiniEntityContent } from '@datagalaxy/webclient/entity/domain';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import {
    GridCellType,
    GridComponent,
    GridConfig,
    TColDef,
} from '@datagalaxy/ui/grid';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { GridUserSettingsDirective } from '../../user/grid-user-settings/grid-user-settings.directive';
import { DxyGridColumnSelectorComponent } from '@datagalaxy/ui/grid';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { NgIf, AsyncPipe } from '@angular/common';

/**
 * ## Role
 * Display data quality rules in a 3 or 6 (isFullColumnDisplay = true) columns grid.
 */
@Component({
    selector: 'app-data-quality-rules-grid',
    templateUrl: 'data-quality-rules-grid.component.html',
    styleUrls: ['data-quality-rules-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        DxyIconButtonDirective,
        MatLegacyTooltipModule,
        DxyGridColumnSelectorComponent,
        TranslateModule,
        GridComponent,
        GridUserSettingsDirective,
        SpinnerComponent,
        AsyncPipe,
    ],
})
export class DataQualityRulesGridComponent
    extends DxyBaseComponent
    implements OnInit
{
    @Input() entityData: IMiniEntityContent;
    @Input() isFullColumnDisplay = true;

    @ViewChild(GridComponent) grid?: GridComponent<DataQualityRule>;

    protected gridConfig: GridConfig<DataQualityRule> = {
        getItemId: (item) => item.id,
    };

    protected cols: TColDef<DataQualityRule>[] = [];

    protected data: DataQualityRule[];

    protected get isEmptyGrid() {
        return !this.data?.length;
    }

    protected get canCreateRule() {
        return (
            this.entityData.SecurityData.HasWriteAccess &&
            this.dataQualityService.AllowStructureEntityTypes.has(
                this.entityData.entityType,
            )
        );
    }

    constructor(
        private translate: TranslateService,
        private dataQualityService: DataQualityService,
        private dxyModalService: DxyModalService,
    ) {
        super();
        super.subscribe(dataQualityService.ruleUpdated$, (rule) =>
            this.updateRow(rule),
        );
        super.subscribe(dataQualityService.ruleDeleted$, (rule) =>
            this.deleteRow(rule),
        );
    }

    ngOnInit() {
        void this.initAsync();
    }

    public updateRow(rule: RuleEditable) {
        this.data = this.data.map((r) =>
            r.id === rule.id ? { ...r, ...rule } : r,
        );
    }

    public deleteRow(rule: RuleEditable) {
        this.data = this.data.filter((r) => r.id !== rule.id);
    }

    protected async onRowClick(qualityRule: DataQualityRule) {
        await this.openRuleCheckListModal(qualityRule);
        await this.initAsync();
    }

    protected async createRule() {
        const result = await this.dataQualityService.openRuleCreationModal(
            this.entityData.ReferenceId,
            this.entityData.entityType,
        );
        if (result) {
            await this.initAsync();
        }
    }

    @withLoading()
    private async initAsync() {
        this.data = await this.dataQualityService.getEntityRules(
            this.entityData.ReferenceId,
        );
        this.cols = this.getColumns();
    }

    private getColumns(): TColDef<DataQualityRule>[] {
        const columnLastResult = {
            id: 'lastResult',
            headerLabel: this.getLabel('lastResult'),
            type: GridCellType.custom,
            customCellComponent: DxyIconCellComponent,
            getInputs: (rule) => {
                const status = rule.lastChecks?.[0]?.status;
                return {
                    glyphClass: GlyphUtil.getDataQualityGlyphClass(status),
                    glyphTooltip: this.translate.instant(
                        DataQualityService.getDataQualityResultTranslateKey(
                            status,
                        ),
                    ),
                } as Partial<DxyIconCellComponent<DataQualityRule>>;
            },
            width: 140,
            maxWidth: 140,
            hidden: true,
        } as TColDef<DataQualityRule>;

        const typeLastResult = {
            id: 'type',
            headerLabel: this.getLabel('type'),
            type: GridCellType.text,
            getValue: (rule: DataQualityRule) =>
                rule.type === null
                    ? ''
                    : this.translate.instant(
                          `UI.DataQualityRules.DataQualityRuleType.${rule.type}`,
                      ),
        } as TColDef<DataQualityRule>;

        const columnStatement = {
            id: 'statement',
            headerLabel: this.getLabel('ruleStatement'),
            type: GridCellType.custom,
            customCellComponent: TextAndToolsCellComponent,
            getInputs: (rule) =>
                ({
                    text: rule.statement,
                    actions: [
                        {
                            dataTestId: (data: DataQualityRule) =>
                                `rule-${data.id}-action-button`,
                            burgerMenuProvider:
                                new RuleBurgerMenuActionProvider(
                                    this.dataQualityService,
                                ),
                        },
                    ],
                    actionsData: rule,
                }) as Partial<TextAndToolsCellComponent<DataQualityRule>>,
        } as TColDef<DataQualityRule>;

        const columnCode = {
            id: 'code',
            headerLabel: this.getLabel('ruleCode'),
            type: GridCellType.custom,
            customCellComponent: TextAndToolsCellComponent,
            getInputs: (rule) =>
                ({
                    text: rule.code,
                    actions: [
                        {
                            glyphClass: 'glyph-file-copy',
                            callback: (rule: DataQualityRule) =>
                                DomUtil.copyToClipboard(rule.code),
                        },
                    ],
                    actionsData: rule,
                }) as Partial<TextAndToolsCellComponent<DataQualityRule>>,
            hidden: true,
        } as TColDef<DataQualityRule>;

        const columnLastCheck = {
            id: 'lastChecks',
            headerLabel: this.getLabel('lastResults'),
            type: GridCellType.custom,
            customCellComponent: LastChecksCellComponent,
            getInputs: (rule) =>
                ({
                    checks: CollectionsHelper.orderBy(
                        rule.lastChecks.slice(0, 7),
                        (d: DataQualityCheck) => d.creationTime,
                        'asc',
                    ),
                }) as Partial<LastChecksCellComponent>,
            width: 180,
            maxWidth: 180,
            minWidth: 180,
            sortable: false,
        } as TColDef<DataQualityRule>;

        if (this.isFullColumnDisplay) {
            return [
                columnStatement,
                typeLastResult,
                columnLastResult,
                columnCode,
                {
                    id: 'lastCheckDate',
                    headerLabel: this.getLabel('lastCheckDate'),
                    type: GridCellType.custom,
                    customCellComponent: DxyDgDateTimeCellComponent,
                    getInputs: (rule) =>
                        ({
                            dgDate: rule.lastChecks?.[0]?.creationTime,
                        }) as Partial<
                            DxyDgDateTimeCellComponent<DataQualityRule>
                        >,
                    width: 180,
                    maxWidth: 180,
                },
                {
                    id: 'lastCheckCreatorId',
                    headerLabel: this.getLabel('lastCheckUser'),
                    type: GridCellType.custom,
                    customCellComponent: UserCollectionCellComponent,
                    getInputs: (rule) => {
                        const userId = rule.lastChecks?.[0]?.userId;
                        return {
                            userIds: userId ? [userId] : [],
                        } as Partial<UserCollectionCellComponent>;
                    },
                    maxWidth: 160,
                    hidden: true,
                },
                {
                    id: 'lastCheckMessage',
                    headerLabel: this.getLabel('lastResultSummary'),
                    type: GridCellType.text,
                    getValue: (rule: DataQualityRule) =>
                        rule.lastChecks?.[0]?.message,
                },
                columnLastCheck,
            ];
        }
        return [
            columnStatement,
            typeLastResult,
            columnLastResult,
            columnCode,
            columnLastCheck,
        ];
    }

    private getLabel(suffix: string) {
        return this.translate.instant(`UI.Modeler.DataQualityGrid.${suffix}`);
    }

    private async openRuleCheckListModal(qualityRule: DataQualityRule) {
        return await this.dxyModalService.open<
            RuleCheckListModalComponent,
            IRuleCheckListModalOptions,
            any
        >({
            componentType: RuleCheckListModalComponent,
            size: ModalSize.Large,
            data: {
                qualityRule: qualityRule,
            },
        });
    }
}
