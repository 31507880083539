import { Inject, Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { IApiConfig, BACKEND_API_CONFIG } from '@datagalaxy/data-access';
import { LoggingHttpService } from './logging-http.service';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class MonitoringInterceptor implements HttpInterceptor {
    constructor(
        @Inject(BACKEND_API_CONFIG) private config: IApiConfig,
        private loggingHttpService: LoggingHttpService,
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const requestStart = Date.now();
        this.loggingHttpService.notifyStart();

        const path = req.url
            .replace(this.config.baseUrl, '')
            .replace('/api/', '')
            .split('/')
            .join('.');

        this.loggingHttpService.notifyLog(
            `Server Request Start test - ${path}`,
        );

        return next.handle(req).pipe(
            tap(() => this.logRequestEnd(requestStart)),
            finalize(() => this.loggingHttpService.notifyStop()),
        );
    }

    private logRequestEnd(requestStart: number) {
        this.loggingHttpService.notifyLog(
            `Server Request End, Time: ${Date.now() - requestStart} ms`,
        );
        const timeoutStart = Date.now();
        setTimeout(() =>
            this.loggingHttpService.notifyLog(
                `Server Request - Post processing End! ${
                    Date.now() - timeoutStart
                } ms`,
            ),
        );
    }
}
