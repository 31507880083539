import { CurrentUserState } from './current-user-state';
import { Injectable } from '@angular/core';
import { ClientInfoDto } from '@datagalaxy/webclient/client/data-access';
import { BaseStateService } from '@datagalaxy/utils';
import { UserInfoDto } from '../user-info';

@Injectable({ providedIn: 'root' })
export class CurrentUserService extends BaseStateService<CurrentUserState> {
    public get clientId() {
        return this.state.clientInfo?.ClientId;
    }

    public get userInfo() {
        return this.state.userInfo;
    }

    public get languageCode() {
        return this.userInfo?.LanguageCode;
    }

    public get clientInfo() {
        return this.state.clientInfo;
    }

    public get communicationSettings() {
        return this.state.communicationSettings;
    }

    constructor() {
        super({});
    }

    public init(state: CurrentUserState) {
        this.setState(state);
    }

    public selectClientId() {
        return this.select((s) => s.clientInfo?.ClientId);
    }

    public selectLanguage() {
        return this.select(
            (s) =>
                s.userInfo?.LanguageCode ??
                navigator?.language?.substring(0, 2) ??
                'en',
        );
    }

    public rename(newName: { firstName: string; lastName: string }) {
        this.setState({
            userInfo: {
                ...(this.state.userInfo as UserInfoDto),
                FirstName: newName.firstName,
                LastName: newName.lastName,
            },
        });
    }

    public updateUserInfo(userInfo: Partial<UserInfoDto>) {
        this.setState({
            userInfo: {
                ...(this.state.userInfo as UserInfoDto),
                ...userInfo,
            },
        });
    }

    public updateClientInfo(clientInfo: Partial<ClientInfoDto>) {
        this.setState({
            clientInfo: {
                ...(this.state.clientInfo as ClientInfoDto),
                ...clientInfo,
            },
        });
    }
}
