<div class="modal-header">
    <h3 mat-dialog-title class="modal-title" translate>
        UI.apiDocumentation.title
    </h3>
    <button dxyIconButton color="transparent" (click)="onCloseCancel()">
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
<div mat-dialog-content>
    <div class="public-api-container">
        <div class="left-section">
            <div class="information-card">
                <div class="title" translate>
                    UI.apiDocumentation.description.title
                </div>
                <div class="description-content content">
                    <span translate
                        >UI.apiDocumentation.description.content.part1</span
                    >
                    <span translate
                        >UI.apiDocumentation.description.content.part2</span
                    >
                    <span translate
                        >UI.apiDocumentation.description.content.part3</span
                    >
                </div>
                <pre class="copy-container">
                    <span>{{v2ApiUrl}}</span>
                    <button dxyIconButton variant="tertiary" (click)="copyUrl()"><i class="glyph-file-copy"></i></button>
                </pre>
            </div>
            <div class="information-card">
                <div class="title" translate>
                    UI.apiDocumentation.needHelp.title
                </div>
                <div class="content">
                    <a (click)="onClickQuickStart()" translate
                        >UI.apiDocumentation.needHelp.link
                    </a>
                    <span translate>UI.apiDocumentation.needHelp.content</span>
                </div>
            </div>
            <div class="information-card">
                <div class="title" translate>
                    UI.apiDocumentation.documentation.title
                </div>
                <div class="content">
                    <a (click)="onClickPublicApi()" translate
                        >UI.apiDocumentation.documentation.link</a
                    >
                    <span translate
                        >UI.apiDocumentation.documentation.content</span
                    >
                </div>
            </div>
            <div class="information-card">
                <div class="title" translate>
                    UI.apiDocumentation.changelog.title
                </div>
                <div class="content">
                    <span class="description-content content" translate
                        >UI.apiDocumentation.changelog.details
                    </span>
                    <a (click)="onClickChangelog()" translate
                        >UI.apiDocumentation.changelog.link</a
                    >
                    <span translate>UI.apiDocumentation.changelog.content</span>
                </div>
            </div>
        </div>
        <div class="right-section">
            <img class="api-icon" src="/images/connectors.svg" />
            <div class="information-card">
                <div class="title" translate>
                    UI.apiDocumentation.links.title
                </div>
                <div class="links">
                    <button
                        dxyButton
                        variant="tertiary"
                        (click)="onDownloadDocumentationClick()"
                    >
                        <i class="glyph-upload"></i>
                        <span translate
                            >UI.apiDocumentation.links.openApiLink</span
                        >
                    </button>
                    <button
                        dxyButton
                        variant="tertiary"
                        (click)="onClickPublicApi()"
                    >
                        <i class="glyph-help"></i>
                        <span translate
                            >UI.apiDocumentation.links.documentationLink</span
                        >
                    </button>
                    <button
                        dxyButton
                        variant="tertiary"
                        (click)="onClickChangelog()"
                    >
                        <i class="glyph-help"></i>
                        <span translate
                            >UI.apiDocumentation.links.changelogLink</span
                        >
                    </button>
                    <button
                        dxyButton
                        variant="tertiary"
                        (click)="onClickQuickStart()"
                    >
                        <i class="glyph-help"></i>
                        <span translate
                            >UI.apiDocumentation.links.quickStartLink</span
                        >
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
