import { Component, Input } from '@angular/core';
import {
    CrudActionType,
    CrudOperation,
    FunctionalLogService,
} from '@datagalaxy/shared/monitoring/data-access';
import { ISuggestionRow } from '../suggestion.types';
import { SuggestionType } from '@datagalaxy/webclient/suggestion/types';
import { SuggestionService } from '../suggestion.service';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { TranslateModule } from '@ngx-translate/core';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';
import { NgIf } from '@angular/common';

/**
 * ## Role
 * MAnage accept all / decline all for suggestions
 */
@Component({
    selector: 'app-suggestion-apply-all-button',
    templateUrl: './suggestion-apply-all-button.component.html',
    styleUrls: ['./suggestion-apply-all-button.component.scss'],
    standalone: true,
    imports: [NgIf, DxyButtonDirective, TranslateModule, SpinnerComponent],
})
export class SuggestionApplyAllButtonComponent {
    @Input() suggestions: ISuggestionRow[];
    @Input() suggestionType: SuggestionType;
    @Input() disabled = false;

    private readonly featureCodePrefix = 'SUGGESTIONS_CENTER_';

    protected isLoading = false;

    protected get areButtonsDisabled() {
        return this.getPendingSuggestions().length === 0 || this.disabled;
    }

    constructor(
        private functionalLogService: FunctionalLogService,
        private suggestionService: SuggestionService,
    ) {}

    protected async onAcceptAllClick() {
        await this.applyUserChoice(true);
    }

    protected async onRejectAllClick() {
        await this.applyUserChoice(false);
    }

    private async applyUserChoice(accepted: boolean) {
        if (this.areButtonsDisabled) return;
        const crudActionType = accepted
            ? CrudActionType.AcceptAll
            : CrudActionType.DeclineAll;

        this.isLoading = true;

        this.functionalLogService.logFunctionalAction(
            `${this.featureCodePrefix}${SuggestionType[
                this.suggestionType
            ]?.toUpperCase()}`,
            CrudOperation.A,
            crudActionType,
        );

        switch (this.suggestionType) {
            case SuggestionType.Tag:
            case SuggestionType.Dcp:
                await this.applyUserChoiceForTagsAndDCPs(accepted);
                break;
            case SuggestionType.Link:
                await this.applyUserChoiceForLinks(accepted);
        }
        this.isLoading = false;
    }

    private async applyUserChoiceForTagsAndDCPs(accepted: boolean) {
        const filteredSuggestions = this.getPendingSuggestions();
        await this.suggestionService.applyAllForeachSuggestions(
            filteredSuggestions,
            accepted,
        );
    }

    private async applyUserChoiceForLinks(accepted: boolean) {
        const filteredSuggestions = this.getPendingSuggestions();
        await this.suggestionService.applyAllInSuggestionsGroupForType(
            this.suggestionType,
            filteredSuggestions,
            accepted,
        );
    }

    private getPendingSuggestions() {
        return this.suggestions.filter(
            (s) => s.accepted === undefined || s.accepted === null,
        );
    }
}
