import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { IPreviewPanelParams } from '../preview-panel.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
/**
 * ## Role
 * Dynamic preview panel content.
 * Inject a component inside the panel with parameters.
 */
@Component({
    selector: 'dxy-preview-panel-content',
    template: '<ng-container #panelContainer></ng-container>',
    standalone: true,
})
export class DxyPreviewPanelContentComponent
    extends DxyBaseComponent
    implements OnChanges, AfterViewInit
{
    @Input() contentParams: IPreviewPanelParams;
    @Output() ready = new EventEmitter<boolean>();
    @ViewChild('panelContainer', { read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef;

    constructor() {
        super();
    }
    ngOnChanges(changes: SimpleChanges) {
        super.onChange(
            changes,
            'contentParams',
            () => this.initInnerComponent(),
            false,
        );
    }
    ngAfterViewInit(): void {
        this.initInnerComponent();
    }

    private async initInnerComponent() {
        this.log('initInnerComponent');

        const componentType = this.contentParams?.component;
        this.viewContainerRef.clear();
        if (!componentType) {
            return;
        }
        const component =
            componentType &&
            this.viewContainerRef.createComponent(componentType);
        const inputs = this.contentParams.inputs;
        Object.keys(inputs).forEach((key) => {
            component.instance[key] = inputs[key];
        });
        component.instance.onReady.subscribe((data) =>
            this.onContentReady(data),
        );
    }

    private onContentReady(data: any) {
        this.ready.emit(data);
    }
}
