import { MultilingualState } from '../../multilingual-state';
import { LanguageSelectorButtonState } from './language-selector-button-state';

export const selectPendingBatchesState =
    (selectedLanguage: string) =>
    (state: MultilingualState): LanguageSelectorButtonState => {
        const languages = [
            state.settings.sourceLanguageCode ?? '',
            ...(state.settings.targetLanguages?.map((l) => l.languageCode) ??
                []),
        ];
        return {
            languageCodes: languages,
            sourceLanguageCode: state.settings.sourceLanguageCode ?? '',
            hasPendingBatch:
                !!state.pendingBatches?.some(
                    (b) => b.targetLanguageCode == selectedLanguage,
                ) || !!state.liveImportIds.length,
            enabled: state.settings.enabled ?? false,
            hasPendingRegeneration: !!state.runningRegenerations,
        };
    };
