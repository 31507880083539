<dxy-entity-header
    [entityData]="entityItem"
    [readOnly]="readOnly"
></dxy-entity-header>

<div class="buttons-wrapper">
    <app-watch-entity-button
        [entityItem]="entityData"
    ></app-watch-entity-button>
    <ng-container *ngIf="!noNavLink">
        <button
            *ngIf="!isDiagram"
            dxyIconButton
            variant="tertiary"
            (click)="goToEntity()"
            class="nav-btn"
            [matTooltip]="
                'UI.EntityHeader.actions.goToEntityFullPageTooltip' | translate
            "
            matTooltipPosition="below"
        >
            <i class="glyph-object-page"></i>
        </button>
        <button
            *ngIf="isDiagram"
            dxyIconButton
            variant="tertiary"
            (click)="goToDiagram()"
            class="nav-btn"
            [matTooltip]="
                'UI.EntityHeader.actions.goToDiagramTooltip' | translate
            "
            matTooltipPosition="below"
        >
            <i class="glyph-diagram1"></i>
        </button>
    </ng-container>
    <button
        dxyIconButton
        variant="tertiary"
        (click)="onCloseClick.emit()"
        [attr.data-dtname]="closeDtName"
    >
        <i class="glyph-cancelsearch"></i>
    </button>
</div>
