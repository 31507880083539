/* eslint-disable @typescript-eslint/no-namespace */
export namespace Constants {
    export namespace Nav {
        /** words used in the url */
        export namespace UrlWord {
            export const admin = 'admin';
            export const administration = 'administration';
            export const all = 'all';
            export const analysis = 'analysis';
            export const lineage = 'lineage';
            export const attributeAdmin = 'attributes';
            export const browse = 'browse';
            export const catalog = 'catalog';
            export const client = 'client';
            export const clients = 'clients';
            export const columns = 'columns';
            export const containers = 'containers';
            export const create = 'create';
            export const connector = 'connector';
            export const campaigns = 'campaigns';
            export const dashboard = 'dashboard';
            export const datamap = 'datamap';
            export const diagram = 'diagram';
            export const diagrams = 'diagrams';
            export const diagramsList = 'diagrams-list';
            export const error = 'error';
            export const exploratory = 'exploratory';
            export const extlogin = 'extlogin';
            export const expiredTrialLicense = 'expired-trial';
            export const fields = 'fields';
            export const firstAccess = 'first-access';
            export const flat = 'flat';
            export const foreignKeys = 'foreign-keys';
            export const full = 'full';
            export const glossary = 'glossary';
            export const goto = 'goto';
            export const grid = 'grid';
            export const history = 'history';
            export const home = 'home';
            export const implementation = 'implementation';
            export const init = 'init';
            export const isUp = 'is-up';
            export const integrations = 'integrations';
            export const invite = 'invite';
            export const licenses = 'licenses';
            export const licensePools = 'licensePools';
            export const links = 'links';
            export const list = 'list';
            export const login = 'login';
            export const seeYouSoon = 'see-you-soon';
            export const mapping = 'mapping';
            export const mappings = 'mappings';
            export const objects = 'objects';
            export const parameters = 'parameters';
            export const person = 'person';
            export const primaryKeys = 'primary-keys';
            export const processing = 'processing';
            export const profile = 'profile';
            export const reset = 'reset';
            export const right = 'right';
            export const screens = 'screens';
            export const search = 'search';
            export const searchResults = 'search-results';
            export const security = 'security';
            export const access = 'access';
            export const accessObjects = 'access-objects';
            export const settings = 'settings';
            export const dataQuality = 'data-quality';
            export const sources = 'sources';
            export const space = 'space';
            export const spaces = 'spaces-list';
            export const structures = 'structures';
            export const tables = 'tables';
            export const sample = 'sample';
            export const tasks = 'tasks';
            export const tree = 'tree';
            export const usage = 'usage';
            export const user = 'user';
            export const userSettings = 'user-settings';
            export const users = 'users';
            export const teams = 'teams';
            export const versions = 'versions';
            export const details = 'details';

            export const activityLog = 'activity-log';
            export const analytics = 'analytics';
            export const roles = 'roles';
            export const usageFields = 'usage-fields';
            export const usageComponents = 'usage-components';
            export const connectors = 'connectors';
            export const technologies = 'technologies';
            export const collaboration = 'collaboration';
            export const tokens = 'tokens';
        }

        /** state parameter names */
        export namespace ParamKey {
            export const AutoEntityTypeFilter = 'autoEntityTypeFilter';
            export const ClientId = 'clientId';
            export const DataTypeMappingId = 'dataTypeMappingId';
            export const DiagId = 'diagid';
            export const DiagElementId = 'diagElemId';
            export const ErrorMessage = 'errorMessage';
            export const ErrorType = 'errorType';
            export const FilteredViewId = 'filteredViewId';
            export const ImpactAnalysisTool = 'tool';
            export const IsEntityFullPageWord = 'isEntityFullPageWord';
            export const IsHierarchicalWord = 'isHierarchicalWord';
            export const AccessLevel = 'accessLevel';
            export const ServerType = 'serverType';

            export const NavId = 'navid';
            export const ModelId = 'modelId';

            //#region #Archi-cleanup Could probably be replaced by a common EntityId. see nativigationService#getEntityIdParamName
            export const PropertyId = 'propertyId';
            export const ModelElementId = 'modelElementId';
            export const SoftwareId = 'softwareId';
            export const DataProcessingId = 'dataProcessingId';
            //#endregion

            //#region Used on External Login (SAML, etc.)
            export const Status = 'status';
            export const LoginToken = 'logintoken';
            export const Path = 'path';
            //#endregion

            export const PreFilterWord = 'preFilterWord';
            export const EntityType = 'entityType';

            export const PropId = 'propid';
            export const PropType = 'propType';
            export const TabItemIndex = 'tabItemIndex';
            export const TableId = 'tableId';
            export const VersionId = 'versionId';
            export const DashboardId = 'dashboardId';
            export const UserSettingsViewIdentifier =
                'userSettingsViewIdentifier';
            export const CampaignId = 'campaignId';
        }
    }

    export namespace ActionType {
        export const User = 'User';
        export const Save = 'Save';
        export const State = 'State';
    }

    export const defaultDashboardName = 'Dashboard';

    export namespace FreshdeskUrl {
        export const LangPlaceholder = '{langCode}';

        export const BaseUrl = 'https://datagalaxy.freshdesk.com/';
        export const BaseApiUrl = BaseUrl + 'api/v2';
        export const BaseArticlesUrl = `${BaseUrl}${LangPlaceholder}/support/solutions/articles`;

        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const CsvImportHelp = BaseArticlesUrl + '/35000132821';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const OnlineImportHelp = BaseArticlesUrl + '/35000179788';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const DesktopImportHelp = BaseArticlesUrl + '/35000132804';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const CsvImportSamples = BaseArticlesUrl + '/35000139751';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const OnlineImportTokens = BaseArticlesUrl + '/35000132471';
        /**
         * @deprecated Replace with dxyFreshdeskHelpLink directive
         */
        export const DesktopImportOperations = BaseArticlesUrl + '/35000132850';
    }
}
