import { AttributeMatch } from '../shared/AttributeMatch';
import { BaseService } from '@datagalaxy/core-ui';
import { ImportContext, ImportTarget } from '../shared/ImportContext';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { Subject } from 'rxjs';
import { ModalSize } from '@datagalaxy/ui/dialog';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';
import { DxyModalService } from '../../shared/dialogs/DxyModalService';
import {
    CheckMessageData,
    ICheckMessageErrorLink,
} from '../shared/wizard-context';
import { ViewIdentifier } from '../../shared/util/ViewIdentifier';
import { NavigationService } from '../../services/navigation.service';
import { EntitySecurityService } from '../../shared/entity/services/entity-security.service';
import { AppEventsService } from '../../services/AppEvents.service';
import { userSettingsValues } from '@datagalaxy/webclient/user/domain';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { WorkspaceStore } from '@datagalaxy/webclient/workspace/data-access';

@Injectable({ providedIn: 'root' })
export class ImportWizardService extends BaseService {
    constructor(
        private translate: TranslateService,
        private userService: UserService,
        private navigationService: NavigationService,
        private workspaceStore: WorkspaceStore,
        private entitySecurityService: EntitySecurityService,
        private appEventsService: AppEventsService,
        private dxyModalService: DxyModalService,
    ) {
        super();
    }

    public isMissingMandatory(item: AttributeMatch) {
        return item?.isMandatory && !item?.isMatched;
    }

    public async openImportModal(closeOnNavigation = true): Promise<void> {
        let dgModule = this.navigationService.getCurrentModule();
        const currentSpaceOrLatest = this.workspaceStore.currentSpace;
        if (
            !this.entitySecurityService.hasImportAccessToModule(
                currentSpaceOrLatest,
                dgModule,
            )
        ) {
            dgModule = DgModule.unknown;
        }
        const importContext = new ImportContext(
            currentSpaceOrLatest,
            ImportTarget.Entities,
            dgModule,
        );
        importContext.onModalClosed = (isImportDone: boolean) => {
            if (isImportDone) {
                this.appEventsService.notifyImportDone();
            }
        };
        await this.openImportWizardModal(importContext, closeOnNavigation);
    }

    public async openImportWizardModal(
        importContext: ImportContext,
        closeOnNavigation = true,
    ): Promise<void> {
        const result = await this.getImportSettingsShowDetailsStep();
        if (result.Value) {
            const importSettings = JSON.parse(
                result.Value,
            ) as ImportSettingsShowDetailsStep;
            importContext.showCsvDetails = importSettings.showCsvDetails;
            importContext.showEmbeddedDetails =
                importSettings.showEmbeddedDetails;
        }
        try {
            const { DxyImportWizardModalComponent } = await import(
                '../components/dxy-import-wizard/dxy-import-wizard-modal/dxy-import-wizard-modal.component'
            );
            await this.dxyModalService.open({
                componentType: DxyImportWizardModalComponent,
                size: ModalSize.Large,
                disableCloseOnBackdropClick: true,
                disableCloseOnEscapeKey: true,
                closeOnNavigation,
                data: {
                    importContext,
                },
            });
        } catch (e) {
            console.warn(e);
        } finally {
            importContext.onModalClosed?.(importContext.isImportDone);
        }
    }

    public setImportSettingsShowDetailsStep(
        settings: ImportSettingsShowDetailsStep,
    ) {
        this.userService.setUserSettingValue(
            userSettingsValues.import.category,
            userSettingsValues.import.routes.settings,
            JSON.stringify(settings),
        );
    }

    public getImportSettingsShowDetailsStep() {
        return this.userService.getUserSettingValue(
            userSettingsValues.import.category,
            userSettingsValues.import.routes.settings,
        );
    }

    public getCheckIcon(isLoading: boolean, importContext: ImportContext) {
        if (isLoading) {
            return 'dg5-spinner';
        }

        if (!importContext.areAllMandatoryFieldsMatched) {
            return 'glyph-mandatory red-glyph';
        }

        if (importContext.hasChangedMapping) {
            return 'glyph-warning red-glyph';
        }

        if (importContext.hasImportErrors) {
            return 'glyph-mandatory red-glyph';
        }

        if (importContext.hasImportWarnings) {
            return 'glyph-warning orange-glyph';
        }

        return 'glyph-check green-glyph';
    }

    public get redirectToPreImportTab$() {
        return this.redirectToPreImportTab.asObservable();
    }
    private redirectToPreImportTab = new Subject<string>();

    public emitRedirectToPreImportTab(tabKey: string) {
        this.redirectToPreImportTab.next(tabKey);
    }

    public getCheckMessageData(
        isCheckLoading: boolean,
        importContext: ImportContext,
    ): CheckMessageData {
        const isMessageExpanded =
            !isCheckLoading && importContext.isMappingInitDone;
        // first line
        const mappedCount = CollectionsHelper.count(
            importContext.attributeMatches,
            (o) => o.isMatched,
        );
        const fieldsCount = importContext.csvFields?.length ?? 0;
        const firstLineLbl = this.translate.instant(
            'Import.GenericImportWizard.CsvPreImport.MappedDataLbl',
            { mappedCount, fieldsCount },
        );

        // second line
        const recordsCount =
            importContext.lastImportResult?.Stats?.TotalLinesCount ?? 0;
        const secondLineLbl = this.translate.instant(
            'Import.GenericImportWizard.CsvPreImport.ParsedDataLbl',
            { recordsCount },
        );
        return new CheckMessageData(
            isMessageExpanded,
            firstLineLbl,
            secondLineLbl,
            this.getErrorLinkFromImportContext(importContext),
        );
    }

    private getErrorLinkFromImportContext(
        importContext: ImportContext,
    ): ICheckMessageErrorLink {
        if (!importContext.areAllMandatoryFieldsMatched) {
            return {
                errorMessage: this.translate.instant(
                    'Import.GenericImportWizard.CsvPreImport.StatusMissingMandatory',
                ),
                redirectionViewIdentifier: ViewIdentifier.CsvImportMapping,
            };
        }
        if (importContext.hasChangedMapping) {
            return {
                errorMessage: this.translate.instant(
                    'Import.GenericImportWizard.CsvPreImport.StatusCheck',
                ),
            };
        }
        if (importContext.hasImportErrors) {
            return {
                errorMessage: this.translate.instant(
                    'Import.GenericImportWizard.CsvPreImport.ErrorsCount',
                    { errorsCount: importContext.errorsCount },
                ),
                redirectionViewIdentifier: ViewIdentifier.CsvImportCheck,
            };
        }
        if (importContext.hasImportWarnings) {
            return {
                errorMessage: this.translate.instant(
                    'Import.GenericImportWizard.CsvPreImport.WarningsCount',
                    { warningsCount: importContext.warningsCount },
                ),
                redirectionViewIdentifier: ViewIdentifier.CsvImportCheck,
            };
        }
    }
}

interface ImportSettingsShowDetailsStep {
    showCsvDetails?: boolean;
    showEmbeddedDetails?: boolean;
}
