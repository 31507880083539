<app-attribute-filter-add-dropdown
    [class.disabled]="loading$ | async"
    [class.hidden]="filter"
    [attributes]="attributes"
    [noSections]="true"
    buttonIconClass="glyph-filter-empty"
    addBtnText="UI.Suggestions.Panel.addFilter"
    (onAddAttribute)="initFilter($event)"
></app-attribute-filter-add-dropdown>

<app-attribute-filter
    *ngIf="filter"
    [filterItemData]="filter"
    [isReadOnly]="loading$ | async"
    (onAction)="onFilterAction($event)"
></app-attribute-filter>
