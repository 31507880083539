import { ChangeDetectionStrategy, Component } from '@angular/core';
import { GlossaryService } from '../../glossary/glossary.service';
import { SuggestionPanelUiService } from '../suggestion-panel-ui.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { TranslateModule } from '@ngx-translate/core';
import { DxyButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * Display a placeholder for glossary auto generate import from source (img + text)
 * and a button to open the glossary generation modal
 */
@Component({
    selector: 'app-suggestion-panel-glossary-import',
    templateUrl: './suggestion-panel-glossary-import.component.html',
    styleUrls: ['./suggestion-panel-glossary-import.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslateModule, DxyButtonDirective, DxyLogFunctionalDirective],
})
export class SuggestionPanelGlossaryImportComponent extends DxyBaseComponent {
    constructor(
        private glossaryService: GlossaryService,
        private suggestionPanelUiService: SuggestionPanelUiService,
    ) {
        super();
    }

    protected async openGlossaryGenerationModal() {
        this.suggestionPanelUiService.toggleSuggestionPanel();
        await this.glossaryService.openGenerateFromSourceModal();
    }
}
