import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnInit,
} from '@angular/core';
import { ProgressBarComponent } from '@datagalaxy/ui/progress-bar';
import { DxyRichTooltipDirective } from '@datagalaxy/ui/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MultilingualStateService } from '../../multilingual-state.service';
import { IconComponent } from '@datagalaxy/ui/icon';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { selectPendingTranslationMessageKeys } from './pending-translations-selector';
@Component({
    selector: 'dxy-translation-progress',
    templateUrl: './translation-progress.component.html',
    styleUrls: ['./translation-progress.component.scss'],
    standalone: true,
    imports: [
        ProgressBarComponent,
        NgIf,
        NgFor,
        AsyncPipe,
        DxyRichTooltipDirective,
        TranslateModule,
        IconComponent,
        MatLegacyTooltipModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationProgressComponent implements OnInit {
    @Input() languageCode!: string;
    @Input() displayMode: 'icon' | 'badge' = 'badge';
    protected pendingTranslationMessageKeys$?: Observable<string[]>;
    private multilingualStateService = inject(MultilingualStateService);

    async ngOnInit() {
        this.pendingTranslationMessageKeys$ =
            this.multilingualStateService.select(
                selectPendingTranslationMessageKeys(this.languageCode),
            );
    }
}
