import { StateParams } from '@uirouter/core';
import { BaseService } from '@datagalaxy/core-ui';
import { wait } from '@datagalaxy/core-util';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { AppDataService } from './app-data.service';
import { NavigationService } from './navigation.service';
import { JwtUtil } from '../shared/util/Jwt.util';
import { ErrorLoginType } from '../shared/util/app-types/errors.types';
import { LegacyLoginService } from './login.service';
import { Constants } from '../shared/util/Constants';
import { healCheckRoute } from '../app.routes';
import {
    AuthApiService,
    GetExternalAuthenticationMethodParameter,
} from '@datagalaxy/webclient/auth/data-access';
import { FeatureFlagService } from '@datagalaxy/webclient/feature-flag';
import { NavNewTabService } from '../navigation/nav-new-tab/nav-new-tab.service';

/**
 * @deprecated use new Auth library authentication service
 */
@Injectable({ providedIn: 'root' })
export class LegacyAuthenticationService extends BaseService {
    private _isAuthenticationMethodResolved: boolean;
    private _isUsingExternalLogin: boolean;

    public get IsUsingExternaLogin() {
        return this._isUsingExternalLogin;
    }

    private get navigationService() {
        return (this._navigationService ??=
            this.injector.get(NavigationService));
    }
    private _navigationService: NavigationService;

    constructor(
        private injector: Injector,
        private location: Location,
        private window: Window,
        private translate: TranslateService,
        private loginService: LegacyLoginService,
        private appDataService: AppDataService,
        private authApiService: AuthApiService,
        private featureFlagService: FeatureFlagService,
        private navNewTabService: NavNewTabService,
    ) {
        super();
        this._isAuthenticationMethodResolved = false;
        this._isUsingExternalLogin = false;
    }

    public async getExternalAuthenticationMethod() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        const parameter = new GetExternalAuthenticationMethodParameter(
            this.getAbsUrl(),
        );
        return this.authApiService.getExternalAuthenticationMethod(parameter);
    }
    private getAbsUrl() {
        // ngJS using with $location: ng.ILocationService
        //return this.$location.absUrl()
        return this.window.location.href;
    }
    private getPath() {
        // ngJS using with $location: ng.ILocationService
        //return this.$location.path()
        return this.location.path(false);
    }
    private getUrl() {
        // ngJS using with $location: ng.ILocationService
        //return this.$location.url
        return this.location.path(true);
    }

    public async enterApp() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.log('enterApp');

        this.loginService.init();

        if (this.getPath() == healCheckRoute.url) {
            return;
        }

        // Two calls are made to enterApp during login process, this is a simple reentrancy logic
        if (
            this._isAuthenticationMethodResolved ||
            this.getPath().indexOf(Constants.Nav.UrlWord.extlogin) == 1
        ) {
            return;
        }

        await this.doEnterAppExternalLoginSwitch();
        this.log('enterApp-doEnterAppExternalLoginSwitch-done');
    }

    /** To support more and more clients using SAML authentication without having to hard-code values, we now
     *  start the login process with the GetExternalMethod call.
     *
     *  #Archi/performance: eventually use a local cache if the additional call causes difficulties
     *
     *  */
    public async doEnterAppExternalLoginSwitch() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        this.log('Checking on server if SSO Enabled...');
        try {
            const result = await this.getExternalAuthenticationMethod();
            this._isAuthenticationMethodResolved = true;
            if (result.IsExternal) {
                this.log('SSO Enabled!');
                this._isUsingExternalLogin = true;
                // At this point, we redirect the Browser to the external Idp URL (leaving the Angular App)
                this.window.location.assign(result.AuthenticationUrl);
            } else {
                this.log('No SSO: Normal Login');
                return;
            }
        } catch (error) {
            this.log('doEnterAppExternalLoginSwitch-error', error);
            this._isAuthenticationMethodResolved = true;
            if (error.status == -1) {
                // No server: Will let the login screen handle the issue
                return;
            }

            if (error.status !== 401) {
                this.log(`Error: ${error}`);
            }

            await this.navigationService.goToMainError(
                this.translate.instant('UI.Login.lblAuthenticationFailed'),
            );
        }
    }

    public async doLoginProcess() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        if (this.appDataService.isSystemAuthenticated) {
            return;
        }

        let useAutoLogin = this.appDataService.useAutoLogin;

        if (!useAutoLogin) {
            const targetUrl = this.getUrl();
            const cookieUrl = this.navNewTabService.getUrlInCookieNavNewTab();
            const isAuthorized = cookieUrl && targetUrl === cookieUrl;
            //this.log('doLoginProcess', isAuthorized, targetUrl, cookieUrl)
            if (isAuthorized) {
                this.navNewTabService.clearCookieNavNewTab();
                useAutoLogin = true;
            }
        }

        if (useAutoLogin) {
            try {
                await this.loginService.login(true, '');
            } catch (err) {
                throw this.loginService.getLoginError(
                    ErrorLoginType.notAuthenticated,
                );
            }
        } else {
            throw this.loginService.getLoginError(
                ErrorLoginType.notAuthenticated,
            );
        }
    }

    public async doExternalLogin(stateParams: StateParams) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        const externalInitialPath: string =
            stateParams[Constants.Nav.ParamKey.Path];
        const token = stateParams[Constants.Nav.ParamKey.LoginToken];

        this._isUsingExternalLogin = true;

        await this.loginService.externalLogin(token);
        if (externalInitialPath && !externalInitialPath.includes('/login')) {
            this.window.location.assign(externalInitialPath);
            return;
        }

        await this.navigationService.goToMainPrivateIndex();
    }

    /** NOTE: We are voluntarily stalling the Login State (showing login screen) if we are configured to use
     // External Login */
    public async showLoginScreen() {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        if (this._isUsingExternalLogin) {
            this.log(
                'Waiting for up to 30 secs for SSO Provider to respond...',
            );
            await wait(30000);
        }
    }

    public async handleExternalLoginFailed($stateParams: StateParams) {
        if (this.featureFlagService.isFeatureEnabled('ENABLE_AUTH_V2')) {
            return;
        }
        let loginContent = '';

        // Since no user or client, we use the Default Language Code from browser
        this.appDataService.setCurrentLanguageCodeFromBrowser();

        try {
            const tokenContent = JwtUtil.parseJwt(
                $stateParams[Constants.Nav.ParamKey.LoginToken],
            );
            loginContent = tokenContent.login;
        } catch (e) {
            this.warn(e);
        }

        const loginStatus: ExternalLoginStatus = parseInt(
            $stateParams[Constants.Nav.ParamKey.Status],
        );
        let errorMessage = '';
        switch (loginStatus) {
            case ExternalLoginStatus.FailedUnknownUser:
                errorMessage = this.translate.instant(
                    'UI.Login.ExternalLoginStatus.FailedUnknownUser',
                    { login: loginContent },
                );
                break;
            case ExternalLoginStatus.FailedNoLicenseAvailable:
                errorMessage = this.translate.instant(
                    'UI.Login.ExternalLoginStatus.FailedNoLicenseAvailable',
                    { login: loginContent },
                );
                break;
        }

        await this.navigationService.goToMainError(errorMessage, false);
    }
}

/***
 * @deprecated unused in authV2 process
 */
export enum ExternalLoginStatus {
    OK = 1,
    FailedUnknownUser = -1,
    FailedNoLicenseAvailable = -2,
}
