import { Injectable } from '@angular/core';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { RawParams, StateService } from '@uirouter/core';
import { StateName } from '@datagalaxy/webclient/routing';

export const workspaceParams = {
    workspaceId: 'navid',
    versionId: 'versionId',
};

@Injectable({ providedIn: 'root' })
export class WorkspaceRouterService {
    public get isInsideWorkspace() {
        return this.stateService.includes(StateName.Space);
    }

    constructor(private stateService: StateService) {}

    public getSpaceHomeTargetState(spaceIdr: IWorkspaceIdentifier) {
        const params: RawParams = {
            [workspaceParams.workspaceId]: spaceIdr.spaceId,
            [workspaceParams.versionId]: spaceIdr.versionId,
        };

        return this.stateService.target(StateName.SpaceHome, params);
    }

    public goToSpaceHome(spaceIdr: IWorkspaceIdentifier) {
        const targetState = this.getSpaceHomeTargetState(spaceIdr);
        return this.stateService.go(targetState.name(), targetState.params());
    }

    public goToWorkspaceList() {
        return this.stateService.go(StateName.ClientSpacesList);
    }
}
