<a
    class="nav-item-container"
    (click)="onClick()"
    [dxyLogFunctional]="logModule"
    [dxyTooltip]="tooltipContent | translate"
    dxyTooltipPosition="right"
    [attr.data-dtname]="dtNameFull"
    [uiSref]="stateId"
    uiSrefActive="active"
    [dxyDataTestId]="itemIdentifier + '-navbar-item'"
    #menuTrigger
>
    <ng-content select="[sidebar-item-icon]"></ng-content>
    <i
        *ngIf="!isGlyphUndefined"
        class="icon sidebar-icon"
        [ngClass]="glyphClass"
        [attr.data-dtname]="dtNameFull"
    ></i>
    <span
        class="caption"
        dxyEllipsisTooltip
        dxyEllipsisTooltipPosition="right"
        >{{ caption }}</span
    >

    <i
        *ngIf="menuOptions || hasCustomMenu"
        class="expand-btn glyph-arrow-drop-down"
        [class.expanded]="!showTooltip"
    ></i>
</a>

<ng-template #templateRef>
    <div
        class="dg5-mat-menu mat-menu-panel"
        (click)="onMenuClick()"
        @fadeInFadeOut
    >
        <div class="mat-menu-content">
            <ng-content select="[sidebar-item-menu]"></ng-content>
            <dxy-burger-menu
                *ngIf="menuOptions"
                [options]="menuOptions"
            ></dxy-burger-menu>
        </div>
    </div>
</ng-template>
