import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultilingualStateService } from '../../multilingual-state.service';
import { LanguageIconComponent } from '@datagalaxy/webclient/multilingual/ui';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { LanguageCardComponent } from '../language-card/language-card.component';
import {
    DxyTranslateModule,
    executeOnce,
    withLoading,
} from '@datagalaxy/core-ui';
import { BaseComponent } from '@datagalaxy/utils';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { UIRouterModule } from '@uirouter/angular';
import { MultilingualStateName } from '../../multilingual.routes';
import { SpinnerComponent } from '@datagalaxy/ui/spinner';
import { LanguageUserSettingsService } from '../language-user-settings.service';
import { selectPendingBatchesState } from './language-selector-button-state-selector';
import { TranslationProgressComponent } from '../../translations/translation-progress/translation-progress.component';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { CurrentUserService } from '@datagalaxy/webclient/user/data-access';
import { fadeInFadeOutAnimation } from '@datagalaxy/ui/animation';

@Component({
    selector: 'dxy-language-selector-button',
    standalone: true,
    imports: [
        CommonModule,
        LanguageIconComponent,
        DxyIconButtonDirective,
        MatMenuModule,
        LanguageCardComponent,
        SpinnerComponent,
        MatTooltipModule,
        DxyTranslateModule,
        UIRouterModule,
        TranslationProgressComponent,
    ],
    templateUrl: './language-selector-button.component.html',
    styleUrls: ['./language-selector-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInFadeOutAnimation()],
})
export class LanguageSelectorButtonComponent extends BaseComponent {
    @Input() administrationLinkVisible = false;

    private currentUserService = inject(CurrentUserService);
    private multilingualStateService = inject(MultilingualStateService);
    private languageUserSettingsService = inject(LanguageUserSettingsService);
    protected selectedLanguageCode =
        this.currentUserService.userInfo?.LanguageCode ?? 'en';

    protected state$ = this.multilingualStateService.select(
        selectPendingBatchesState(this.selectedLanguageCode),
    );

    protected adminRouteStateName = MultilingualStateName.Languages;

    @withLoading()
    @executeOnce()
    protected async selectLanguage(languageCode: string) {
        this.selectedLanguageCode = languageCode;
        await this.languageUserSettingsService.setUserLanguage(languageCode);
    }
}
