import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { executeOnce } from '@datagalaxy/core-ui';
import { map, Subject } from 'rxjs';
import { fadeInFadeOutAnimation } from '@datagalaxy/ui/animation';
import { NotificationService } from '../notification.service';
import { AppEventsService } from '../../services/AppEvents.service';
import { ITranslatedNotification } from '../notification.types';
import { NotificationPanelUiService } from '../notification-panel-ui.service';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationToasterComponent } from '../notification-toaster/notification-toaster.component';
import { NotificationPanelComponent } from '../notification-panel/notification-panel.component';
import { DxyPanelHostComponent } from '@datagalaxy/core-ui';
import { NgClass, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';

/**
 * ## Role
 * Unread notifications counter button that open the notification panel
 * At also handles realtime notifications toasters
 */
@Component({
    selector: 'app-notification-trigger',
    templateUrl: 'notification-trigger.component.html',
    styleUrls: ['notification-trigger.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [fadeInFadeOutAnimation()],
    standalone: true,
    imports: [
        DxyLogFunctionalDirective,
        MatLegacyTooltipModule,
        NgClass,
        NgIf,
        DxyPanelHostComponent,
        NotificationPanelComponent,
        NgFor,
        NotificationToasterComponent,
        AsyncPipe,
        TranslateModule,
        DxyIconButtonDirective,
    ],
})
export class NotificationTriggerComponent
    extends DxyBaseComponent
    implements OnInit
{
    private static modificationNotificationDurationMs = 1100;

    protected get isPanelOpened$() {
        return this.notificationPanuelUiService.panelOpened$;
    }
    protected get isPanelClosed$() {
        return this.isPanelOpened$.pipe(map((opened) => !opened));
    }

    protected get notifications$() {
        return this.notificationService.notifications$;
    }
    protected get toastNotifications$() {
        return this.notificationService.toastNotifications$;
    }
    protected get unreadNotificationsCount$() {
        return this.notifications$.pipe(
            map(
                (notifications) =>
                    notifications.filter((n) => !n.source.IsAcknowledged)
                        ?.length || 0,
            ),
        );
    }
    protected get notifyNewNotification$() {
        return this.notifyNewNotification;
    }

    protected get realtimeNotificationDisabled() {
        return !this.notificationService.isRealTimeNotificationActive;
    }
    protected get notificationIconClass() {
        return this.realtimeNotificationDisabled
            ? 'glyph-no-notification'
            : 'glyph-notification';
    }

    private notifyNewNotification = new Subject();

    constructor(
        private notificationPanuelUiService: NotificationPanelUiService,
        private notificationService: NotificationService,
        private appEventsService: AppEventsService,
    ) {
        super();
    }

    ngOnInit() {
        this.subscribeEvents();
    }

    public togglePanel() {
        this.notificationPanuelUiService.togglePanel();
    }

    @executeOnce()
    public async acknowledgeNotification(
        notification: ITranslatedNotification,
    ) {
        this.notificationService.notifyToasterClose(notification.source.Guid);
        await this.notificationService.acknowledgeNotification(
            notification,
            true,
        );
    }

    public async closeToaster(notification: ITranslatedNotification) {
        this.notificationService.notifyToasterClose(notification.source.Guid);
    }

    public async onRealtimeNotificationEnabledChange(disabled: boolean) {
        await this.notificationService.setRealTimeNotificationBehavior(
            disabled,
        );
    }

    private subscribeEvents() {
        super.registerSubscriptions(
            this.notificationService.newNotification$.subscribe(() =>
                this.onNewNotification(),
            ),
            this.appEventsService.stateChanged$.subscribe(() =>
                this.closePanel(),
            ),
        );
    }

    private onNewNotification() {
        this.showRecentNotification();
    }

    private async showRecentNotification() {
        this.notifyNewNotification.next(true);
        setTimeout(
            () => this.notifyNewNotification.next(false),
            NotificationTriggerComponent.modificationNotificationDurationMs,
        );
    }

    private closePanel() {
        this.notificationPanuelUiService.closePanel();
    }
}
