import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { IWorkspaceIdentifier } from '@datagalaxy/webclient/workspace/domain';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerComponent } from '@datagalaxy/core-ui';
import { map, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EntityItem } from '@datagalaxy/webclient/entity/domain';
import { DxyBaseComponent } from '@datagalaxy/ui/core';
import { EntityEventService } from '../../shared/entity/services/entity-event.service';
import { Filter, FilterOperator } from '@datagalaxy/webclient/filter/domain';
import {
    EntityGridView,
    ModuleEntitiesStore,
} from './module-entities-store.service';
import { ModuleEntitiesService } from './module-entities.service';
import { EntityGridConfig } from '../../entity/entity-grid/entity-grid/entity-grid-config';
import { EntityGridComponent } from '../../entity/entity-grid/entity-grid/entity-grid/entity-grid-component.component';
import { ModuleEntitiesConfig } from './module-entities.types';
import {
    DxyGridColumnSelectorComponent,
    ServerSideSortEvent,
    TDatasourceConfig,
} from '@datagalaxy/ui/grid';
import { DataUtil } from '../../shared/util/DataUtil';
import { DxyIconButtonDirective } from '@datagalaxy/ui/buttons';
import { DgModule } from '@datagalaxy/shared/dg-module/domain';
import { EntityGridStateService } from '../../entity/entity-grid/entity-grid/entity-grid-state.service';
import { SearchInputComponent } from '@datagalaxy/ui/search';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { ViewTypeService } from '../../services/viewType.service';
import { DxyLogFunctionalDirective } from '../../directives/dxy-log-functional.directive';

@Component({
    standalone: true,
    selector: 'app-module-entities',
    templateUrl: './module-entities.component.html',
    styleUrls: ['./module-entities.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        EntityGridComponent,
        MatLegacyButtonModule,
        NgIf,
        MatLegacyTooltipModule,
        TranslateModule,
        AsyncPipe,
        SpinnerComponent,
        DxyGridColumnSelectorComponent,
        DxyIconButtonDirective,
        SearchInputComponent,
        DxyLogFunctionalDirective,
    ],
    providers: [ModuleEntitiesStore, ModuleEntitiesService],
})
export class ModuleEntitiesComponent
    extends DxyBaseComponent
    implements OnInit, OnChanges
{
    @Input() spaceIdr!: IWorkspaceIdentifier;
    @Input() dgModule!: DgModule;
    @Input() view: EntityGridView = 'tree';
    @Input() filters: Filter[] = [];
    @Input() config?: ModuleEntitiesConfig;

    @Output() entitiesChange = new EventEmitter<EntityItem[]>();
    @Output() entityClick = new EventEmitter<EntityItem>();
    @Output() viewChange = new EventEmitter<EntityGridView>();
    @Output() selectionChange = new EventEmitter<EntityItem[]>();

    @ViewChild(EntityGridComponent) entityGrid: EntityGridComponent;

    protected _loading$ = this.moduleEntitiesStore.selectLoading();
    protected totalCount$ = this.moduleEntitiesStore.selectTotalCount();
    protected hasReachLimit$ = this.moduleEntitiesStore.selectHasReachLimit();

    protected entities$!: Observable<EntityItem[]>;
    protected gridConfig$?: Observable<EntityGridConfig>;
    protected selectedEntities: EntityItem[] = [];

    protected sorting?: ServerSideSortEvent;

    protected get viewTranslateKey() {
        return this.view === 'flat'
            ? 'UI.OmniGrid.goToHierarchicalView'
            : 'UI.OmniGrid.goToFlatView';
    }

    protected get viewFeatureCode() {
        return this.view === 'flat'
            ? 'DISPLAY_HIERARCHY_MODE,R'
            : 'DISPLAY_FLAT_MODE,R';
    }

    constructor(
        private moduleEntitiesStore: ModuleEntitiesStore,
        private moduleEntitiesService: ModuleEntitiesService,
        private entityEventService: EntityEventService,
        private entityGridStateService: EntityGridStateService,
        private viewTyperService: ViewTypeService,
    ) {
        super();
    }

    ngOnInit() {
        this.entities$ = this.moduleEntitiesStore
            .selectEntities()
            .pipe(tap((entities) => this.entitiesChange.emit(entities)));

        this.gridConfig$ = this.moduleEntitiesStore
            .selectDataSource()
            .pipe(map((dataSourceConfig) => this.getConfig(dataSourceConfig)));

        this.subscribeEvents();
    }

    ngOnChanges(changes: SimpleChanges) {
        super.onChanges(
            changes,
            ['filters', 'dgModule', 'view', 'config'],
            () => this.updateGridConfig(),
            true,
        );
    }

    public focusEntity(entity: EntityItem) {
        this.entityGrid.focusEntity(entity);
    }

    protected onSelectionChange(entities: EntityItem[]) {
        this.selectedEntities = entities;
        this.selectionChange.emit(entities);
    }

    protected async onAttributeVisibilityChange(attributeKeys: string[]) {
        this.moduleEntitiesStore.setAttributeKeys(attributeKeys);
        await this.updateGridConfig();
    }

    protected async onSearchTermChange(searchTerm: string) {
        const filterKey = this.viewTyperService.getViewAttributeKey();
        const searchTermFilter = new Filter(
            filterKey,
            FilterOperator.TextContains,
            [searchTerm],
        );

        CollectionsHelper.replaceOrAppend(
            this.filters,
            (filter) => filter.AttributeKey === filterKey,
            searchTermFilter,
        );

        await this.updateGridConfig();
    }

    protected onToggleView() {
        const newView = this.view === 'flat' ? 'tree' : 'flat';
        this.viewChange.emit(newView);
    }

    protected async onServerSideSortChange(event: ServerSideSortEvent) {
        this.sorting = event.direction !== false ? event : undefined;
        await this.updateGridConfig();
    }

    private subscribeEvents() {
        const serverTypes = DataUtil.getServerTypesFromModule(this.dgModule);

        serverTypes.forEach((serverType) => {
            super.registerSubscriptions(
                this.entityEventService.subscribeEntityCreation(
                    serverType,
                    () => this.updateGridConfig(),
                ),
                this.entityEventService.subscribeEntityParentUpdate(
                    serverType,
                    () => this.updateGridConfig(),
                ),
            );
        });
    }

    private async updateGridConfig() {
        this.moduleEntitiesStore.reset();

        const res = await this.entityGridStateService.getGridStateAttributeKeys(
            this.config?.persistedUserSettingsId,
        );

        const attributeKeys = res?.length
            ? res
            : this.config?.attributeConfig?.defaultAttributeKeys;

        this.moduleEntitiesStore.setAttributeKeys(attributeKeys);

        const sortKey = this.sorting?.direction
            ? `${this.sorting.direction === 'desc' ? '-' : ''}${
                  this.sorting.colId
              }`
            : undefined;

        const dataSourceConfig = this.moduleEntitiesService.getGridDataSource(
            this.view,
            this.spaceIdr,
            DataUtil.getServerTypesFromModule(this.dgModule),
            this.filters,
            this.config?.entityRootId,
            sortKey,
        );

        this.moduleEntitiesStore.setDataSource(dataSourceConfig);
    }

    private getConfig(
        dataSourceConfig: TDatasourceConfig<EntityItem>,
    ): EntityGridConfig {
        const activeSort =
            this.sorting && this.sorting?.direction !== false
                ? {
                      columnId: this.sorting?.colId,
                      direction: this.sorting?.direction,
                  }
                : undefined;

        return {
            ...this.config,
            sorting: {
                activeSort,
                isServerSide: true,
            },
            hideColumnsHeader: !this.config?.attributeConfig,
            entity: {
                ...this.config?.entity,
                hideBreadcrumb: this.view === 'tree',
            },
            dataSourceConfig,
        };
    }
}
