import { StringUtil } from '@datagalaxy/core-util';
import { StateObject } from '@uirouter/core';

export namespace StateName {
    export const Main = 'main';
    export const MainIsUp = Main + '.isup';
    export const MainError = Main + '.error';

    //#region Account site states
    export const AccountLogin = Main + '.login';
    export const AccountExternalLogin = Main + '.extlogin';
    export const AccountExternalLogout = Main + '.extlogout';
    export const AccountMainInit = Main + '.init';
    export const AccountMainReset = Main + '.reset';
    export const MainExpiredLicense = Main + '.expiredLicense';

    export const RelativeHome = '.home';
    export const RelativeData = '.data';
    export const RelativeDashboard = '.dashboard';
    export const RelativeGrid = '.grid';
    export const RelativeList = '.list';
    export const RelativeMain = '.main';
    export const RelativeSearchResults = '.search-results';
    export const RelativeTasks = '.tasks';
    export const RelativeDataMap = '.dataMap';
    export const RelativeConnectors = '.connectors';
    export const RelativeToolDiagram = '.diagram';

    export const RelativeTabDetails = '.details';
    export const RelativeTabMapping = '.mapping';
    export const RelativeTabLinkedObjects = '.linkedObjects';
    export const RelativeTabTables = '.tablesTab';
    export const RelativeTabContainers = '.containersTab';
    export const RelativeTabColumns = '.columns';
    export const RelativeTabModelSettings = '.modelSettings';
    export const RelativeTabDiagrams = '.diagrams';
    export const RelativeTabImpactAnalysis = '.analysis';
    export const RelativeTabLineage = '.lineage';
    export const RelativeTabPrimaryKeys = '.primaryKeys';
    export const RelativeTabForeignKeys = '.foreignKeys';
    export const RelativeTabSample = '.sample';
    export const RelativeTabUsageFields = '.usageFields';
    export const RelativeTabUsageComponents = '.usageComponents';
    export const RelativeTabDataQuality = '.dataQuality';
    //#endregion

    //#region  Main private states name
    export const MainPrivate = Main + '.private';
    export const MainPrivateIndex: string = MainPrivate + '.index';
    export const MainPrivateError: string = MainPrivate + '.error';
    //#endregion

    //#region  Base Client states
    export const Client: string = MainPrivate + '.client';
    export const ClientFirstAccess = MainPrivate + '.first-access';
    export const ClientFirstAccessAuthV2 = Client + '.first-access';
    export const ClientLazyLoaded: string = MainPrivate + '.client.**';
    export const ClientSpacesList: string = Client + '.spacesList';
    export const ClientSearchResults: string = Client + RelativeSearchResults;
    export const ClientTasks: string = Client + RelativeTasks;
    export const ClientError: string = Client + '.error';
    export const ClientConnectors: string = Client + RelativeConnectors;
    export const ClientDashboard = Client + RelativeDashboard;
    export const ClientDashboardLazyLoaded = ClientDashboard + '.**';
    //#endregion

    //#region  User states name
    export const User = Client + '.user';
    export const UserLazyLoaded = Client + '.user.**';
    export const UserProfile = User + '.profile';
    export const UserTeams = User + '.teams';
    export const UserSettings = User + '.settings';
    export const UserAnalytics = User + '.analytics';
    export const UserRights = User + '.right';
    //#endregion

    //#region  Space states name
    export const Space = Client + '.space';
    export const SpaceDashboard = Space + '.dashboard';
    export const SpaceHome = Space + RelativeHome;
    export const SpaceSettings = Space + '.settings';
    export const SpaceScreens = SpaceSettings + '.screens';
    export const SpaceSecurity = SpaceSettings + '.security';
    export const SpaceRoles = SpaceSettings + '.roles';
    export const SpaceVersions = SpaceSettings + '.projectVersions';
    export const SpaceParameters = SpaceSettings + '.parameters';
    export const SpaceDetails = SpaceSettings + '.details';
    export const SpaceConnector = SpaceSettings + '.connector';
    export const SpaceAnalytics = SpaceSettings + '.analytics';

    export const AccessControlManagement = SpaceSettings + '.access';
    export const AccessControlManagementObjects =
        AccessControlManagement + '.access-objects';
    //#endregion

    //#region  Modeler states for model, table, column objects
    export const Modeler = Space + '.modeler';

    //Modeler
    //  ModelerHome
    //  ModelerData
    //      ModelerMain
    //          ModelerGrid
    //          ModelerList
    //              ModelerDashboard
    //                  Modeler[...]Tab

    // for model, and table or columns in hierarchical view
    export const ModelerData = Modeler + RelativeData;
    export const ModelerMain = ModelerData + RelativeMain;
    export const ModelerGrid = ModelerMain + RelativeGrid;
    export const ModelerList = ModelerMain + RelativeList;
    export const ModelerDashboard = ModelerList + RelativeDashboard;
    export const ModelerDetailsTab = ModelerDashboard + RelativeTabDetails;
    export const ModelerDiagramsTab = ModelerDashboard + RelativeTabDiagrams;
    export const ModelerImpactAnalysisTab =
        ModelerDashboard + RelativeTabImpactAnalysis;
    export const ModelerLineage = ModelerDashboard + RelativeTabLineage;
    export const ModelerLinkedObjects =
        ModelerDashboard + RelativeTabLinkedObjects;
    export const ModelerDataQuality = ModelerDashboard + RelativeTabDataQuality;
    // for model only
    export const ModelerContainerTablesTab =
        ModelerDashboard + RelativeTabTables;
    export const ModelerModelContainersTab =
        ModelerDashboard + RelativeTabContainers;
    export const ModelerModelSettingsTab =
        ModelerDashboard + RelativeTabModelSettings;
    export const ModelerModelPrimaryKeysTab =
        ModelerDashboard + RelativeTabPrimaryKeys;
    export const ModelerModelForeignKeysTab =
        ModelerDashboard + RelativeTabForeignKeys;
    // for table in hierarchical view
    export const ModelerTableColumnsTab = ModelerDashboard + RelativeTabColumns;
    export const ModelerTableSampleTab = ModelerDashboard + RelativeTabSample;
    //#endregion

    //#region  Glossary states name
    export const GlossaryRoot = '.glossary';
    export const Glossary = Space + GlossaryRoot;
    export const GlossaryData = Glossary + RelativeData;
    export const GlossaryMain = GlossaryData + RelativeMain;
    export const GlossaryGrid = GlossaryMain + RelativeGrid;
    export const GlossaryDataMap = GlossaryMain + RelativeDataMap;
    export const GlossaryList = GlossaryMain + RelativeList;
    export const GlossaryDashboard = GlossaryList + RelativeDashboard;
    export const GlossaryDetails = GlossaryDashboard + RelativeTabDetails;
    export const GlossaryLinkedObjects =
        GlossaryDashboard + RelativeTabLinkedObjects;
    export const GlossaryMapping = GlossaryDashboard + RelativeTabMapping;
    export const GlossaryMappingLazyLoaded = GlossaryMapping + '.**';
    export const GlossaryImpactAnalysis =
        GlossaryDashboard + RelativeTabImpactAnalysis;
    export const GlossaryLineage = GlossaryDashboard + RelativeTabLineage;
    export const GlossaryDiagramsTab = GlossaryDashboard + RelativeTabDiagrams;
    //#endregion

    //#region  DataProcessing states name
    export const DataProcessing = Space + '.dataProcessing';
    export const DataProcessingData = DataProcessing + RelativeData;
    export const DataProcessingMain = DataProcessingData + RelativeMain;
    export const DataProcessingGrid = DataProcessingMain + RelativeGrid;
    export const DataProcessingDataMap = DataProcessingMain + RelativeDataMap;
    export const DataProcessingList = DataProcessingMain + RelativeList;
    export const DataProcessingDashboard =
        DataProcessingList + RelativeDashboard;
    export const DataProcessingDetails =
        DataProcessingDashboard + RelativeTabDetails;
    export const DataProcessingMapping =
        DataProcessingDashboard + RelativeTabMapping;
    export const DataProcessingLinkedObjects =
        DataProcessingDashboard + RelativeTabLinkedObjects;
    export const DataProcessingImpactAnalysis =
        DataProcessingDashboard + RelativeTabImpactAnalysis;
    export const DataProcessingLineage =
        DataProcessingDashboard + RelativeTabLineage;
    export const DataProcessingDiagramsTab =
        DataProcessingDashboard + RelativeTabDiagrams;
    //#endregion

    //#region  Software states name
    export const Software = Space + '.software';
    export const SoftwareData = Software + RelativeData;
    export const SoftwareMain = SoftwareData + RelativeMain;
    export const SoftwareGrid = SoftwareMain + RelativeGrid;
    export const SoftwareDataMap = SoftwareMain + RelativeDataMap;
    export const SoftwareList = SoftwareMain + RelativeList;
    export const SoftwareDashboard = SoftwareList + RelativeDashboard;
    export const SoftwareDetails = SoftwareDashboard + RelativeTabDetails;
    export const SoftwareLinkedObjects =
        SoftwareDashboard + RelativeTabLinkedObjects;
    export const SoftwareImpactAnalysis =
        SoftwareDashboard + RelativeTabImpactAnalysis;
    export const SoftwareLineage = SoftwareDashboard + RelativeTabLineage;
    export const SoftwareFields = SoftwareDashboard + RelativeTabUsageFields;
    export const SoftwareComponents =
        SoftwareDashboard + RelativeTabUsageComponents;
    export const SoftwareDiagramsTab = SoftwareDashboard + RelativeTabDiagrams;
    //#endregion

    //#region Diagram state names
    //#region common module state names
    export const Diagrams = Space + '.diagrams';
    export const DiagramsData = Diagrams + RelativeData;
    export const DiagramsMain = Diagrams + RelativeMain;
    export const DiagramsGrid = Diagrams + RelativeGrid;
    export const DiagramsList = Diagrams + RelativeList;
    export const DiagramsDashboard = Diagrams + RelativeDashboard;
    export const DiagramsDetails = Diagrams + RelativeTabDetails;
    //#endregion
    //#region specific state names
    export const DiagramsGlobalList = DiagramsMain + '.global-list';
    export const GenericDiagram = DiagramsMain + RelativeToolDiagram;
    //#endregion
    //#endregion

    //#region Campaigns state names
    export const Campaigns = Space + '.campaigns';
    export const CampaignsLazyLoaded = Space + '.campaigns.**';
    export const CampaignDetails = Campaigns + '.details';
    export const CampaignDetailsInfos = CampaignDetails + '.infos';
    export const CampaignDetailsObjects = CampaignDetails + '.objects';
    export const CampaignDetailsHistory = CampaignDetails + '.history';
    //#endregion

    //#region  Impact analysis state names
    export const ImpactAnalysis = Space + '.impactAnalysis';
    export const ImpactAnalysisMain = ImpactAnalysis + RelativeMain;
    export const ImpactAnalysisExploratory = ImpactAnalysis + '.exploratory';
    export const ImpactAnalysisLineage = ImpactAnalysis + '.lineage';
    //#endregion

    //#region Admin Client state names

    export const ClientAdmin = Client + '.admin';
    export const ClientAdminLazyLoaded = Client + '.admin.**';

    export const ClientAdminDetails = ClientAdmin + '.adminClientDetails';
    export const ClientAdminAnalytics = ClientAdmin + '.adminAnalytics';
    export const ClientAdminUsers = ClientAdmin + '.adminUsers';
    export const ClientAdminUsersLazyLoaded = ClientAdmin + '.adminUsers.**';
    export const ClientAdminTeams = ClientAdmin + '.adminTeams';
    export const ClientAdminAttributes = ClientAdmin + '.adminAttribute';
    export const ClientAdminAttributesLazyLoaded =
        ClientAdmin + '.adminAttribute.**';

    export const ClientAdminDataProcessingAttributes =
        ClientAdmin + '.adminDataProcessingAttributes';
    export const ClientAdminDataProcessingScreens =
        ClientAdmin + '.adminDataProcessingScreens';

    export const ClientAdminGlossaryAttributes =
        ClientAdmin + '.adminGlossaryAttributes';
    export const ClientAdminGlossaryScreens =
        ClientAdmin + '.adminGlossaryScreens';

    export const ClientAdminScreens = ClientAdmin + '.adminScreens';

    export const ClientAdminSoftwareAttributes =
        ClientAdmin + '.adminSoftwareAttributes';
    export const ClientAdminSoftwareScreens =
        ClientAdmin + '.adminSoftwareScreens';

    export const ClientAdminDiagramAttributes =
        ClientAdmin + '.adminDiagramAttributes';
    export const ClientAdminDiagramScreens =
        ClientAdmin + '.adminDiagramScreens';

    export const ClientAdminTechnologies = ClientAdmin + '.technology';
    export const ClientAdminDataDefinitionLanguage = ClientAdmin + '.adminDdl';

    export const ClientAdminModelerAttributes =
        ClientAdmin + '.adminModelerAttributes';
    export const ClientAdminModelerContainerAttributes =
        ClientAdmin + '.adminContainerAttributes';
    export const ClientAdminModelerTableAttributes =
        ClientAdmin + '.adminTableAttributes';
    export const ClientAdminModelerColumnAttributes =
        ClientAdmin + '.adminColumnsAttributes';

    export const ClientAdminModelerScreens = ClientAdmin + '.adminModelScreens';
    export const ClientAdminContainerScreens =
        ClientAdmin + '.adminContainerScreens';
    export const ClientAdminTableScreens = ClientAdmin + '.adminTableScreens';
    export const ClientAdminColumnScreens = ClientAdmin + '.adminColumnScreens';

    export const ClientAdminIntegration = ClientAdmin + '.adminIntegration';
    export const ClientAdminIntegrationLazyLoaded =
        ClientAdmin + '.adminIntegration.**';
    export const ClientAdminIntegrationTokens =
        ClientAdminIntegration + '.tokens';
    export const ClientAdminIntegrationCollaboration =
        ClientAdminIntegration + '.collaboration';
    export const ClientAdminSecurity = ClientAdmin + '.security';

    export const ClientAdminAllTypesAttributes =
        ClientAdmin + '.adminAllTypesAttributes';
    export const ClientAdminAllTypesScreens =
        ClientAdmin + '.adminAllTypesScreens';

    export const ClientAdminSearch = ClientAdmin + '.adminSearch';
    export const ClientAdminSearchSettings = ClientAdminSearch + '.settings';

    export const ClientAdminActivityLog =
        ClientAdmin + '.adminClientActivityLog';

    //#endregion

    //#region Admin site state names

    export const AdminHome = Main + '.adminPrivate' + RelativeHome;

    //#endregion
}

/** Returns true if the given state's name is any of the given names */
export function isStateByName(state: StateObject, ...stateNames: string[]) {
    return StringUtil.containsAny(state.name, stateNames);
}
