<div class="left-container">
    <img class="dg-logo" src="/images/logo/logo-cloud.svg" />
    <span class="title" translate>UI.ClientTrialExpired.title</span>
    <span class="contact-us-section" translate
        >UI.ClientTrialExpired.messageLbl</span
    >
    <div class="buttons-container">
        <a
            dxyButton
            variant="secondary"
            class="contact-us-button"
            [href]="linkUrl"
            target="_blank"
        >
            <span translate>UI.ClientTrialExpired.subscribeBtn</span>
        </a>
        <a
            dxyButton
            variant="secondary"
            class="contact-us-button"
            [href]="linkUrl"
            target="_blank"
        >
            <span translate>UI.ClientTrialExpired.extendTrialBtn</span>
        </a>
    </div>
</div>
<div class="right-container">
    <span class="floating-message" translate
        >UI.ClientTrialExpired.floatingMessage</span
    >
    <img class="foreground-img" src="/images/online-trial-foreground.png" />
</div>
