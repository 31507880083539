import { Transition } from '@uirouter/core';
import { SlackRedirectionQueryParams } from '../notification-channels/slack-redirection.types';
import { SlackRedirectionHandler } from '../notification-channels/slack-redirection-handler';
import { QueryParamRemover } from '../notification-channels/query-param-remover';

export function handleQueryParamsOnClientTransitionSuccess(
    transition: Transition,
) {
    const injector = transition.injector();
    const routeParams = transition.targetState().params();
    const callbackIdentifier =
        routeParams[SlackRedirectionQueryParams.callbackIdentifier];
    const code = routeParams[SlackRedirectionQueryParams.code];
    const from = routeParams.from;
    if (callbackIdentifier && code) {
        const slackRedirectionHandler = injector.get<SlackRedirectionHandler>(
            SlackRedirectionHandler,
        );
        setTimeout(() => {
            slackRedirectionHandler.handleRedirection(callbackIdentifier, code);
        }, 10);
    }
    if (from) {
        const queryParamRemover =
            injector.get<QueryParamRemover>(QueryParamRemover);
        setTimeout(() => {
            queryParamRemover.remove(['from']);
        }, 10);
    }
}
