<div class="error-page-card">
    <app-account-logo></app-account-logo>

    <div class="cardTitle" [translate]="titleKey"></div>

    <div class="cardContent">{{ contentMessage }}</div>

    <a
        *ngIf="canRedirect"
        (click)="redirect()"
        dxyButton
        variant="secondary"
        class="redirectButton redirect"
    >
        <span translate> UI.ErrorPage.lblredirect </span>
    </a>

    <div *ngIf="isPrivateLevel" class="client-list">
        <div class="cardTitle client-list-title" translate>
            UI.ErrorPage.lblclientListTitle
        </div>
        <ul class="dg5-list-unstyled">
            <li
                *ngFor="let client of availableClients"
                (click)="onChangeClient(client)"
            >
                {{ client.DisplayName }}
            </li>
        </ul>
    </div>

    <a
        *ngIf="showDefaultRedirect"
        (click)="goToLoginPage()"
        class="hcenter redirectButton gotologin"
    >
        <span translate> UI.ErrorPage.lblConnect </span>
    </a>
</div>
