// Important: import nothing but environment in this file; only exports
import { CoreUtil } from '@datagalaxy/core-util';
import { environment } from './environments/environment';

/** Set to true for logging to console during app bootstrapping.
 * Can be activated by `dgdebug.traceBoot(true)` in console and reloading the app. */
let debug = false;
CoreUtil.isDebuggingBoot = () => {
    return debug;
};

/** uirouter trace categories
 *  none = -1, RESOLVE = 0, TRANSITION = 1, HOOK = 2, UIVIEW = 3, VIEWCONFIG = 4
 * Can be activated by (e.g.) `dgdebug.traceBoot(true, [1, 3])` in console and reloading the app. */
export let logStates: number[];

if (!environment.production) {
    let traceBoot: { logStates?: number[] };
    try {
        traceBoot = JSON.parse(
            window?.localStorage?.getItem?.('dgTraceBoot') ?? null,
        );
    } catch (e) {
        try {
            window.localStorage.removeItem('dgTraceBoot');
        } catch (e2) {}
    }

    debug ||= !!traceBoot;
    logStates = !debug ? undefined : (traceBoot?.logStates ?? []); // [0, 1, 2, 3, 4]
}

export const getBootLogger = (callerName: string, withTime = true) => {
    if (!withTime) {
        return (...args: any[]) => logBoot(callerName, ...args);
    }

    const start = Date.now();
    let last = start;
    return (...args: any[]) => {
        const now = Date.now();
        const fromStart = now - start;
        const fromLast = now - last;
        logBoot(callerName, ...args, fromStart, fromLast);
        last = now;
    };
};
function logBoot(...args: any[]) {
    if (!debug) {
        return;
    }
    console.log(
        '[boot]',
        ...args,
        Zone.current?.name,
        Zone.currentTask?.source,
    );
}
