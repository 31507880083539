<dxy-burger-menu-button
    [options]="menuOptions"
    [buttonColor]="translationKo ? 'destructive' : 'ai'"
    [caretIconInButton]="true"
    glyphClass="glyph-language-ai"
    [matTooltip]="
        (translationKo
            ? 'Multilingual.AttributeTranslationButton.koTooltip'
            : 'Multilingual.AttributeTranslationButton.tooltip'
        ) | translate
    "
>
    <header menu-header [class.ko]="translationKo">
        <h3>
            {{
                (translationKo
                    ? 'Multilingual.AttributeTranslationButton.koTitle'
                    : 'Multilingual.AttributeTranslationButton.title'
                )
                    | translate
                        : {
                              sourceLanguageCode:
                                  sourceLanguageCode.toUpperCase(),
                          }
            }}
        </h3>
        <ng-container *ngIf="!translationKo">
            <dxy-spinner
                *ngIf="loading$ | async; else source"
                class="xs no-padding"
            ></dxy-spinner>
            <ng-template #source>
                <p>{{ translationSource }}</p>
            </ng-template>
        </ng-container>
    </header>
</dxy-burger-menu-button>
