import { Injectable } from '@angular/core';
import { SlackApiService } from './api/slack-api.service';
import {
    SlackRedirectionIdentifier,
    SlackRedirectionQueryParams,
} from './slack-redirection.types';
import { UiSpinnerService } from '@datagalaxy/core-ui';
import { UserNotificationChannelsStateService } from './state/user-notification-channels-state.service';
import { slackNotificationChannel } from './slack-notification-channel';
import { ToasterService } from '../services/toaster.service';
import { QueryParamRemover } from './query-param-remover';

const channelName = slackNotificationChannel.name;

@Injectable({
    providedIn: 'root',
})
export class SlackRedirectionHandler {
    constructor(
        private slackApiService: SlackApiService,
        private userNotificationChannelsStateService: UserNotificationChannelsStateService,
        private uiSpinnerService: UiSpinnerService,
        private toasterService: ToasterService,
        private queryParamRemover: QueryParamRemover,
    ) {}

    async handleRedirection(callbackIdentifier: string, code: string) {
        if (code == 'undefined') {
            this.toastError();
            return;
        }

        await this.uiSpinnerService.executeWithSpinner(async () => {
            switch (callbackIdentifier) {
                case SlackRedirectionIdentifier.integrationActivation:
                    await this.activateIntegration(code);
                    break;
                case SlackRedirectionIdentifier.notficationsActivation:
                    await this.activateNotifications(code);
                    break;
            }
        });

        this.removeQueryParams();
    }

    private removeQueryParams() {
        this.queryParamRemover.remove([
            SlackRedirectionQueryParams.callbackIdentifier,
            SlackRedirectionQueryParams.code,
        ]);
    }

    private toastError() {
        this.toasterService.errorToast({
            messageKey: 'UI.NotificationChannels.Slack.activationError',
        });
    }

    private async activateIntegration(code: string) {
        try {
            await this.slackApiService.activateIntegration(code);
            this.userNotificationChannelsStateService.setClientIntegration(
                slackNotificationChannel.name,
                true,
            );
            this.toasterService.successToast({
                titleKey: 'UI.Global.success',
                messageKey:
                    'UI.NotificationChannels.Slack.integrationActivationSuccess',
            });
        } catch (error) {
            this.toastError();
        }
    }

    private async activateNotifications(code: string) {
        try {
            await this.slackApiService.activateNotifications(code);
            this.userNotificationChannelsStateService.setUserChannelActivation(
                channelName,
                true,
            );
            this.toasterService.successToast({
                messageKey:
                    'UI.NotificationChannels.Slack.notificationsActivationSuccess',
            });
        } catch {
            this.toastError();
        }
    }
}
