import { Injectable } from '@angular/core';
import {
    AttributeCategory,
    AttributeMetaInfo,
    AttributeMetaType,
} from '@datagalaxy/webclient/attribute/domain';
import { IDropdownSection, ISectionOption } from '@datagalaxy/core-ui';
import { ServerConstants } from '@datagalaxy/shared/server/domain';
import { ServerType } from '@datagalaxy/dg-object-model';
import { CollectionsHelper } from '@datagalaxy/core-util';
import { DataUtil } from '../../../util/DataUtil';
import { getAttributeGlyphClass } from '@datagalaxy/webclient/attribute/feature';
import { ModuleStore } from '../../../../module/module.store';

@Injectable({ providedIn: 'root' })
export class AttributeFilterSectionService {
    constructor(private moduleStore: ModuleStore) {}

    public getUiAttributesSections(
        attributes: AttributeMetaInfo[],
        opt?: {
            onAddAttribute?: (attr: AttributeMetaInfo) => void;
            onSearchString?: (
                searchTerm: string,
                section: IDropdownSection,
            ) => void;
            includeLinks?: boolean;
            useObjectValues?: boolean;
            getLogFunctional?: (attr: AttributeMetaInfo) => string;
            flattenSections?: boolean;
        },
    ) {
        /** Note: we want to include the TechnicalComments field in both the Structure and the Field sections */
        const technicalCommentsExceptionFunc = (attr: AttributeMetaInfo) =>
            attr.AttributeKey ==
            ServerConstants.PropertyName.TechnicalCommentsRaw;
        const makeSection = (
            sectionKey?: string,
            attributeCategory?: AttributeCategory,
            serverType?: ServerType,
            additionalMatchingFunc?: (att) => boolean,
        ): IDropdownSection => {
            let attributesFiltered =
                attributeCategory == undefined
                    ? attributes
                    : attributes
                          ?.filter((attr) => {
                              const hasAccessToModule =
                                  this.moduleStore.hasAccess(
                                      this.getAttributeModule(attr),
                                  ) || attr.IsAllTypes;

                              return (
                                  hasAccessToModule &&
                                  (attr.AttributeCategory ==
                                      attributeCategory ||
                                      (attributeCategory ==
                                          AttributeCategory.Common &&
                                          !attr.AttributeCategory &&
                                          attr.AttributeKey ==
                                              ServerConstants.Search
                                                  .EntityTypeFilterKey)) &&
                                  (!serverType ||
                                      attr.serverType == serverType ||
                                      additionalMatchingFunc?.(attr))
                              );
                          })
                          .filter(
                              (attr) =>
                                  attr.AttributeType !==
                                  AttributeMetaType.ObjectValueList,
                          );

            attributesFiltered = CollectionsHelper.orderByText(
                attributesFiltered,
                (att) => att.translatedDisplayName,
            );

            if (opt.useObjectValues) {
                attributesFiltered = attributesFiltered.map((attrFiltered) => {
                    const objectValues = attributes.find(
                        (attr) =>
                            attr.AttributePath ===
                            `${attrFiltered.AttributePath}ObjectValues`,
                    );

                    return objectValues ?? attrFiltered;
                });
            }

            const options: ISectionOption[] = attributesFiltered.map(
                (attr) => ({
                    labelText: attr.translatedDisplayName,
                    data: attr,
                    glyphClass: getAttributeGlyphClass(attr),
                    callback: () => opt.onAddAttribute(attr),
                    logFunctional: opt.getLogFunctional?.(attr),
                }),
            );

            return {
                textTranslateKey:
                    sectionKey && `UI.Filter.sections.${sectionKey}`,
                options,
                filteredOptions: options,
                onSearchString: (
                    section: IDropdownSection,
                    searchTerm: string,
                ) => opt.onSearchString(searchTerm, section),
            };
        };
        const makeSuperSection = (
            sectionKey: string,
            subSections: IDropdownSection[],
        ): IDropdownSection => {
            const superSection = {
                textTranslateKey: `UI.Filter.sections.${sectionKey}`,
                subSections,
            };
            subSections.forEach((ss) => (ss.parentSection = superSection));
            return superSection;
        };

        if (opt.flattenSections) {
            return [makeSection()];
        }

        const sections = [
            makeSection('common', AttributeCategory.Common),
            makeSection('governanceUser', AttributeCategory.GovernanceUser),
            makeSection('gdpr', AttributeCategory.Gdpr),
            makeSection(
                'glossary',
                AttributeCategory.none,
                ServerType.Property,
            ),
            makeSuperSection('catalog', [
                makeSection('source', AttributeCategory.none, ServerType.Model),
                makeSection(
                    'container',
                    AttributeCategory.none,
                    ServerType.Container,
                ),
                makeSection(
                    'structure',
                    AttributeCategory.none,
                    ServerType.Table,
                    technicalCommentsExceptionFunc,
                ),
                makeSection(
                    'field',
                    AttributeCategory.none,
                    ServerType.Column,
                    technicalCommentsExceptionFunc,
                ),
            ]),
            makeSection(
                'processing',
                AttributeCategory.none,
                ServerType.DataProcessing,
            ),
            makeSection(
                'software',
                AttributeCategory.none,
                ServerType.SoftwareElement,
            ),
        ];

        if (opt.includeLinks) {
            sections.push(makeSection('link', AttributeCategory.Link));
        }
        return sections;
    }

    private getAttributeModule(attribute: AttributeMetaInfo) {
        return DataUtil.getModuleFromServerType(attribute.serverType);
    }
}
