<button
    #panelToggleButton
    dxyIconButton
    variant="tertiary"
    size="medium"
    class="btn-bell"
    (click)="togglePanel()"
    [matTooltip]="'UI.NavBar.lblNotifications' | translate"
    matTooltipPosition="below"
    dxyLogFunctional="NOTIFICATION,R"
>
    <i
        [ngClass]="notificationIconClass"
        [class.animation-wobble]="notifyNewNotification$ | async"
    ></i>
    <span
        *ngIf="unreadNotificationsCount$ | async as notificationCount"
        class="badge notif dg5-notif-badge"
    >
        {{ notificationCount }}
    </span>
</button>

<dxy-panel-host
    [panelOpened]="isPanelOpened$ | async"
    (panelOpenedChange)="togglePanel()"
    [toggleButton]="panelToggleButton"
>
    <app-notification-panel
        *ngIf="isPanelOpened$ | async"
        [notifications]="notifications$ | async"
        [realtimeNotificationDisabled]="realtimeNotificationDisabled"
        (realtimeNotificationDisabledChange)="
            onRealtimeNotificationEnabledChange($event)
        "
    ></app-notification-panel>
</dxy-panel-host>

<div *ngIf="isPanelClosed$ | async" class="realtime-notification-panel">
    <app-notification-toaster
        *ngFor="let toastNotification of toastNotifications$ | async"
        [notification]="toastNotification"
        (close)="acknowledgeNotification(toastNotification)"
        (timeout)="closeToaster(toastNotification)"
        (isAcknowledgedChanged)="closeToaster(toastNotification)"
        @fadeInFadeOut
    ></app-notification-toaster>
</div>
